import React, {useEffect, useState} from "react";
import {IonLabel, IonButton, IonButtons, IonLoading, IonModal, IonInput, IonItem, IonContent, IonTextarea} from "@ionic/react";
import {useApi} from "../providers/LaravelApiProvider";
import AlertDialog from "./AlertDialog";
import TelInput from "./TelInput";
import validator from 'validator';
import DateInput from "./DateInput";

function DriverEditDialog(props) {

    const {api} = useApi();
    let [loading, setLoading] = useState(false);
    let [alertDialog, setAlertDialog] = useState({show: false, message: null});
    let [value, setValue] = useState(null);
    let [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        setInputValue(props.driverEditData.defaultValue);
    },[props.driverEditData])

    function onChange(key, value) {
        setValue({
            [key] : value
            });
        setInputValue(value);
    }

    function onChangeAddress(e){
        setValue({
            "address" : e.detail.value
        });
    }


    function update() {

        if(value){
            if(value.identity_number){
                api.get('user/identity/' + value.identity_number).then(resp => {
                    if(resp.Type === "foreign" || resp.Type === "person"){
                        save(value);
                    }else{
                        setAlertDialog({
                            show: true,
                            message: "Geçerli bir kimlik no giriniz"
                        });
                    }
                })
            }else if(value.email){
                if(validator.isEmail(value.email)){
                    save(value);
                }else{
                    setAlertDialog({
                        show: true,
                        message: "Geçerli bir email giriniz"
                    });
                }

            }else if(value.address){
                save(value);
            }else{
                save(value);
            }
        }else{
            setAlertDialog({
                show: true,
                message: "Boş geçemezsiniz!"
            });
        }
    }

    function save(alertData){

        setLoading(true);
        api.post('mobileapp/driver/' + props.driverEditData.id ,alertData).then(resp => {
            props.onChange();
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    function updateCustomerIdentity(alertData){
        let params = {
            identity_number: alertData.identity_number,
            tax_number: "",
            tax_office: ""
        }

        setLoading(true);
        api.post('mobileapp/user/' + props.reservation.id, params).then(resp => {
            props.onChange();
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <>
            <AlertDialog show={alertDialog.show} message={alertDialog.message} onClose={() => setAlertDialog({show: false, message: null})}/>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            <IonModal isOpen={props.show} onDidDismiss={() => props.onClose() } cssClass='driver-edit-dialog'>
                <IonLabel className="title">{props.driverEditData.driverValue.label}</IonLabel>
                <IonContent>
                <div className="my-2 mx-2">
                {props.driverEditData.driverValue.name === "phone" ? (
                    <>
                        <TelInput
                            name={props.driverEditData.driverValue.name}
                            value={inputValue}
                            placeholder={"Yeni telefon no"}
                            onChange={onChange}
                            className={"my-2"}
                        />

                    </>
                ) : props.driverEditData.driverValue.name === "license_date" ? (
                    <>
                        <DateInput onChange={(key, value) => {onChange(key, value)}} errors={[]} value={inputValue} name={"license_date"} label={"E.Ver.Tarihi"} />
                    </>
                ) : props.driverEditData.driverValue.name === "birthday" ? (
                    <>
                        <DateInput onChange={(key, value) => {onChange(key, value)}} errors={[]} value={inputValue} name={"birthday"} label={"D.Tarihi"} />
                    </>
                ) : props.driverEditData.driverValue.name === "address" ? (
                    <IonItem className="mx-2">
                        <IonTextarea
                            value={inputValue}
                            name={props.driverEditData.driverValue.name}
                            placeholder="Yeni değer"
                            onIonChange={e => {
                                setInputValue(e.detail.value);
                                onChangeAddress(e);
                            }}/>
                    </IonItem>
                ) : (
                    <>
                        <IonItem className="mx-2">
                            <IonInput value={inputValue} name={props.driverEditData.driverValue.name} type={props.driverEditData.driverValue.type} placeholder="Yeni değer" onIonChange={(e) => onChange(e.target.name, e.detail.value)}/>
                        </IonItem>
                    </>
                )}
            </div>
                </IonContent>
            <IonButtons className="p-2 d-flex justify-content-between border-top" style={{zIndex: -1}}>
                <IonButton color="medium" expand="full" onClick={() => {props.onClose()}}>İptal</IonButton>
                <IonButton color="primary" expand="full" onClick={() => {update()}}>Güncelle</IonButton>
            </IonButtons>
        </IonModal>
        </>
    )
}

export default DriverEditDialog;
