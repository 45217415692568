import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonCard, IonCardContent,
    IonContent, IonIcon,
    IonItem, IonLabel, IonLoading,
    IonSelect, IonSelectOption,
    IonTextarea

} from "@ionic/react";
import money, {convertCeil, currencyFormat, moneyKalan} from "../utils/money";

import ReactCardFormContainer from 'card-react';
import 'card-react/lib/card.css';
import AlertDialog from "./AlertDialog";
import {checkmarkCircle, checkmarkCircleOutline, checkmarkDoneOutline} from "ionicons/icons";
import {currencyList} from "../utils/currencyList";
import {useApi} from "../providers/LaravelApiProvider";
import moment from "moment";
import 'react-phone-input-2/lib/style.css'
import {PayConfirm} from "./ConfirmDialog";

const currencyArray = ["TRY", "EUR", "USD","GBP", "CHF"];
const paymentMethodCodes = ["credit_card", "invo", "office", "wire_transfer","current_owner", "self_key", "manuel_credit_card"];

function LastStepDialog({reservation, onChangePay, onlyDelivery, onlyReturn, onChangeHgs}) {

    const {api, user} = useApi();

    let [payType, setPayType] = useState(null);
    let [reservationData, setReservationData ] = useState(null);
    let [price, setPrice] = useState(null);
    let [provisionPrice, setProvisionPrice] = useState(null);
    let [provision, setProvision] = useState({status: false, message: null});
    let [alertData, setAlertData] = useState({
        message: "",
        show: false,
    });

    let [cardForm, setCardForm] = useState({});
    let [isPay, setIsPay] = useState(false); // default olarak borcu yok kabul ediyoruz
    let [deposit, setDeposit] = useState(false);
    let [extraSum, setExtraSum] = useState(null);
    let [totalSum, setTotalSum] = useState(null);
    let [paidSum, setPaidSum] = useState(null);
    let [willPaySum, setWillPaySum] = useState(null);
    let [currency, setCurrency] = useState(null);
    let [collections, setCollections] = useState([]);
    let [reservationExtras, setReservationExtras] = useState([]);
    let [paymentMethods, setPaymentMethods] = useState(null);
    let [exchangeRate, setExchangeRate] = useState(1);
    let [selectedCurrency, setSelectedCurrency] = useState(null);
    let [formType, setFormType] = useState("tahsilat");

    let [apiCurrency, setApiCurrency] = useState(null);
    let [defaultCurrency, setDefaultCurrency] = useState(null);
    let [vehicleAmount, setVehicleAmount] = useState(null);

    let [sortingArray, setSortingArray] = useState(["TRY", "EUR", "USD","GBP", "CHF"]);
    let [loading, setLoading] = useState(false);
    let [debtSum, setDebtSum] = useState(null);

    let [apiCurrencyData, setApiCurrencyData] = useState(null);
    let [selectedCurrencyData, setSelectedCurrencyData] = useState({currency_id: null, exchange_rate: null});
    let [incomeSum, setIncomeSum] = useState(null);
    let [minPrice, setMinPrice] = useState(null);
    let [apiPaid, setApiPaid] = useState(null);
    let [payConfirm, setPayConfirm] = useState({open: false, message: null, price: null, curr: null});
    let [description, setDescription] = useState(null);

    let [currTypes, setCurrTypes] = useState([]);
    let [cards, setCards] = useState([]);
    let [selectedCard, setSelectedCard] = useState(null);

    let [realPaid, setRealPaid] = useState(null);

    let [mode, setMode] = useState("live");


    useEffect(() => {

        load( null);

    },[reservation])

    async function load(curr) {

        getPaymentMethods(user);
        customerRealPaid()
        setSortingArray(sortCurrency(currencyArray, reservation.currency.shortcode));
        setReservationData(reservation);
        setCurrency(reservation.currency);

        if (selectedCurrency === null) {
            setSelectedCurrency(reservation.currency.shortcode);
        }

        let default_exchange_rate = reservation.exchange_rate;
        setExchangeRate(default_exchange_rate);

        setReservationExtras(reservation.extras);

        let depositData = !Boolean(reservation.no_deposit);
        setDeposit(depositData);

        let newVehicleAmount = reservation.admin_price ? (reservation.admin_price * default_exchange_rate) : ((reservation.daily_vehicle_amount * default_exchange_rate) * reservation.day_range);
        setVehicleAmount(newVehicleAmount);

        setLoading(true);
        Promise.all([
            api.get("reservation/current/" + reservation.id),
            api.get("payment_activity/fake_current/"+ reservation.id),
            api.get("mobileapp/current/" + reservation.id),
            //api.get("payment_activity/fake_current/"+ reservation.id)

        ]).then(resp => {

            let reservationCurrent = onlyDelivery === true ? resp[2] : resp[0][1][0];

            let apiCurrencies = resp[1];
            let data = apiCurrencies;
            setApiCurrencyData(data);

            let foundEur = data?.find(x => x.shortcode === "EUR");
            let foundUsd = data?.find(x => x.shortcode === "USD");
            let foundTry = data?.find(x => x.shortcode === "TRY");
            let foundGbp = data?.find(x => x.shortcode === "GBP");
            let foundChf = data?.find(x => x.shortcode === "CHF");


            let EUR = {icon: foundEur?.icon, count: foundEur?.exchange_rate}
            let USD = {icon: foundUsd?.icon, count: foundUsd?.exchange_rate}
            let TRY = {icon: foundTry?.icon, count: foundTry?.exchange_rate}
            let GBP = {icon: foundGbp?.icon, count: foundGbp?.exchange_rate}
            let CHF = {icon: foundChf?.icon, count: foundChf?.exchange_rate}

            let currencyResult = {
                TRY: TRY,
                EUR: EUR,
                USD: USD,
                GBP: GBP,
                CHF: CHF
            }

            setApiCurrency(currencyResult);


            if (selectedCurrency === null) {
                    setCurrTypes(apiCurrencies);
                    setSelectedCurrencyData({
                        currency_id: reservation.currency.id,
                        exchange_rate: currencyResult[reservation.currency.shortcode]?.count
                    })
            }

            let payyable = reservationCurrent?.payyable;
            let paid = reservationCurrent?.paid;
            let final_amount = reservationCurrent?.final_amount;

            setApiPaid(paid);

            let debtData = getWithCurrencyLoad(reservation.currency.shortcode, payyable, currencyResult);

            if (paid >= reservation.extras_amount * default_exchange_rate) {
                let minPriceData = getWithCurrencyLoad(reservation.currency.shortcode, 0, currencyResult);
                setMinPrice(minPriceData);
            } else {
                let newExtraAmount = reservation.extras_amount * default_exchange_rate;
                let minPrice = newExtraAmount - paid;
                let minPriceData = getWithCurrencyLoad(reservation.currency.shortcode, minPrice, currencyResult);
                setMinPrice(minPriceData);
            }


            let filterIncomes = reservation.extras.filter(x => x.extra.extra_type === 1);
            let filterExtras = reservation.extras.filter(x => x.extra.extra_type === 0);

            if (filterIncomes.length > 0) {
                let sumIncome = 0;
                filterIncomes.map(item => {
                    let y = item.price * reservation.exchange_rate;
                    sumIncome = y + sumIncome;
                })
                let incomeSumData = getWithCurrencyLoad(reservation.currency.shortcode, sumIncome, currencyResult);
                setIncomeSum(incomeSumData);
            }

            if (filterExtras.length > 0) {
                let sumExtra = 0;
                filterExtras.map(item => {
                    let y = item.price * reservation.exchange_rate;
                    sumExtra = y + sumExtra;
                })
                let extraSumData = getWithCurrencyLoad(reservation.currency.shortcode, sumExtra, currencyResult);
                setExtraSum(extraSumData);
            } else {
                let extraSumData = getWithCurrencyLoad(reservation.currency.shortcode, 0, currencyResult);
                setExtraSum(extraSumData);
            }

            if (payyable !== null) {

                let paidData = getWithCurrencyLoad(reservation.currency.shortcode, paid, currencyResult);
                let willPaidData = getWithCurrencyLoad(reservation.currency.shortcode, payyable, currencyResult);
                setPaidSum(paidData);
                setWillPaySum(willPaidData);
                setDebtSum(debtData);

                if (curr) {
                    setPrice(money(willPaidData[curr]?.price));
                } else if (selectedCurrency) {
                    setPrice(money(willPaidData[selectedCurrency]?.price));
                } else {
                    setPrice(money(willPaidData[reservation.currency.shortcode]?.price));
                }

                if (money(payyable) <= money(0)) {
                    setIsPay(true);
                    onChangePay(true);
                }
            } else {
                let kalan = (reservation.final_amount * default_exchange_rate);

                let paidData = getWithCurrencyLoad(reservation.currency.shortcode, 0, currencyResult);
                let willPaidData = getWithCurrencyLoad(reservation.currency.shortcode, kalan, currencyResult);

                setPaidSum(paidData);
                setWillPaySum(willPaidData);
                setDebtSum(willPaidData);

                if (curr) {
                    setPrice(money(willPaidData[curr]?.price));
                } else if (selectedCurrency) {
                    setPrice(money(willPaidData[selectedCurrency]?.price));
                } else {
                    setPrice(money(willPaidData[reservation.currency.shortcode]?.price));
                }
            }

            let totalSumData = getWithCurrencyLoad(reservation.currency.shortcode, final_amount, currencyResult);
            setTotalSum(totalSumData);
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })

    }

    async function customerRealPaid(){
        await api.get('user/current/'+reservation.user_id, {method: true}).then(resp => {

            if(resp[1]){
                let response = resp[1][0];

                let sum = (response.final_amount) - (response.real_paid);
                let converted = currencyFormat(sum) + " " + (response?.currency ? response?.currency?.icon : "");
                setRealPaid(converted);
            }
        })
    }

    function sortCurrency(currencyArray, code){

        let sorted = currencyArray.sort(function (a,b) {
            if (a === code) {
                return -1;
            }else{
                return 1
            }

            return 0;
        })

        return sorted;

    }

    function getPaymentMethods(userData){
            setPaymentMethods(userData?.payment_methods);

    }

    function getWithCurrencyLoad(curr, price, currencyList) {

        if(curr === "EUR" ){
            let TRY = Number(currencyList?.TRY?.count);
            let EUR = Number(currencyList?.EUR?.count);
            let USD = Number(currencyList?.USD?.count);
            let GBP = Number(currencyList?.GBP?.count);
            let CHF = Number(currencyList?.CHF?.count);


            return {
                TRY: {icon: "₺", price: price * TRY},
                EUR: {icon: "€", price: price * EUR},
                USD: {icon: "$", price: price * USD},
                GBP: {icon: "£", price: price * GBP},
                CHF: {icon: "CHF", price: price * CHF}
            }
        }else{
            return {
                TRY: {icon: "₺", price: ((price * currencyList?.TRY.count) / currencyList[curr]?.count)},
                EUR: {icon: "€", price: ((price * currencyList?.EUR.count) / currencyList[curr]?.count)},
                USD: {icon: "$", price: ((price * currencyList?.USD.count) / currencyList[curr]?.count)},
                GBP: {icon: "£", price: ((price * currencyList?.GBP.count) / currencyList[curr]?.count)},
                CHF: {icon: "CHF", price: ((price * currencyList?.CHF.count) / currencyList[curr]?.count)}
            }
        }
    }

    function onChangeCash(e) {
        setPrice(e.target.value);
    }

    function onChangeProvision(e) {
        setProvisionPrice(e.target.value);
    }

    function closeAlertDialog(){
        setAlertData({show: false, message: ""});
    }

    function onChangeCard(e) {
        let oldCardForm = cardForm;
        setCardForm({
            ...oldCardForm,
            [e.target.name] : e.target.value
        })
    }

    function submit() {

        if (selectedCurrency === null) {
            setAlertData({show: true, message: "Lütfen para tipi (kur) seçiniz"});
            return false;
        }

        if (price === null || Number(price) === 0) {
            setAlertData({show: true, message: "Girilen tutar 0'dan farklı olmalıdır"});
            return false;
        }

        if (willPaySum !== null) {
            let willPaySumData = willPaySum[selectedCurrency]?.price;
            if (Number(willPaySumData) <= 0) {
                setAlertData({show: true, message: "Tahsilatınız tamamlanmıştır! Teslimatı bitirebilirsiniz"});
                return false;
            }
        }

        if (collections.length === 0 && apiPaid === null || apiPaid === 0) {

            let extraTotal = extraSum[selectedCurrency]?.price;
            let totalSumData = totalSum[selectedCurrency]?.price;

            if (Number(money(price)) === Number(money(extraTotal))) {


                pay(price, selectedCurrency).then(resp => {
                    if (resp === true) {
                        load(selectedCurrency);
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Ekstralar"}
                        ])
                    }
                })

            } else if (Number(money(price)) >= Number(money(extraTotal))) {


                pay(price, selectedCurrency).then(resp => {
                    if (resp === true) {
                        load(selectedCurrency);
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Ekstralar"},
                            {price: Number(price-extraTotal), currency: selectedCurrency, payType: payType.name, payValue: "Araç"}
                        ])
                    }
                })

            } else if (Number(money(price)) < Number(money(extraTotal))) {
                setAlertData({show: true, message: "İlk tahsilatınız Ekstra toplamından küçük olamaz!"});
                return false;
            }
        } else {
            let willPaySumData = willPaySum[selectedCurrency]?.price;

            if (Number(money(price)) === Number(money(willPaySumData))) {

                pay(price, selectedCurrency).then(resp => {
                    if (resp === true) {
                        load(selectedCurrency);
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Araç"}
                        ])
                    }
                })


            } else if (Number(money(price)) > Number(money(willPaySumData))) {
                pay(price, selectedCurrency).then(resp => {
                    if (resp === true) {
                        load(selectedCurrency);
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Araç"}
                        ])
                    }
                })


            } else if (Number(money(price)) < Number(money(willPaySumData))) {

                if (minPrice) {
                    if (price < minPrice[selectedCurrency]?.price) {
                        setAlertData({
                            show: true,
                            message: "İlk tahsilatınız minimum " + money(minPrice[selectedCurrency]?.price) + minPrice[selectedCurrency]?.icon + " olmalıdır"
                        });
                        return false;
                    }
                }

                pay(price, selectedCurrency).then(resp => {
                    if (resp === true) {
                        load(selectedCurrency);
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Araç"}
                        ])
                    }
                })

            }
        }

    }

    function payConvertWithCurrency(currTypes, price, exchange_rate){

        let foundTRY = currTypes.find(x => x.shortcode === "TRY");
        let foundEUR = currTypes.find(x => x.shortcode === "EUR");
        let foundUSD = currTypes.find(x => x.shortcode === "USD");
        let foundGBP = currTypes.find(x => x.shortcode === "GBP");
        let foundCHF = currTypes.find(x => x.shortcode === "CHF");

        let TRY = (price / exchange_rate) * Number(foundTRY?.exchange_rate);
        let EUR = (price / exchange_rate) * Number(foundEUR?.exchange_rate);
        let USD = (price / exchange_rate) * Number(foundUSD?.exchange_rate);
        let GBP = (price / exchange_rate) * Number(foundGBP?.exchange_rate);
        let CHF = (price / exchange_rate) * Number(foundCHF?.exchange_rate);

        return {
            TRY: {icon: "₺", price: TRY},
            EUR: {icon: "€", price: EUR},
            USD: {icon: "$", price: USD},
            GBP: {icon: "£", price: GBP},
            CHF: {icon: "CHF", price: CHF}
        };

    }

    function pay(price, curr){

        let newPrice = payConvertWithCurrency(currTypes, price, selectedCurrencyData.exchange_rate);

        return new Promise((resolve, reject) => {

            if(Math.round(newPrice[selectedCurrency]?.price) > Math.round(willPaySum[selectedCurrency]?.price)){
                setPayConfirm({
                    open: true,
                    message: "Borç tutarından fazla tahsilat yapmak istediğinize emin misiniz?",
                    price: newPrice[selectedCurrency]?.price,
                    curr:selectedCurrency
                });
                resolve(false);

            }else{

                if(payType.code === "credit_card"){

                    if(!cardForm?.CCname){
                        setAlertData({show: true, message:"Kart sahibinin adını giriniz"});
                        setLoading(false);
                        return false;
                    }

                    if(!cardForm?.CCnumber){
                        setAlertData({show: true, message:"Kart numarasını giriniz"});
                        setLoading(false);
                        return false;
                    }

                    if(!cardForm?.CCexpiryMonth){
                        setAlertData({show: true, message:"Kart üzerindeki ay kısmını giriniz"});
                        setLoading(false);
                        return false;
                    }

                    if(!cardForm?.CCexpiryYear){
                        setAlertData({show: true, message:"Kart üzerindeki yıl kısmını giriniz"});
                        setLoading(false);
                        return false;
                    }

                    if(!cardForm?.CCcvc){
                        setAlertData({show: true, message:"Kartın arka yüzündeki CVC numarasını giriniz"});
                        setLoading(false);
                        return false;
                    }

                    let params = {
                        paid: Number(price),
                        currency_id: selectedCurrencyData.currency_id,
                        exchange_rate: selectedCurrencyData.exchange_rate,
                        description: description,
                        payment_method: {
                            id: payType.id,
                            name: payType.name,
                            code: payType.code,
                            sort: payType.sort,
                            pivot: {
                                user_id: reservationData?.user_id,
                                payment_method_id: payType.id
                            },
                        },
                        card_number: cardForm.CCnumber,
                        name_on_the_card: cardForm.CCname,
                        month: cardForm.CCexpiryMonth,
                        year: cardForm.CCexpiryYear,
                        cvv: cardForm.CCcvc,
                        reservation_id: reservationData?.id
                    }

                    setLoading(true);
                    api.post("payment_activity", params).then(resp => {
                        resolve(resp.status);
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    })

                }else{

                    let params = {
                        paid: Number(price),
                        currency_id: selectedCurrencyData.currency_id,
                        exchange_rate: selectedCurrencyData.exchange_rate,
                        description: description,
                        payment_method: {
                            id: payType.id,
                            name: payType.name,
                            code: payType.code,
                            sort: payType.sort,
                            pivot: {
                                user_id: reservationData?.user_id,
                                payment_method_id: payType.id
                            },
                        },
                        card_number: "",
                        name_on_the_card: "",
                        month: "",
                        year: "",
                        cvv: "",
                        reservation_id: reservationData?.id
                    }

                    setLoading(true);
                    api.post("payment_activity", params).then(resp => {
                        resolve(resp.status);
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    })
                }
            }
        })

    }

    function payConfirmClose(price, curr, value){

        if(value === true){
            setPayConfirm({open: false, message: null, curr: null, price: null });
            if(payType.code === "credit_card"){

                if(!cardForm?.CCname){
                    setAlertData({show: true, message:"Kart sahibinin adını giriniz"});
                    setLoading(false);
                    return false;
                }

                if(!cardForm?.CCnumber){
                    setAlertData({show: true, message:"Kart numarasını giriniz"});
                    setLoading(false);
                    return false;
                }

                if(!cardForm?.CCexpiryMonth){
                    setAlertData({show: true, message:"Kart üzerindeki ay kısmını giriniz"});
                    setLoading(false);
                    return false;
                }

                if(!cardForm?.CCexpiryYear){
                    setAlertData({show: true, message:"Kart üzerindeki yıl kısmını giriniz"});
                    setLoading(false);
                    return false;
                }

                if(!cardForm?.CCcvc){
                    setAlertData({show: true, message:"Kartın arka yüzündeki CVC numarasını giriniz"});
                    setLoading(false);
                    return false;
                }


                let params = {
                    paid: Number(price),
                    currency_id: selectedCurrencyData.currency_id,
                    exchange_rate: selectedCurrencyData.exchange_rate,
                    description: description,
                    payment_method: {
                        id: payType.id,
                        name: payType.name,
                        code: payType.code,
                        sort: payType.sort,
                        pivot: {
                            user_id: reservationData?.user_id,
                            payment_method_id: payType.id
                        },
                    },
                    card_number: cardForm.CCnumber,
                    name_on_the_card: cardForm.CCname,
                    month: cardForm.CCexpiryMonth,
                    year: cardForm.CCexpiryYear,
                    cvv: cardForm.CCcvc,
                    reservation_id: reservationData?.id
                }

                setLoading(true);
                api.post("payment_activity", params).then(resp => {
                    if(resp.status === true){
                        load();
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Tümü"}
                        ])
                    }
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })

            }else{

                let params = {
                    paid: Number(price),
                    currency_id: selectedCurrencyData.currency_id,
                    exchange_rate: selectedCurrencyData.exchange_rate,
                    description: description,
                    payment_method: {
                        id: payType.id,
                        name: payType.name,
                        code: payType.code,
                        sort: payType.sort,
                        pivot: {
                            user_id: reservationData?.user_id,
                            payment_method_id: payType.id
                        },
                    },
                    card_number: "",
                    name_on_the_card: "",
                    month: "",
                    year: "",
                    cvv: "",
                    reservation_id: reservationData?.id
                }

                setLoading(true);
                api.post("payment_activity", params).then(resp => {
                    if(resp.status === true){
                        load();
                        setCollections([
                            ...collections,
                            {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Tümü"}
                        ])
                    }
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })
            }
        }
        setPayConfirm({open: false, message: null, curr: null, price: null });
    }

    function payWithSelfKey(){
        let params = {
            paid: Number(price),
            currency_id: selectedCurrencyData.currency_id,
            exchange_rate: selectedCurrencyData.exchange_rate,
            description: description,
            payment_method: {
                id: payType.id,
                name: payType.name,
                code: payType.code,
                sort: payType.sort,
                pivot: {
                    user_id: reservationData?.user_id,
                    payment_method_id: payType.id
                },
            },
            card_number: "",
            name_on_the_card: "",
            month: "",
            year: "",
            cvv: "",
            reservation_id: reservationData?.id,
            self_key_id: selectedCard
        }


        setLoading(true);
        api.post("payment_activity", params).then(resp => {
            if(resp.status === true){
                load();
                setCollections([
                    ...collections,
                    {price: Number(price), currency: selectedCurrency, payType: payType.name, payValue: "Tümü"}
                ])
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })

    }


    function payProvision(){

        let params = {
            paid: Number(provisionPrice),
            card_number: cardForm.CCnumber,
            name_on_the_card: cardForm.CCname,
            month: cardForm.CCexpiryMonth,
            year: cardForm.CCexpiryYear,
            cvv: cardForm.CCcvc,
            reservation_id: reservation.id
        }

        setLoading(true);
        api.post('payment_activity/provision', params).then(resp => {
            if(resp === true){
                setProvision({
                    status: true,
                    message: provisionPrice + " TL provizyon alınmıştır"
                })
            }
            setLoading(false)
        }).finally(() => {
            setLoading(false);
        })

    }

    function onCurrencySelectChange(value){
        setSelectedCurrency(value);
        let found = currTypes.find(x => x.shortcode === value);
        setSelectedCurrencyData({
            currency_id: found.id,
            exchange_rate: found.exchange_rate
        })
        setPrice(money(willPaySum[value]?.price));

    }

    function onChangePayType(e){
        setPayType(e.detail.value);
        setDescription(null);

        if(e.detail.value.code === "self_key"){
            setLoading(true);
            api.post('payment_activity/self_key/' + reservation.id).then(resp => {
                setCards(resp.data);
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        }else{
            setCards([]);
            setSelectedCard(null);
        }
    }

    function onSelectCard(value){
        setSelectedCard(value);
    }


    return (
        <IonContent scrollX className="ion-padding align-content-center scroll-content">
            <div className="d-flex justify-content-center">
                <IonItem>
                    <h4>ÖDEME #{reservation.code}</h4>
                    {/*<button onClick={() => {*/}
                    {/*    console.log(selectedCard, 'selected card');*/}
                    {/*    console.log(cards, 'cards');*/}
                    {/*    console.log(payType, 'pay type');*/}
                    {/*}}>test</button>*/}
                </IonItem>
            </div>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {
                }}
                message={'İşlem Gerçekleştiriliyor...'}
            />

            <AlertDialog show={alertData.show} message={alertData.message} onClose={closeAlertDialog}/>
            <PayConfirm
                show={payConfirm.open}
                message={payConfirm.message}
                onClose={payConfirmClose}
                price={payConfirm.price}
                curr={payConfirm.curr}
            />
            <div className="d-md-flex justify-content-md-center">
                <IonCard className="pay-card">
                    <div className="d-flex justify-content-between px-1">
                        {onlyDelivery === true && (
                            <div className="flex-fill">
                                <IonButton className="w-100" color={formType === "tahsilat" ? "light" : "light"}
                                           onClick={() => setFormType("provizyon")}>
                                    PROVİZYON
                                </IonButton>
                            </div>
                        )}

                        <div className="flex-fill">
                            <IonButton className="w-100" color={formType === "tahsilat" ? "light" : "light"}
                                       onClick={() => setFormType("tahsilat")}>
                                TAHSİLAT
                            </IonButton>
                        </div>
                    </div>
                    {formType === "tahsilat" && (
                        <IonCardContent className="pay-card-content">

                            <div className="d-flex flex-wrap align-items-center">
                                <span>Ödenecek: </span>
                                {willPaySum && sortingArray.map((item, idx) => (
                                    <span key={"will-pay" + idx}
                                          className={selectedCurrency === item ? "custom-badge-danger-active mx-1" : "custom-badge-danger mx-1 d-none d-md-block"}>{currencyFormat(willPaySum?.[item]?.price)} {willPaySum[item]?.icon}</span>
                                ))}
                            </div>
                            <div className="d-flex flex-wrap align-items-center my-2">
                                <span>Ödenen: </span>
                                {paidSum && sortingArray.map((item, idx) => (
                                    <span key={"paid-" + idx}
                                          className={selectedCurrency === item ? "custom-badge-success-active mx-1 f" : "custom-badge-success mx-1 d-none d-md-block"}>{currencyFormat(paidSum[item]?.price)} {paidSum[item]?.icon}</span>
                                ))}
                            </div>
                            <div className="d-flex justify-content-between" style={{borderBottom: "1px solid gray"}}>
                                <div  className="d-flex flex-column pb-2 mb-2">
                                    <div className="d-flex flex-column mb-2">
                                        <span>Araç Tutarı:<strong> {currencyFormat(vehicleAmount)} {reservationData?.currency?.icon}</strong></span>
                                    </div>
                                    <div className="d-flex flex-column mb-2">
                                        <span>Drop: <strong>{currencyFormat(reservationData?.drop_amount * exchangeRate)} {reservationData?.currency?.icon}</strong></span>
                                    </div>
                                    <div className="d-flex flex-column mb-2">
                                        <span><strong className="text-info">Anlık Hgs Bedeli:</strong> <strong>{currencyFormat(reservationData?.now_hgs_amount)} TL</strong></span>
                                    </div>
                                    <div className="d-flex flex-column mb-2">
                                        <span><strong className="text-danger">Müşteri cari genel toplam:</strong> <strong>{realPaid}</strong></span>
                                    </div>


                                    <div className="d-flex flex-wrap">
                                        {reservation.extras.length !== 0 && reservation.extras.map((item, idx) => (
                                            <span key={"extras-badge" + idx}
                                                  className={"text-white mb-2 mr-2 custom-badge-size p-1" + (item.extra.extra_type === 1 ? " badge badge-secondary " : " badge badge-info ")}>{item.extra.name.tr}: <strong> {money(item.price * exchangeRate)} {reservationData?.currency?.icon}</strong></span>
                                        ))}
                                    </div>
                                </div>

                            </div>


                            <div className="d-flex flex-wrap align-items-center my-2">
                                <span>Cari Borç: </span>
                                {debtSum && sortingArray.map((item, idx) => (
                                    <span key={"cari-borc" + idx}
                                          className={selectedCurrency === item ? "custom-badge-warning-active custom-badge-size mx-1" : "custom-badge-warning custom-badge-size mx-1 d-none d-md-block"}>{currencyFormat(debtSum[item].price)} {debtSum[item].icon}</span>
                                ))}
                            </div>
                            <div className="d-flex flex-wrap align-items-center my-2">
                                <span>Ekstra Toplam </span>
                                {extraSum && sortingArray.map((item, idx) => (
                                    <span key={"extra-sum" + idx}
                                          className={selectedCurrency === item ? "custom-badge-secondary-active custom-badge-size mx-1" : "custom-badge-secondary custom-badge-size mx-1 d-none d-md-block"}>{currencyFormat(extraSum[item].price)} {extraSum[item].icon}</span>
                                ))}
                            </div>
                            {incomeSum && (
                                <div className="d-flex flex-wrap align-items-center my-2">
                                    <span>D.Gelir Toplam: </span>
                                    {incomeSum && sortingArray.map((item, idx) => (
                                        <span key={"incomes-sum" + idx}
                                              className={selectedCurrency === item ? "custom-badge-secondary-active custom-badge-size mx-1" : "custom-badge-secondary custom-badge-size mx-1 d-none d-md-block"}>{currencyFormat(incomeSum[item].price)} {incomeSum[item].icon}</span>
                                    ))}
                                </div>
                            )}
                            <div className="d-flex flex-wrap align-items-center my-2">
                                <span>Genel Toplam: </span>
                                {totalSum && sortingArray.map((item, idx) => (
                                    <span key={"total-sum" + idx}
                                          className={selectedCurrency === item ? "custom-badge-secondary-active custom-badge-size mx-1" : "custom-badge-secondary custom-badge-size mx-1 d-none d-md-block"}>{currencyFormat(totalSum[item]?.price)} {totalSum[item]?.icon}</span>
                                ))}
                            </div>

                            {isPay === true ? (
                                <>
                                    <div className="d-flex justify-content-center my-3">
                                        <div className="badge badge-success p-3">
                                            <IonIcon icon={checkmarkDoneOutline} size={"large"}/><br/>
                                            <h1>Ödemesi Yapılmıştır</h1>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <IonItem className="select-border mx-2">
                                        <IonLabel>Ödeme Tipi </IonLabel>
                                        <IonSelect okText="Seç" cancelText="İptal" value={payType} onIonChange={(e) => {
                                            onChangePayType(e)
                                        }}>
                                            {paymentMethods && paymentMethods.map((item, idx) => (
                                                <>
                                                    <IonSelectOption
                                                        disabled={(onlyDelivery === false && item.code === "current_owner")}
                                                        key={"pay-type" + idx} value={item}
                                                        className="position-relative">{item.name}</IonSelectOption>
                                                </>
                                            ))}
                                        </IonSelect>
                                    </IonItem>
                                    <div className="bg-light p-2 my-2">
                                        {payType && (
                                            <div>
                                                {(payType.code === "credit_card") ? (
                                                    <div className="my-2 ">
                                                        <div className="mt-2">
                                                            <ReactCardFormContainer
                                                                container="react-card-container"
                                                                formInputsNames={{
                                                                    number: 'CCnumber',
                                                                    expiry: 'CCexpiry',
                                                                    cvc: 'CCcvc',
                                                                    name: 'CCname'
                                                                }}
                                                                classes={{
                                                                    valid: 'valid',
                                                                    invalid: 'invalid'
                                                                }}
                                                                formatting={true}
                                                            >
                                                                <form>
                                                                    <div
                                                                        className="pay-input-group d-flex justify-content-start px-2 pt-1">
                                                                        <div className="flex-fill">
                                                                            <strong className="label">Kur</strong>
                                                                            <CurrencySelect value={selectedCurrency}
                                                                                            onChange={onCurrencySelectChange}/>
                                                                        </div>
                                                                        <div className="flex-fill ">
                                                                            <strong className="label">Ödenecek Tutar</strong>
                                                                            <input value={price} onChange={onChangeCash}
                                                                                   className="w-100 input-border mb-2 pl-2"
                                                                                   name="cash_price" type="number"
                                                                                   placeholder="Nakit Tutarı"/>
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <span className="mb-2">*Lütfen kart üzerindeki bilgileri aşağıdaki alanlara giriniz.</span>

                                                                    <input className="w-100 input-border mb-2 mt-2 pl-2"
                                                                           name="CCname" type="text" onChange={onChangeCard}
                                                                           placeholder="Kart Sahibi"/>

                                                                    <input className="w-100 input-border mb-2 pl-2"
                                                                           name="CCnumber" type="text" onChange={onChangeCard}
                                                                           placeholder="Kart Numarası"/>

                                                                    <div className="d-flex justify-content-start">
                                                                        <input className="input-border w-100 pl-2"
                                                                               name="CCexpiryMonth" type="text"
                                                                               onChange={onChangeCard} placeholder="Ay"/>
                                                                        <input className="input-border w-100 pl-2"
                                                                               name="CCexpiryYear" type="text"
                                                                               onChange={onChangeCard} placeholder="Yıl"/>
                                                                        <input className="input-border w-100 pl-2 ml-2"
                                                                               name="CCcvc" type="text" onChange={onChangeCard}
                                                                               placeholder="CVC"/>
                                                                    </div>
                                                                    <div className="my-3 pay-input-group p-2">
                                                                        <IonTextarea
                                                                            className="border border-secondary rounded bg-white"
                                                                            placeholder="Açıklama..." value={description}
                                                                            onIonChange={(e) => setDescription(e.detail.value)}/>
                                                                    </div>
                                                                    <IonButton type="button" className="pay-button mt-2"
                                                                               color="tertiary" expand={"block"}
                                                                               onClick={() => {
                                                                                   submit()
                                                                               }}>ÖDEME YAP</IonButton>
                                                                </form>

                                                            </ReactCardFormContainer>
                                                        </div>
                                                    </div>
                                                ) : (payType.code === "self_key") ? (
                                                    <div>
                                                        <div style={{overflow: "scroll", maxHeight: "400px"}}
                                                             className="pay-input-group p-2">
                                                            <div>
                                                                <strong className="label">Kartlar</strong>
                                                            </div>
                                                            <div className="mb-2">
                                                                <small className="text-white">*Kart seçiniz</small>
                                                            </div>
                                                            {cards.length > 0 ? (
                                                                <>
                                                                    {
                                                                        cards.map((item, idx) => (
                                                                            <IonItem
                                                                                className={item.id === selectedCard?.id ? "selected-card" : ""}
                                                                                button onClick={() => onSelectCard(item)}>
                                                                                <span>{item.Type + " | " + item.card + " | Son Çekilen Tutar " + currencyFormat(item.Amount)}</span>
                                                                                {item.id === selectedCard?.id && (
                                                                                    <IonIcon icon={checkmarkCircle}
                                                                                             className={"text-success"}/>)}
                                                                            </IonItem>
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <span className="text-white">Kart Bilgisi Yok</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {selectedCard && (
                                                            <div className="pay-input-group p-2">
                                                                <div
                                                                    className="pay-input-group d-flex justify-content-start px-2 pt-1 mb-2">
                                                                    <div className="flex-fill">
                                                                        <strong className="label">Kur</strong>
                                                                        <CurrencySelect value={selectedCurrency}
                                                                                        onChange={onCurrencySelectChange}/>
                                                                    </div>
                                                                    <div className="flex-fill ">
                                                                        <strong className="label">Ödenecek Tutar</strong>
                                                                        <input value={price} onChange={onChangeCash}
                                                                               className="w-100 input-border mb-2 pl-2"
                                                                               name="cash_price" type="number"
                                                                               placeholder="Nakit Tutarı"/>
                                                                    </div>
                                                                </div>
                                                                <div className="my-2">
                                                                    <IonTextarea
                                                                        className="border border-secondary rounded bg-white"
                                                                        placeholder="Açıklama..." value={description}
                                                                        onIonChange={(e) => setDescription(e.detail.value)}/>
                                                                </div>
                                                                <IonButton type="button" className="pay-button" color="tertiary"
                                                                           expand={"block"}
                                                                           onClick={() => payWithSelfKey()}>KAYDET</IonButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="my-2">
                                                        <div
                                                            className="pay-input-group d-flex justify-content-start px-2 pt-1 mb-2">
                                                            <div className="flex-fill">
                                                                <strong className="label">Kur</strong>
                                                                <CurrencySelect value={selectedCurrency}
                                                                                onChange={onCurrencySelectChange}/>
                                                            </div>
                                                            <div className="flex-fill ">
                                                                <strong className="label">Ödenecek Tutar</strong>
                                                                <input value={price} onChange={onChangeCash}
                                                                       className="w-100 input-border mb-2 pl-2"
                                                                       name="cash_price" type="number"
                                                                       placeholder="Nakit Tutarı"/>
                                                            </div>
                                                        </div>
                                                        <div className="my-2 pay-input-group p-2">
                                                            <IonTextarea className="border border-secondary rounded bg-white"
                                                                         placeholder="Açıklama..." value={description}
                                                                         onIonChange={(e) => setDescription(e.detail.value)}/>
                                                        </div>
                                                        <IonButton type="button" className="pay-button" color="tertiary"
                                                                   expand={"block"} onClick={() => submit()}>KAYDET</IonButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                </>
                            )}

                            {collections.length > 0 && (
                                <div className="p-2 border border-secondary my-2">
                                    <span style={{fontSize: "20px"}}><strong>Tahsilat Listesi</strong></span>
                                    <div className="d-flex flex-column">
                                        {collections.length > 0 && collections.map((item, idx) => (
                                            <>
                                                <div key={"collections" + idx}
                                                     className="row d-flex align-items-center">
                                                    <div className="col-4">
                                                        {item.payValue}
                                                    </div>
                                                    <div className="col-4">
                                                        {money(item.price)} {item.currency}
                                                    </div>
                                                    <div className="col-4">
                                                        {item.payType}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </IonCardContent>
                    )}

                    {formType === "provizyon" && (
                        <IonCardContent className="pay-card-content">
                            {provision.status === true && (
                                <>
                                    <div className="d-flex justify-content-center my-3">
                                        <div className="badge badge-success p-3">
                                            <IonIcon icon={checkmarkDoneOutline} size={"large"}/><br/>
                                            <h1>{provision.message}</h1>
                                        </div>
                                    </div>
                                </>
                            )}

                            {provision.status === false && (
                                <>
                                    <div className="my-2 ">
                                        <small className="mb-2">*Lütfen kart üzerindeki bilgileri aşağıdaki alanlara
                                            giriniz.</small>
                                        <div className="mt-2">
                                            <ReactCardFormContainer
                                                container="react-card-container"
                                                formInputsNames={{
                                                    number: 'CCnumber',
                                                    expiry: 'CCexpiry',
                                                    cvc: 'CCcvc',
                                                    name: 'CCname'
                                                }}
                                                classes={{
                                                    valid: 'valid',
                                                    invalid: 'invalid'
                                                }}
                                                formatting={true}
                                            >
                                                <form>
                                                    <div className="d-flex justify-content-start">
                                                        <input value={provisionPrice} onChange={onChangeProvision}
                                                               className="w-100 input-border mb-2 pl-2"
                                                               name="cash_price" type="number"
                                                               placeholder="Provizyon Tutarı (TL)"/>
                                                    </div>
                                                    <input className="w-100 input-border mb-2 pl-2" name="CCname"
                                                           type="text" onChange={onChangeCard}
                                                           placeholder="Kart Sahibi"/>

                                                    <input className="w-100 input-border mb-2 pl-2" name="CCnumber"
                                                           type="text" onChange={onChangeCard}
                                                           placeholder="Kart Numarası"/>

                                                    <div className="d-flex justify-content-start">
                                                        <input className="input-border w-100 pl-2" name="CCexpiryMonth"
                                                               type="text" onChange={onChangeCard} placeholder="Ay"/>
                                                        <input className="input-border w-100 pl-2" name="CCexpiryYear"
                                                               type="text" onChange={onChangeCard} placeholder="Yıl"/>
                                                        <input className="input-border w-100 pl-2 ml-2" name="CCcvc"
                                                               type="text" onChange={onChangeCard} placeholder="CVC"/>
                                                    </div>
                                                    <IonButton type="button" className="pay-button mt-2"
                                                               color="tertiary" expand={"block"} onClick={() => {
                                                        payProvision()
                                                    }}>ÖDEME YAP</IonButton>
                                                </form>
                                            </ReactCardFormContainer>
                                        </div>
                                    </div>
                                </>
                            )}

                        </IonCardContent>
                    )}
                </IonCard>

            </div>
        </IonContent>
    );
}

function CurrencySelect({onChange, value, disabled}) {

    let [selected, setSelected] = useState(null);

    useEffect(() => {
        setSelected(value);
    },[value])

    function onIonChange(value) {
        setSelected(value);
        onChange(value)
    }

    return (
        <>
            <IonSelect disabled={disabled} okText="Seç" cancelText="Kapat" className="currency-border bg-white w-100" style={{color: "blue", fontWeight: "bold"}} value={selected} placeholder="Para Tipi" onIonChange={e => {onIonChange(e.detail.value)}}>
                {currencyList.map((item, idx) => (
                    <IonSelectOption key={"currency-list" + idx} value={item.code}>{item.icon} ({item.name})</IonSelectOption>
                ))}
            </IonSelect>
        </>
    )
}



export default LastStepDialog;

