import {
    IonButton,
    IonButtons,
    IonContent, IonIcon,
    IonItem,
    IonList, IonLoading,
    IonModal, IonSearchbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import Input from "./Input";
import validate from "../utils/driver_add_validate";
import DateInput from "./DateInput";
import {useApi} from "../providers/LaravelApiProvider";
import AlertDialog from "./AlertDialog";
import {checkmarkCircleOutline, checkmarkDoneOutline, closeOutline} from "ionicons/icons";

function DriverChangeDialog({show, onClose, reservation, onDriverAddEditChange, defaultDriverData}) {

    const {api} = useApi();
    let [form, setForm] = useState(null);
    let [errors, setErrors] = useState(null);
    let [status, setStatus] = useState("search");
    let [searchResult, setSearchResult] = useState([]);
    let [searchText, setSearchText] = useState(null);
    let [selected, setSelected] = useState(null);
    let [loading, setLoading] = useState(false);
    let [alertDialog, setAlertDialog] = useState({show: false, message: null})

    useEffect(() => {

    },[])

    function onCloseModal() {
        //setForm({});
        setSearchText(null);
        setSearchResult([]);
        setErrors(null);
        onClose();
    }

    function onChange(key, value) {
        let oldForm = form;
        setForm({
            ...oldForm,
            [key]: value
        })
    }

    function searchUser(value) {
        if(value){
            setSearchText(value);
            api.get('user', {search: value}).then(resp => {
                setSearchResult(resp.data);
            })
        }else{
            setSearchResult([]);
            setSearchText(value);

        }
    }

    function onSelectUser(data) {

        let newForm = {
            user_id: data.id,
            full_name: data.name,
            phone: data.phone,
            identity_number: data.identity_number,
            email: data.email,
            address: data.address
        }
        setStatus("add");
        setForm(newForm);

    }

    function submit() {
        if(!form){
            setAlertDialog({show: true,message: 'Lütfen istenen alanları doldurunuz'});
            return false;
        }
        let validateErrors = validate(form);
        if(validateErrors.length > 0){
            setErrors(validateErrors);
            return false;
        } else{
            save();
        }

    }

    async function save(){
        let params = {
            reservation_id: reservation.id,
            name: form?.full_name,
            email: form?.email,
            phone: form?.phone,
            address: form?.address,
            identity_number: form?.identity_number,
            user_id: form?.user_id
        }
        setLoading(true);
        await api.post('reservation/long_term/changeDriver',params).then(resp => {
          setLoading(false);
          onDriverAddEditChange();
          onClose();
        }).finally(() => {
            setLoading(false);
        })
    }


    return(
        <IonModal isOpen={show} onDidDismiss={() => {onCloseModal()}}>
            <IonContent>
                <AlertDialog show={alertDialog.show} message={alertDialog.message} onClose={() => setAlertDialog({show: false, message: null})}/>

                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={loading}
                    onDidDismiss={() => {}}
                    message={'İşlem yapılıyor...'}
                />
                <div className="p-2">
                    <div className="d-flex p-2">
                        <IonButton block onClick={() => setStatus("search")} color={status === "search" ? "success" : "light"} className="flex-fill">
                            Kişi Ara {status === "search" && (<IonIcon className="ml-2" icon={checkmarkCircleOutline}/>)}
                        </IonButton>
                        <IonButton block onClick={() => setStatus("add")} color={status === "add" ? "success" : "light"} className="ml-2 flex-fill">
                            Yeni Ekle {status === "add" && (<IonIcon className={"ml-2"} icon={checkmarkCircleOutline}/>)}
                        </IonButton>
                    </div>

                </div>
                <hr/>

                {status === "search" && (
                    <div className="px-3 bg-light">
                        <div>
                            <h4>Yeni Sürücü Ara</h4>
                        </div>
                        <IonSearchbar placeholder="Kişi Ara..." value={searchText} onIonChange={e =>{searchUser(e.detail.value)}}/>
                        <IonList>
                            {searchResult.length > 0 && searchResult.map((item, idx) => (
                                <IonItem key={"search-result" + idx} button onClick={() => {onSelectUser(item)}}>{item.name} <span className="text-muted ml-2" style={{fontSize: '14px'}}> ({item.email})</span></IonItem>
                            ))}

                        </IonList>
                    </div>
                )}

                {status === "add" && (
                    <div className="px-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>Yeni Sürücü Ekle</h4>
                            <IonButton color="danger" fill="outline" size={"small"} onClick={() => setForm({})} >Formu Temizle <IonIcon icon={closeOutline}/></IonButton>
                        </div>
                        <div className="p-2">
                            <Input className={"mb-2 text-left"} name={"full_name"} value={form?.full_name} label={"Ad Soyad"} type={"text"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"phone"} value={form?.phone} label={"Telefon"} type={"tel"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"identity_number"} value={form?.identity_number} type={"number"} label={"TC No"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"email"} value={form?.email} label={"E-Posta"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"address"} value={form?.address} label={"Adres"} type={"text"} errors={errors} onChange={onChange}/>
                        </div>

                    </div>
                )}

            </IonContent>
            <IonButtons className="p-2 d-flex justify-content-end">
                <IonButton color="medium" onClick={() => {onCloseModal()}}>Kapat</IonButton>
                <IonButton color="primary" onClick={() => {submit()}}>Sürücü Değiştir</IonButton>
            </IonButtons>

        </IonModal>
    )
}

export default DriverChangeDialog;
