import React from "react";
import {IonAlert} from "@ionic/react";
import money from "../utils/money";

function ConfirmDialog(props) {

    function onClose(value) {
         props.onClose(value);
    }

    return (<div>
        <IonAlert
            isOpen={props.show}
            //onDidDismiss={() => {onClose(false)}}
            cssClass='my-custom-class'
            header={props.title ? props.title : 'Uyarı'}
            message={props.message}
            buttons={[
                {
                    text: 'Hayır',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => {
                        onClose(false)
                    }
                },
                {
                    text: 'Evet',
                    handler: () => {
                        onClose(true)
                    }
                }
            ]}
        />
    </div>)
}

export function PayConfirm({show, message, price, curr, onClose}){

    function onAlertClose(value){
        onClose(price, curr, value);
    }

    return (
        <IonAlert
            isOpen={show}
            //onDidDismiss={() => {onClose(false)}}
            cssClass='my-custom-class'
            header={'Uyarı'}
            message={message}
            buttons={[
                {
                    text: 'Hayır',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => {
                        onAlertClose(false)
                    }
                },
                {
                    text: 'Evet',
                    handler: () => {
                        onAlertClose(true)
                    }
                }
            ]}
        />
    )

}

export function OtherIncomeAddEditConfirm({show, data, title, message, onClose}){

    function onAlertClose(value){
         onClose(data, value);
    }

    return (
        <IonAlert
            isOpen={show}
            //onDidDismiss={() => {onClose(false)}}
            cssClass='my-custom-class'
            header={title}
            message={
                data?.item?.income_id ?
                    (data?.item?.name + ' ' + money(data?.price) +' TL olarak güncellenecektir')
                :
                    ('"' + money(data?.price) +' TL tutarında ' + data?.item?.name + ' eklenecektir')

            }
            buttons={[
                {
                    text: 'Hayır',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => {
                        onAlertClose(false)
                    }
                },
                {
                    text: 'Evet',
                    handler: () => {
                        onAlertClose(true)
                    }
                }
            ]}
        />
    )
}

export default ConfirmDialog;
