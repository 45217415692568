import React, {useEffect, useState} from "react";
import {IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLoading, IonModal, IonTextarea} from "@ionic/react";
import {useApi} from "../providers/LaravelApiProvider";
import {createOutline} from "ionicons/icons";

function RentalNote({reservation, onChange}) {

    const {api} = useApi();

    let [editDialog, setEditDialog] = useState(false);
    let [description, setDescription] = useState(null);
    let [defaultDescription, setDefaultDescription] = useState(null);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setDefaultDescription(reservation?.delivery_description);
        setDescription(reservation?.delivery_description);

    }, [reservation]);

    function openDialog(e){
        e.stopPropagation();
        setEditDialog(true);
    }

    function closeDialog(e){
        e.stopPropagation();
        setEditDialog(false);
    }


    function onInputChange(e){
        setDescription(e.detail.value);
    }

    function submit(e){
        e.stopPropagation();
        setLoading(true);
        api.post('reservation/edit/deliveryDescription/' + reservation.id,{
            delivery_description: description
        }).then(resp => {
            closeDialog(e);
            onChange();
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })

    }

    function onClickComp(e){
        e.stopPropagation();
        openDialog(e);
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    return (
        <>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'İşlem Gerçekleştiriliyor...'}
            />
            <div onClick={onClickComp} className="mr-2">
                <div className="list-description">
                    <p><IonIcon color={"primary"} icon={createOutline} style={{fontSize:"12px"}}/>{defaultDescription}</p>
                </div>
            </div>
            <IonModal isOpen={editDialog} onDidDismiss={(e) => {closeDialog(e)}}>
                <IonItem onClick={stopPropagation} className="py-2">
                    <h4>Açıklama  #{reservation.code}</h4>
                </IonItem>
                <IonItem onClick={stopPropagation} className="m-4 border border-secondary">
                    <IonTextarea placeholder="Açıklama giriniz ... " rows={6} value={description} onIonChange={onInputChange}/>
                </IonItem>

                <IonButtons onClick={stopPropagation} className="p-2 d-flex justify-content-end">
                    <IonButton color="medium" onClick={(e) => {closeDialog(e)}}>Kapat</IonButton>
                    <IonButton disabled={description === null || description === ""} color="primary" onClick={(e) => {submit(e)}}>Kaydet</IonButton>
                </IonButtons>
            </IonModal>
        </>
    )
}


export default RentalNote;
