import React from "react";
import {IonAlert} from "@ionic/react";

function DamageConfirmDialog(props) {

    return(<div>
        <IonAlert
            isOpen={props.show}
            onDidDismiss={() => {}}
            cssClass='my-custom-class'
            header={'Dikkat!'}
            message={props.message}
            inputs={[
                {
                    name: 'isDamage',
                    type: 'radio',
                    label: 'Hasarsız',
                    value: 'no-damage',
                    checked: false
                },

            ]}
            buttons={[
                {
                    text: 'İptal',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: blah => {
                        props.onClose(null);
                    }
                },
                {
                    text: 'Seç',
                    handler: (data) => {
                        props.onClose(data);
                    }
                }
            ]}
        />
    </div>)
}

export default DamageConfirmDialog;
