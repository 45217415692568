import React, {useContext, useEffect, useState} from 'react';
import {storage} from "../lib/storage";
import axios from "axios";
import {IonToast} from "@ionic/react";
 //export const apiUrl = 'https://test.e-rac.com/api';
 export const apiUrl = 'https://5s.e-rac.com/api';
 //export const apiUrl = process.env.REACT_APP_API_URL;

export const ApiContext = React.createContext(null);

export function useApi() {
    return useContext(ApiContext);
}

export default function ApiProvider(props) {

    const [user, setUser] = useState(null);
    let [toast, setToast] = useState({type: "", message: "", show: false})
    let [smsText, setSmsText] = useState(" ");

    useEffect(()=> {
        if (storage.get("user")) {
            setUser(storage.get("user"));
        }

    }, []);

    const api = {
        get : (endPoint, params) => {

            let url = new URL(apiUrl + '/' + endPoint)
            url.search = new URLSearchParams(params);

            return new Promise(function(resolve, reject) {
                return fetch(url, {
                    method: 'GET',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization" : isLogin() ? "Bearer " + user.token : null
                    }
                }).then(async resp => {
                    if (resp.status === 200 || resp.status === 201) {
                        resolve((await resp.json()));
                    } else {
                        throw (await resp.json());
                    }
                }).catch(error => {
                    globalErrorHandling(error);
                    reject(error);
                    return error;
                });
            });
        },
        post : (endPoint, params) => {

            return new Promise(function(resolve, reject) {
                return fetch(apiUrl + '/' + endPoint, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization" : isLogin() ? "Bearer " + user.token : null
                    },
                    body: JSON.stringify(params),
                }).then(async resp => {
                    if (resp.status === 200 || resp.status === 201) {
                        resolve((await resp.json()));
                    } else {
                        throw (await resp.json());
                    }
                }).catch(error => {
                    globalErrorHandling(error);
                    reject(error);
                    return error;
                });
            });
        },

        upload : (endPoint, formData) => {
            return new Promise(function(resolve, reject) {
                return   axios({
                    method: 'post',
                    url: apiUrl + '/' + endPoint ,
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data' }
                }).then(async resp => {
                    if (resp.status === 200 || resp.status === 201) {
                        resolve((await resp));
                    } else {
                        throw (await resp);
                    }
                }).catch(error => {
                    globalErrorHandling(error);
                    reject(error);
                    return error;
                });
            });

        },

    };

    async function login(email, password) {
        return api.post("login",{
            email, password
        }).then(resp => {
            setUser(resp.data);
            storage.set("user",resp.data);
        });
    }

    function isLogin() {
        return Boolean(user);
    }

    function isManager(){
        return false;
    }

    function logout() {
        setUser(null);
        storage.remove("user");
    }

    function openToast(type, message, show) {
        setToast({
            type: type,
            show: show,
            message: message
        })
    }

    function closeToast() {
      setToast({
          show: false,
          message: "",
          type: ""
      })
    }

    function updateSmsText(value) {
        setSmsText(value);
    }

    function getSmsText() {
        return smsText;
    }

    function globalErrorHandling(error) {
        openToast("danger", error.message,true);
    }

    function getVersion() {
        return "V1.0.73";
    }

    return (
        <ApiContext.Provider value={{api, user, getVersion, isLogin, login, logout, openToast, isManager, getSmsText, updateSmsText}}>
            {props.children}
            <IonToast
                cssClass="custom-toast"
                isOpen={toast.show}
                onDidDismiss={() => {closeToast()}}
                message={toast.message}
                duration={5000}
                position="top"
                color={toast.type}
                keyboardClose={true}
                buttons={[
                    {
                        text: "Kapat",
                        role: 'cancel',
                        handler: () => {
                            closeToast();
                        }
                    }
                ]}
            />
        </ApiContext.Provider>
    );
}
