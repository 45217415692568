import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LaravelApiProvider from "./providers/LaravelApiProvider";
import { unregister } from './serviceWorker';



ReactDOM.render(
    <LaravelApiProvider>
        <App/>
    </LaravelApiProvider>
    ,
    document.getElementById('root')
);

unregister();



