import React, {useState} from "react";
import {IonAlert, IonLoading} from "@ionic/react";
import {useApi} from "../providers/LaravelApiProvider";
import validator from 'validator';
import AlertDialog from "./AlertDialog";


function CustomerEditDialog(props) {

    const {api} = useApi();
    let [loading, setLoading] = useState(false);
    let [alertDialog, setAlertDialog] = useState({show: false, message: null});


    function update(alertData) {

            setLoading(true);

            if(props.type === "identity_number"){
                let params = {
                    identity_number: alertData.identity_number,
                    tax_number: "",
                    tax_office: ""
                }

                let driver = props.reservationData.drivers[0];

                api.post('mobileapp/user/' + props.id ,params).then(resp => {
                    props.onChange(params.identityNumber);
                    setLoading(false);
                }).catch(() => {
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })

            }else if(props.type === "tax_number"){


                if(props.reservationData.tax_office){
                    console.log('tax office var');
                    let params = {
                        tax_number: alertData.tax_number,
                        identity_number: ""
                    }

                    api.post('mobileapp/user/' + props.id ,params).then(resp => {
                        props.onChange(params.tax_number);
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    })
                }else{
                    let params = {
                        tax_number: alertData.tax_number,
                        identity_number: "",
                        tax_office: "Türkiye"
                    }

                    api.post('mobileapp/user/' + props.id ,params).then(resp => {
                        props.onChange(params.tax_number);
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    })
                }

            }else if(props.type === "address"){

                let params = {
                    address: alertData.address,
                }

                api.post('mobileapp/user/' + props.id ,params).then(resp => {
                    props.onChange(params.tax_number);
                    setLoading(false);
                }).catch(() => {
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })

            }else{
                let params = {
                    email: alertData.email
                }

                if(validator.isEmail(params.email)){
                    api.post('mobileapp/user/' + props.id ,params).then(resp => {
                        props.onChange(params.email);
                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    })
                }else{
                    setLoading(false);
                    setAlertDialog({
                        show: true,
                        message: "Geçerli bir email giriniz"
                    });
                    return false;
                }

            return true;
        }
    }


    return (<div>
        <IonLoading
            cssClass='my-custom-class'
            isOpen={loading}
            onDidDismiss={() => {}}
            message={'Lütfen bekleyiniz...'}
        />
        <AlertDialog show={alertDialog.show} message={alertDialog.message} onClose={() => setAlertDialog({show: false, message: null})}/>

        <IonAlert
            isOpen={props.show}
            onDidDismiss={props.onClose}
            cssClass='my-custom-class'
            //header={props.type}
            header={props.type === "identity_number" ? 'Tc No' : props.type === "tax_number" ? 'Vergi No' : props.type === "address" ? 'Adres' : 'Email'}
            inputs={[
                {
                    name: props.type,
                    type: props.type === "identity_number" ? 'number' : props.type === 'address' ? 'textarea' : 'text',
                    placeholder: props.type === "identity_number" ? 'Yeni Tc... ' : props.type === "tax_number" ? "Yeni Vergi No..." : props.type === "address" ? 'Adres' : 'Yeni Email...',
                    value: props.defaultValue
                },
            ]}
            buttons={[
                {
                    text: 'İptal',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {

                    }
                },
                {
                    text: 'Güncelle',
                    handler: (alertData) => {
                        return update(alertData);
                    }
                }
            ]}
        />
    </div>)
}

export default CustomerEditDialog;
