import React, {useEffect, useState} from "react";
import {IonContent, IonInput, IonItem, IonPage} from "@ionic/react";

function TestPage(){

    let [cardNumber, setCardNumber] = useState(null);

    useEffect(() => {
    },[])

    function onchange(e){
        setCardNumber(e.detail.value);
        console.log(e.detail.value, 'value');

        let val = e.detail.value;

        console.log(val.length, 'length');

    }

    return (
        <IonPage>
            <IonContent>
                <IonItem>
                    <IonInput onIonChange={onchange} value={cardNumber} type="number"/>
                </IonItem>
            </IonContent>
        </IonPage>
    )
}

export default TestPage;
