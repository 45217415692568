import React, {useState} from "react";
import {IonIcon, IonLoading} from "@ionic/react";
import {phonePortrait} from "ionicons/icons";

const url = "https://teslimatapi.5scarrental.com/download";
//const url = "http://localhost:8080/download";

function DownloadApkButton(){

   let [loading, setLoading] = useState(false);

   function onClick(){


       // setLoading(true);
       // axios({
       //     url: url,
       //     method: 'GET',
       //     responseType: 'blob', // important
       // }).then((response) => {
       //     const url = window.URL.createObjectURL(new Blob([response.data]));
       //     const link = document.createElement('a');
       //     link.href = url;
       //     link.setAttribute('download', '5sTeslimat.apk');
       //     document.body.appendChild(link);
       //     link.click();
       //     setLoading(false);
       // }).catch((err) => {
       //     alert('hata');
       //     setLoading(false);
       // }).finally(() => {
       //     setLoading(false);
       // });
   }

    return (
        <>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                //onDidDismiss={() => setShowLoading(false)}
                message={'İndiriliyor...'}
                //duration={5000}
            />
            <a className="mr-2 bg-info text-white p-2" href="https://teslimatapi.5scarrental.com/download" target="_blank">
                <strong><IonIcon icon={phonePortrait}/> Android İndir</strong>
            </a>

        </>

    )
}

export default DownloadApkButton;
