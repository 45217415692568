import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import tr from 'react-phone-input-2/lang/tr.json';


function TelInput({name, value, className, placeholder, onChange}){


    function onChangeInput(name, phone){
        onChange(name, phone);
    }

    return (
        <div>
        <PhoneInput
            inputStyle={{width: "auto"}}
            searchPlaceholder={"Ara"}
            localization={tr}
            enableSearch={true}
            value={value}
            placeholder={placeholder}
            onChange={phone => {onChangeInput(name, phone)}}
        />
        {/*<InputMask value={value} alwaysShowMask={false} mask="9999 999 99 99" onChange={(e) => onChangeInput(name, e.target.value)}/>*/}
        </div>
    )
}

export default TelInput;
