import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './styles/index.scss';
import Reservations from "./pages/Reservations";
import {ApiContext} from "./providers/LaravelApiProvider";
import Login from "./pages/Login";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";
import ReservationDetail from "./pages/ReservationDetail";
import NotFound from "./pages/404";
import Sign from "./pages/Sign";
import Expertise from "./components/ExpertiseSvg";
import Camera from "./pages/Camera";
import ReservationManagerPage from "./pages/ReservationManagerPage";
import { Plugins, Capacitor } from "@capacitor/core";
import ConfirmDialog from "./components/ConfirmDialog";
import TestPage from "./pages/TestPage";


export default function App() {
    const {isLogin, isManager} = useContext(ApiContext);
    let [confirmDialog, setConfirmDialog] = useState({open: false, message: null});

    //const root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)

    //root.classList.add("ios");
    //root.classList.remove("md");
    document.body.setAttribute('data-theme', 'light');
    ///document.body.classList.toggle('dark', false);
    useEffect(() => {

        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
                if (window.location.pathname === "/") {
                    // Show A Confirm Box For User to exit app or not
                    setConfirmDialog({
                        open: true,
                        message: "Uygulama kapatılsın mı?"
                    })
                } else if (window.location.pathname === "/reservations") {
                    // Show A Confirm Box For User to exit app or not
                    setConfirmDialog({
                        open: true,
                        message: "Uygulama kapatılsın mı?"
                    })
                }
            });
        }
        //document.body.setAttribute('data-theme', 'dark');
    }, [])

    function onCloseDialog(value){
        console.log(value, 'valee');
        if(value === true){
            Plugins.App.exitApp();
        }else{
            setConfirmDialog({
                open:false,
                message: null
            })
        }
    }

    return (
        <IonApp>
            <ConfirmDialog show={confirmDialog.open} message={confirmDialog.message} onClose={onCloseDialog}/>
            {isManager() ? (
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route path="/reservation-manage" component={ReservationManagerPage} exact={true}/>
                        <Route exact path="/" render={() => <Redirect to="/reservation-manage"/>}/>
                        <Route exact path="*" render={NotFound} />
                    </IonRouterOutlet>
                </IonReactRouter>
            ) : (
                <>
                    {
                        isLogin() ? (
                            <IonReactRouter>
                                <IonRouterOutlet>
                                    <Route path="/test-page" component={TestPage} exact={true}/>
                                    <Route path="/camera" component={Camera} exact={true}/>
                                    <Route path="/expertise" component={Expertise} exact={true}/>
                                    <Route path="/reservations" component={Reservations} exact={true}/>
                                    <Route path="/sign" component={Sign} exact={true}/>
                                    <Route path="/reservations/:id" component={ReservationDetail}/>
                                    <Route exact path="/" render={() => <Redirect to="/reservations"/>}/>
                                    <Route exact path="*" render={NotFound} />
                                </IonRouterOutlet>
                            </IonReactRouter>
                        ) : (
                            <Login/>
                        )
                    }
                </>
            )}
            <ServiceWorkerWrapper/>

        </IonApp>
    )
}
