import validator from 'validator';
import axios from "axios";

let response = [];

export default function validate(form) {
    response = [];

    if(form){
        if(!form.full_name){
            response.push({
                name: "full_name",
                message: "Lütfen bu alanı doldurunuz"
            })
        }

        if(!form.identity_number){
            response.push({
                name: "identity_number",
                message: "Lütfen bu alanı doldurunuz"
            })
        }

        if(!form.phone){
            response.push({
                name: "phone",
                message: "Lütfen bu alanı doldurunuz"
            })
        }

        if(!form.email){
            response.push({
                name: "email",
                message: "Lütfen bu alanı doldurunuz"
            })
        }

        if(form.email){

            if(!validator.isEmail(form.email)){
                response.push({
                    name: "email",
                    message: "Lütfen geçerli bir email adresi giriniz"
                })
            }
        }

    }

    return response;

}
