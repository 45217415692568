import React, { FC, useEffect } from 'react';
import * as serviceWorker from '../serviceWorker';

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
        setTimeout(() => {
            reloadPage();
        }, 100);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload(true);
    };

    return (
        <>
        {/*{*/}
        {/*    showReload && (*/}
        {/*        <IonButton onClick={reloadPage} color="primary">Primary</IonButton>*/}
        {/*    )*/}
        {/*}*/}
        </>
    );
}

export default ServiceWorkerWrapper;
