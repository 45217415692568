import React, {useEffect, useRef, useState} from "react";
import {
    IonButton, IonButtons,
    IonContent, IonFooter,
    IonHeader, IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent, IonItem, IonList,
    IonPage, IonSearchbar,
    IonToolbar
} from "@ionic/react";
import {
    arrowForward,
    logOutOutline,
    personAdd,
    arrowUndoCircleOutline,
    personCircle,
    arrowDownOutline,
} from 'ionicons/icons'
import moment from 'moment';
import 'moment/locale/tr';
import {useApi} from "../providers/LaravelApiProvider";
import ConfirmDialog from "../components/ConfirmDialog";
import FullLoader from "../components/FullLoader";
import DownloadApkButton from "../components/DownloadApkButton";
import RentalNote from "../components/RentalNote";
import {useHistory} from 'react-router-dom';
import {extraIcons} from '../utils/extraIcons'
import ExtraIconFounder from "../components/ExtraIconFounder";

moment.locale("tr");

export default function Reservations() {

    const history = useHistory();
    const {logout, api, user, openToast, getVersion} = useApi();
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    let [onlyDelivery, setOnlyDelivery] = useState(true);
    let [onlyReturn, setOnlyReturn] = useState(false);

    let [confirmDialog, setConfirmDialog] = useState({
        show: false,
        message: ""
    })

    const scroll = useRef();
    const contentRef = useRef(null);


    useEffect(() => {
        appendReservation(1,"",true, false);
    }, []);

    function appendReservation(page, searchText = "", only_delivery, only_return) {
        api.get("mobileapp", {paginate: 15, page: page, search: searchText, only_delivery: only_delivery, only_return: only_return}).then(resp => {
            if (page === 1) {
                setReservations(resp.data);
                setPage(1);
            } else {
                let newReservations = [...reservations, ...resp.data];
                setReservations(newReservations);
                setPage(page);
            }
            setLoading(false);

        }).finally(() => {
            setLoading(false);
            scroll.current.complete();
        });
    };

    const scrollToTop= () => {
        // @ts-ignore
        contentRef.current.scrollToTop();
    };

    const logoutConfirmOpen = () => {
        setConfirmDialog({
            show: true,
            message: "Çıkış yapmak istediğinize eminmisiniz"
        })
    }

    const logoutConfirmClose = (value) => {

        if(value === true){
            setConfirmDialog({
                show: false,
                message: ""
            })
            logout();
        }else{
            setConfirmDialog({
                show: false,
                message: ""
            })
        }
    }

    function onCardClick(e, path){
        history.push(path);
    }

    function onRentalNoteChange(){
        appendReservation(1, search, onlyDelivery, onlyReturn)
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton>
                            <IonIcon icon={personCircle}/>
                            <span className="d-sm-block d-none"> {user.name} ({getVersion()})</span>
                        </IonButton>
                    </IonButtons>
                    <DownloadApkButton/>

                    <IonButtons slot="end" className="clickable">
                        <IonButton color="primary" onClick={logoutConfirmOpen}>
                            <IonIcon slot="icon-only" icon={logOutOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <div className="d-flex justify-content-center align-items-center">
                    <hr className="title-line"/>
                    <span className="px-2 h5">
                        {onlyDelivery ? 'Teslim Edilecekler' : 'Teslim Alınacaklar'}
                    </span>
                    <hr className="title-line"/>
                </div>

                <IonSearchbar placeholder="Rezervasyon Ara" onIonChange={($e => {
                    setSearch($e.target.value);
                    appendReservation(1, $e.target.value, onlyDelivery, onlyReturn);
                })}/>
            </IonHeader>
            <IonContent ref={contentRef}>
                {/*<PayTypeSelector/>*/}
                <ConfirmDialog show={confirmDialog.show} message={confirmDialog.message} onClose={logoutConfirmClose}/>
                <FullLoader show={loading}/>
                <IonList className={"px-2"}>
                    {reservations.length === 0 ? (
                        <>
                            <div className="d-flex justify-content-center">
                                <span className="py-4">Rezervasyon bulunmamaktadır</span>
                            </div>
                        </>
                    ) : (
                        <>
                            {reservations.map((reservation, index) => (
                                <IonItem className="my-3 shadow-sm reservation-card" key={"rs" + index}>
                                    <div className="receive-badge">
                                        <span
                                            className="badge badge-danger">{reservation.receive === "0" && ("UZAMA")}</span>
                                    </div>
                                    <div className="col-12 position-relative"
                                         onClick={(e) => onCardClick(e, "/reservations/" + reservation.id + "?only_delivery=" + onlyDelivery + "&only_return=" + onlyReturn)}>
                                        <div className="row py-2">
                                            <div className="col-md-3 align-self-center text-center">
                                                <img src={reservation.model_image} className="img-thumbnail border-0"/>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="d-flex justify-content-between">
                                                    <div className="">
                                                        <span
                                                            className="plaka h5">{reservation?.vehicle?.license_plate}</span> #{reservation.code}
                                                    </div>

                                                    <RentalNote onChange={onRentalNoteChange}
                                                                reservation={reservation}/>
                                                    <div>
                                                        <div className="phone-card d-none d-lg-block d-md-block">
                                                            <span className="title">Telefon Numaraları</span>
                                                            {reservation.phone && (
                                                                <span className="phone">{reservation.phone}</span>)}
                                                            {reservation.phone_2 && (
                                                                <span className="phone">{reservation.phone_2}</span>)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <h5 className="mt-1">{reservation.name}</h5>
                                                <div className="phone-card-mobile d-md-none d-block">
                                                    <span className="title">Telefon Numaraları:</span>
                                                    {reservation.phone && (
                                                        <span className={"phone"}> {reservation.phone}</span>)}
                                                    {reservation.phone_2 && (
                                                        <span className={"phone"}> {reservation.phone_2}</span>)}
                                                </div>

                                                <div className="row my-2">
                                                    <div className="col-sm-5 col-12">
                                                        <p className="mb-0">{moment(reservation.begin_date).locale("tr").format("DD.MM.YYYY")}
                                                            <strong
                                                                className="text-danger">({moment(reservation.begin_date).locale("tr").format("HH:mm")})</strong>
                                                        </p>
                                                        <strong
                                                            className="text-muted">{reservation.begin_location?.name?.tr}</strong><br/>
                                                        <small
                                                            className="text-muted">{reservation.begin_location?.address}</small>
                                                    </div>
                                                    <div className="col-2 d-none d-sm-flex align-self-center">
                                                        <IonIcon icon={arrowForward}/>
                                                    </div>
                                                    <div className="col-12 d-sm-none d-block">
                                                        <IonIcon icon={arrowDownOutline}/>
                                                    </div>
                                                    <div className="col-sm-5 col-12">
                                                        <p className="mb-0">{moment(reservation.end_date).locale("tr").format("DD.MM.YYYY")}
                                                            <strong
                                                                className="text-danger">({moment(reservation.end_date).locale("tr").format("HH:mm")})</strong>
                                                        </p>
                                                        <strong
                                                            className="text-muted">{reservation.end_location?.name?.tr}</strong><br/>
                                                        <small
                                                            className="text-muted">{reservation.end_location?.address}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div className="flex-fill">
                                                <div className="d-flex justify-content-start flex-wrap">
                                                    <span className="badge badge-warning h6 mr-2 d-flex justify-content-center align-items-center">
                                                        <ExtraIconFounder code={"unlimited_km"}/>
                                                        Sınırsız Km
                                                    </span>
                                                    {reservation.extras.length > 0 && reservation.extras.map((item, idx) => (
                                                        <span key={"extras-list-" + idx}
                                                              className="badge badge-warning h6 mr-2 d-flex justify-content-center align-items-center">
                                                        <ExtraIconFounder code={item.extra?.code}/>
                                                            {item.extra?.name?.tr}
                                                    </span>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="d-flex justify-content-end flex-wrap flex-fill">

                                                    <span
                                                        className="badge badge-info text-white h5 d-flex align-items-center">
                                                    {reservation?.company?.name}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </IonItem>
                            ))}
                        </>
                    )}


                </IonList>

                <IonInfiniteScroll ref={scroll} onIonInfinite={($event) => {
                    appendReservation(page + 1, "", onlyDelivery, onlyReturn);
                }}>
                    <IonInfiniteScrollContent/>
                </IonInfiniteScroll>
            </IonContent>
            <IonFooter className="shadow shadow-sm">
                <div className="d-flex justify-content-start p-3 tab-bar">
                    <IonButton color={onlyDelivery ? "success" : "light"}
                               className={"flex-fill tab-button custom-tab-button"} onClick={() => {
                        setLoading(true);
                        appendReservation(1, "", true, false);
                        setOnlyDelivery(true);
                        setOnlyReturn(false);
                        scrollToTop();

                    }}><IonIcon icon={personAdd}/>
                        <span className="d-none d-md-block">Teslim Edilecekler</span>
                    </IonButton>
                    <IonButton color={onlyReturn ? "success" : "light"}
                               className={"flex-fill tab-button ml-2 custom-tab-button"} onClick={() => {
                        setLoading(true);
                        appendReservation(1, "", false, true);
                        setOnlyReturn(true);
                        setOnlyDelivery(false);
                        scrollToTop();
                    }}><IonIcon icon={arrowUndoCircleOutline}/>
                        <span className="d-none d-md-block">Teslim Alınacaklar</span>
                    </IonButton>
                </div>
            </IonFooter>
        </IonPage>
    );
}
