import React from "react";

const fill = 'none';
const fillRule = 'evenodd';
const clipRule = 'evenodd';
const fillColor = '#FFFFFF';
const fillColorDamage = '#de189b';

const stroke = '#2B2A29';
const strokeDamage = '#ffffff';

const strokeWidth = 0.567;
const strokeMiterLimit = 2.7481;
const fontFamily = 'Arial-BoldMT';
const fontSize = '25px';

function ExpertiseSvg(props) {


    return(
   <div style={{width: props.width + 'px', height: props.height + 'px'}} className="position-relative">
       {props.data?.sag_dikiz_ayna ? (
           <div style={{position: "absolute", top: "23%", left: "36%", backgroundColor: fillColorDamage, width: "27px", height: "12px", borderRadius: "4px", border: "1px solid gray"}}>
               <span className={"text-white"}>{props.data?.sag_dikiz_ayna }</span>
           </div>
       ) : (
           <div style={{position: "absolute", top: "23%", left: "36%", backgroundColor: "white", width: "27px", height: "12px", borderRadius: "4px", border: "1px solid gray"}}>
           </div>
       ) }

       {props.data?.sol_dikiz_ayna ? (
           <div style={{position: "absolute", top: "74%", left: "36%", backgroundColor: fillColorDamage, width: "27px", height: "12px", borderRadius: "4px", border: "1px solid gray"}}>
               <span className={"text-white"}>{props.data?.sol_dikiz_ayna }</span>
           </div>
       ) : (
           <div style={{position: "absolute", top: "74%", left: "36%", backgroundColor: "white", width: "27px", height: "12px", borderRadius: "4px", border: "1px solid gray"}}>
           </div>
       ) }

       <svg viewBox="0 0 1024 673" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
           <style type="text/css">
               {
                   `.st0 { fill:${fill} }
                 .st1{fill-rule:${fillRule};clip-rule:${clipRule};fill:${fillColor};stroke:${stroke};stroke-width:${strokeWidth};stroke-miterlimit:${strokeMiterLimit};}
                 .st1{fill-rule:${fillRule};clip-rule:${clipRule};fill:${fillColor};stroke:${stroke};stroke-width:${strokeWidth};stroke-miterlimit:${strokeMiterLimit};}
                 .st1{fill-rule:${fillRule};clip-rule:${clipRule};fill:${fillColor};stroke:${stroke};stroke-width:${strokeWidth};stroke-miterlimit:${strokeMiterLimit};}
                 .st4{fill-rule:${fillRule};clip-rule:${clipRule};fill:${fillColorDamage};stroke:${strokeDamage};stroke-width:${strokeWidth};stroke-miterlimit:${strokeMiterLimit};}
                 .st2{font-family: ${fontFamily};}.st3{font-size:${fontSize};}.caption{fill: ${strokeDamage};`
               }
           </style>
           <g id="Layer_2_1_">
               <g >
                   <path className={props.data?.sol_on_teker ? "st4" : "st1"} d="M239.92,611.84h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
                    c-3.72,0-6.63-3.72-6.63-8.49v-5.71C233.28,615.69,236.34,611.84,239.92,611.84z"/>
                   <text id="text-sol-on-teker" transform="matrix(1 0 0 1 237.5137 631.4141)" className={props.data?.sol_on_teker ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_on_teker}</text>
               </g>
               <g>
                   <path className={props.data?.sol_on_camurluk ? "st4" : "st1"} d="M298.03,518.57h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
                    c-3.72,0-6.63-3.72-6.63-8.49v-5.71C291.4,522.42,294.45,518.57,298.03,518.57z"/>
                   <text id="text-sol-on-camurluk" transform="matrix(1 0 0 1 295.5474 537.8984)"
                         className={props.data?.sol_on_camurluk ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_on_camurluk}</text>
               </g>
               <g>
                   <path  className={props.data?.sol_marspiye ? "st4" : "st1"} d="M475.3,617.95h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49H475.3
                    c-3.72,0-6.63-3.72-6.63-8.49v-5.71C468.66,621.8,471.71,617.95,475.3,617.95z"/>
                   <text id="text-sol-maspier" transform="matrix(1 0 0 1 472.8276 637.2725)"
                         className={props.data?.sol_marspiye ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_marspiye}</text>
               </g>
               <g>
                   <path className={props.data?.sol_on_kapi ? "st4" : "st1"} d="M425.41,552.54h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C418.77,556.25,421.82,552.54,425.41,552.54z"/>
                   <text id="text-sol-on-kapi" transform="matrix(1 0 0 1 422.9219 571.8164)"
                         className={props.data?.sol_on_kapi ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_on_kapi}</text>
               </g>
               <g>
                   <path className={props.data?.sol_arka_kapi ? "st4" : "st1"} d="M581.97,552.54h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C575.34,556.25,578.39,552.54,581.97,552.54z"/>
                   <text id="text-sol-arka-kapi" transform="matrix(1 0 0 1 579.4854 571.8164)"
                         className={props.data?.sol_arka_kapi ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_arka_kapi}</text>
               </g>
               <g>
                   <path className={props.data?.sol_on_cam ? "st4" : "st1"} d="M464.42,462.71h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C457.78,466.43,460.7,462.71,464.42,462.71z"/>
                   <text id="text-sol-on-cam" transform="matrix(1 0 0 1 461.9097 482.0068)"
                         className={props.data?.sol_on_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_on_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sol_arka_cam ? "st4" : "st1"} d="M581.84,462.71h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C575.2,466.43,578.12,462.71,581.84,462.71z"/>
                   <text id="text-sol-arka-cam" transform="matrix(1 0 0 1 579.3232 482.0068)"
                         className={props.data?.sol_arka_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_arka_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sol_kelebek_cam ? "st4" : "st1"} d="M659.72,462.71h28c2.39,0,4.38,3.72,4.38,8.49v5.71c0,4.64-1.99,8.49-4.38,8.49h-28
        c-2.39,0-4.38-3.72-4.38-8.49v-5.71C655.34,466.43,657.33,462.71,659.72,462.71z"/>
                   <text id="text-sol-kelebek-cam" transform="matrix(1 0 0 1 658.1885 481.9951)"
                         className={props.data?.sol_kelebek_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_kelebek_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sol_arka_teker ? "st4" : "st1"} d="M702.58,611.84h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C695.95,615.69,699,611.84,702.58,611.84z"/>
                   <text id="text-sol-arka-teker" transform="matrix(1 0 0 1 700.1387 631.1953)"
                         className={props.data?.sol_arka_teker ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_arka_teker}</text>
               </g>
               <g>
                   <path className={props.data?.sol_direk ? "st4" : "st1"} d="M721.95,462.71h27.07c2.39,0,4.25,3.72,4.25,8.49v5.71c0,4.64-1.86,8.49-4.25,8.49h-27.07
        c-2.39,0-4.25-3.72-4.25-8.49v-5.71C717.7,466.43,719.56,462.71,721.95,462.71z"/>
                   <text id="text-sol-direk" transform="matrix(1 0 0 1 719.8701 481.9951)"
                         className={props.data?.sol_direk ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_direk}</text>
               </g>
               <g>
                   <path className={props.data?.sol_arka_camurluk ? "st4" : "st1"} d="M719.43,512.47h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C712.8,516.18,715.85,512.47,719.43,512.47z"/>
                   <text id="text-sol-arka-camurluk" transform="matrix(1 0 0 1 716.9863 531.7617)"
                         className={props.data?.sol_arka_camurluk ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.sol_arka_camurluk}</text>
               </g>
               <g>
                   <path className={props.data?.tavan ? "st4" : "st1"} d="M555.3,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49H555.3
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C548.67,322.2,551.59,318.35,555.3,318.35z"/>
                   <text id="text-tavan" transform="matrix(1 0 0 1 552.832 337.9326)"
                         className={props.data?.tavan ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.tavan}</text>
               </g>
               <g>
                   <path className={props.data?.arka_cam ? "st4" : "st1"}  d="M742.38,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C735.75,322.2,738.67,318.35,742.38,318.35z"/>
                   <text id="text-arka-cam" transform="matrix(1 0 0 1 739.8721 337.9326)"
                         className={props.data?.arka_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.arka_cam}</text>
               </g>
               <g>
                   <path className={props.data?.on_cam ? "st4" : "st1"} d="M370.34,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C363.71,322.2,366.63,318.35,370.34,318.35z"/>
                   <text id="text-on-cam" transform="matrix(1 0 0 1 367.8608 337.9326)"
                         className={props.data?.on_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.on_cam}</text>
               </g>
               <g>
                   <path className={props.data?.kaput ? "st4" : "st1"}  d="M193.35,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C186.71,322.2,189.76,318.35,193.35,318.35z"/>
                   <text id="text-kabut" transform="matrix(1 0 0 1 190.9424 337.9326)"
                         className={props.data?.kaput ? ("st2 st3 caption") : ("st2 st3")}>{props.data?.kaput}</text>
               </g>
               <g>
                   <path className={props.data?.on_tampon ? "st4" : "st1"} d="M46.07,318.35H88.4c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49H46.07
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C39.44,322.2,42.49,318.35,46.07,318.35z"/>
                   <text id="text-on-tampon" transform="matrix(1 0 0 1 43.6265 337.9326)"
                         className={props.data?.on_tampon ? ("st2 st3 caption") : ("st2 st3")}>{props.data.on_tampon}</text>
               </g>
               <g>
                   <path className={props.data?.bagaj ? "st4" : "st1"} d="M828.1,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49H828.1
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C821.46,322.2,824.51,318.35,828.1,318.35z"/>
                   <text id="text-bagaj" transform="matrix(1 0 0 1 825.6787 337.9326)"
                         className={props.data?.bagaj ? ("st2 st3 caption") : ("st2 st3")}>{props.data.bagaj}</text>
               </g>
               <g>
                   <path className={props.data?.arka_tampon ? "st4" : "st1"}  d="M948.04,318.35h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C941.41,322.2,944.33,318.35,948.04,318.35z"/>
                   <text id="text-arka-tampon" transform="matrix(1 0 0 1 945.5312 337.9326)"
                         className={props.data?.arka_tampon ? ("st2 st3 caption") : ("st2 st3")}>{props.data.arka_tampon}</text>
               </g>
               <g>
                   <path className={props.data?.sag_on_teker ? "st4" : "st1"} d="M239.92,31.63h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C233.28,35.34,236.34,31.63,239.92,31.63z"/>
                   <text id="text-sag-on-teker" transform="matrix(1 0 0 1 237.5137 51.1182)"
                         className={props.data?.sag_on_teker ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_on_teker}</text>
               </g>
               <g>
                   <path className={props.data?.sag_marspiye ? "st4" : "st1"} d="M475.3,37.73h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49H475.3
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C468.66,41.45,471.71,37.73,475.3,37.73z"/>
                   <text id="text-sag-maspier" transform="matrix(1 0 0 1 472.8276 56.9756)"
                         className={props.data?.sag_marspiye ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_marspiye}</text>
               </g>
               <g>
                   <path className={props.data?.sag_arka_teker ? "st4" : "st1"} d="M702.58,31.63h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C695.95,35.34,699,31.63,702.58,31.63z"/>
                   <text id="text-sag-arka-teker" transform="matrix(1 0 0 1 700.1387 50.8979)"
                         className={props.data?.sag_arka_teker ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_arka_teker}</text>
               </g>
               <g>
                   <path className={props.data?.sag_on_kapi ? "st4" : "st1"} d="M425.41,98.63h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C418.77,102.35,421.82,98.63,425.41,98.63z"/>
                   <text id="text-sag-on-kapi" transform="matrix(1 0 0 1 422.9219 117.9092)"
                         className={props.data?.sag_on_kapi ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_on_kapi}</text>
               </g>
               <g>
                   <path className={props.data?.sag_arka_kapi ? "st4" : "st1"} d="M581.97,98.63h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C575.34,102.35,578.39,98.63,581.97,98.63z"/>
                   <text id="text-sag-arka-kapi" transform="matrix(1 0 0 1 579.4854 117.9092)"
                         className={props.data?.sag_arka_kapi ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_arka_kapi}</text>
               </g>
               <g>
                   <path className={props.data?.sag_on_camurluk ? "st4" : "st1"} d="M298.03,118h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C291.4,121.85,294.45,118,298.03,118z"/>
                   <text id="text-sag-on-camurluk" transform="matrix(1 0 0 1 295.5474 137.3643)"
                         className={props.data?.sag_on_camurluk ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_on_camurluk}</text>
               </g>
               <g>
                   <path className={props.data?.sag_arka_camurluk ? "st4" : "st1"} d="M719.43,119.99h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C712.8,123.84,715.85,119.99,719.43,119.99z"/>
                   <text id="text-sag-arka-camurluk" transform="matrix(1 0 0 1 716.9863 139.3535)"
                         className={props.data?.sag_arka_camurluk ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_arka_camurluk}</text>
               </g>
               <g>
                   <path className={props.data?.sag_on_cam ? "st4" : "st1"} d="M464.42,168.82h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C457.78,172.54,460.7,168.82,464.42,168.82z"/>
                   <text id="text-sag-on-cam" transform="matrix(1 0 0 1 461.9097 188.1182)"
                         className={props.data?.sag_on_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_on_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sag_arka_cam ? "st4" : "st1"} d="M581.84,168.82h42.33c3.72,0,6.63,3.72,6.63,8.49v5.71c0,4.64-2.92,8.49-6.63,8.49h-42.33
        c-3.72,0-6.63-3.72-6.63-8.49v-5.71C575.2,172.54,578.12,168.82,581.84,168.82z"/>
                   <text id="text-sag-arka-cam" transform="matrix(1 0 0 1 579.3232 188.1182)"
                         className={props.data?.sag_arka_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_arka_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sag_kelebek_cam ? "st4" : "st1"} d="M659.72,168.82h28c2.39,0,4.38,3.72,4.38,8.49v5.71c0,4.64-1.99,8.49-4.38,8.49h-28
        c-2.39,0-4.38-3.72-4.38-8.49v-5.71C655.34,172.54,657.33,168.82,659.72,168.82z"/>
                   <text id="text-sag-kelebek-cam" transform="matrix(1 0 0 1 658.1885 188.1069)"
                         className={props.data?.sag_kelebek_cam ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_kelebek_cam}</text>
               </g>
               <g>
                   <path className={props.data?.sag_direk ? "st4" : "st1"} d="M721.95,168.82h27.07c2.39,0,4.25,3.72,4.25,8.49v5.71c0,4.64-1.86,8.49-4.25,8.49h-27.07
        c-2.39,0-4.25-3.72-4.25-8.49v-5.71C717.7,172.54,719.56,168.82,721.95,168.82z"/>
                   <text id="text-sag-direk" transform="matrix(1 0 0 1 719.8701 188.1069)"
                         className={props.data?.sag_direk ? ("st2 st3 caption") : ("st2 st3")}>{props.data.sag_direk}</text>
               </g>
           </g>
       </svg>
   </div>

    )
}

export default ExpertiseSvg
