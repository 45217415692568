import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonButtons,
    IonItem,
    IonModal,
    IonTextarea
} from "@ionic/react";

function DeliveryPersonDescription({open, onChange, onClose, value}) {

    let [description, setDescription] = useState(null);

    useEffect(() => {
        setDescription(value);
    },[value])

    function onInputChange(e) {
        setDescription(e.detail.value);
    }

    function submit() {
        onChange(description);
        onClose();
    }

    return (
        <IonModal isOpen={open} onDidDismiss={() => {}}>

            <IonItem className="py-2">
                <h4>Teslimatçı Açıklaması</h4>
            </IonItem>
            <IonItem className="m-4 border border-secondary">
                <IonTextarea placeholder="Açıklama giriniz ... " rows={6} value={description} onIonChange={onInputChange}/>
            </IonItem>

            <IonButtons className="p-2 d-flex justify-content-end">
                <IonButton color="medium" onClick={() => {onClose()}}>Kapat</IonButton>
                <IonButton color="primary" onClick={() => {submit()}}>Kaydet</IonButton>
            </IonButtons>

        </IonModal>
    )
}

export default DeliveryPersonDescription
