import React from "react";
import { IonAlert } from '@ionic/react';

function AlertDialog(props) {

    function onClose() {
          props.onClose();
    }

    return (<div>
            <IonAlert
                isOpen={props.show}
                onDidDismiss={() => onClose()}
                cssClass='my-custom-class'
                header={'Uyarı'}
                //subHeader={'Subtitle'}
                message={props.message}
                buttons={['Tamam']}
            />
    </div>)
}

export default AlertDialog;
