import {IonDatetime, IonItem, IonLabel} from "@ionic/react";
import React, {useEffect, useState} from "react";

function DateInput({className, name, label, onChange, value, errors}) {

    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if(errors !== null){
            let foundError = Boolean(errors.find(x => x.name === name));
            if(foundError){
                let errorData = errors.find(x => x.name === name);
                setErrorMessage(errorData.message);
            }
        }
    },[errors])

    function onChangeInput(key, value) {
        onChange(key, value);
        setErrorMessage(null);
    }

    return (
        <IonItem>
            <IonLabel color="medium">{label}: </IonLabel>
            {errorMessage !== null && (
                <small className="text-danger"> {errorMessage} </small>
            )}
            <IonDatetime style={{textAlign: "left"}} cancelText={"İptal"} name={name} doneText="Seç" displayFormat="DD/MM/YYYY"  value={value} onIonChange={e => {onChangeInput(e.target.name, e.target.value)}}/>
        </IonItem>
    )
}

export default DateInput;
