const formatCurrency = require('format-currency')

export default function money(price){
    let newPrice = Number(price);
    if(newPrice){
        return newPrice.toFixed(2);

    }else{
        return (0).toFixed(2);
    }
}

export function moneyKalan(price){
    let newPrice = Number(price);
    if(newPrice){
        return newPrice.toFixed(1);

    }else{
        return (0).toFixed(1);
    }
}

export function fixedMoney(price){
    let newPrice = Number(price);
    if(newPrice){
        return newPrice.toFixed(5);

    }else{
        return (0).toFixed(5);
    }
}

export function convertCeil(price) {
    let newPrice = Number(price);
    if(newPrice){
        return Math.ceil(newPrice)

    }else{
        return Math.ceil(0)
    }
}

export function currencyFormat(price) {
    if(price){
        return formatCurrency(price);
    }else{
        return formatCurrency(0);
    }
}
