import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {IonAlert, IonButton, IonButtons, IonIcon, IonLoading, IonModal} from "@ionic/react";
import {createOutline} from "ionicons/icons";
import SignatureCanvas from "./SignatureCanvas";
import base64toBlob from "../lib/base64toBlob";
import {useApi} from "../providers/LaravelApiProvider";

function FormSignArea({
  value,
  drivers,
  onChange,
  onlyDelivery,
  onlyPrice = false,
  onlyReturn,
}) {
  const canvas = useRef(null);
  const { api } = useApi();

  let [driverSelect, setDriverSelect] = useState(false);
  let [signModal, setSignModal] = useState(false);
  let [driverData, setDriverData] = useState({
    imageUrl: null,
    imageName: null,
    driverId: drivers[0]?.id,
  });
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      value.imageUrl === null &&
      value.imageName === null &&
      value.driverId === null
    ) {
      setDriverData({
        imageUrl: null,
        imageName: null,
        driverId: drivers[0].id,
      });
    } else {
      setDriverData({
        imageUrl: value.imageUrl,
        imageName: value.imageName,
        driverId: value.driverId,
      });
    }
  }, [value]);

  function openDriverSelectDialog() {
    setDriverSelect(true);
  }

  function closeDriverSelectDialog() {
    setDriverSelect(false);
  }

  function onChangeActiveDriver(value) {
    setDriverData({ imageUrl: null, imageName: null, driverId: value.id });
  }

  function onChangeSign(imageData) {
    onChange(imageData);
  }

  function openSignModal() {
    setSignModal(true);
  }

  function closeSignModal() {
    setSignModal(false);
  }

  return (
    <>
      <div className="flexTable border-0 my-1 position-relative">
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => {}}
          message={"Lütfen bekleyiniz..."}
        />
        {onlyDelivery === false && onlyReturn === true ? (
          <div
            onClick={() => {
              openDriverSelectDialog();
            }}
            style={{
              right: 2,
              top: 2,
              position: "absolute",
              fontSize: "14px",
              zIndex: 99,
            }}
          >
            <IonIcon icon={createOutline} />
          </div>
        ) : (
          <></>
        )}

        <div className="flexCell col-4 border-0">
          <strong>Sürücü: </strong>
          {drivers.find((x) => x.id === driverData.driverId)?.name}
        </div>
        <div className="flexCell col-4 border-0">
          <strong>Tarih Saat:</strong> {moment().format("DD/MM/YY")}{" "}
          <strong>{moment().format("HH:mm")}</strong>
        </div>
        <div className="flexCell col-4 border-0 position-relative">
          <strong>İmza:</strong>
          {driverData.imageUrl === null && driverData.imageName === null ? (
            <span
              className="signArea"
              onClick={() => {
                openSignModal();
              }}
            />
          ) : (
            <img
              onClick={() => {
                openSignModal();
              }}
              src={driverData.imageUrl}
              width={102}
              height={25}
              alt="Orijinal İmza"
            />
          )}
        </div>
      </div>
      <IonModal
        isOpen={signModal}
        onDidDismiss={() => {
          closeSignModal();
        }}
      >
        <div className="p-3">
          <h3>İmza (Sürücü)</h3>

          <small>İmzayı aşağıdaki kutucuğun içine atınız</small>
          <br />
        </div>
        <div className="p-3">
          <span style={{ color: "red" }}>
            Ödemeniz Gereken Toplam Tutar / Total Price :{" "}
            <strong>{onlyPrice}</strong>{" "}
          </span>
          <div className="border border-secondary ">
            <SignatureCanvas
              ref={canvas}
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
          </div>
        </div>
        <IonButtons className="p-2 d-flex justify-content-end">
          <IonButton color="medium" onClick={() => closeSignModal()}>
            İptal
          </IonButton>
          <IonButton
            color="primary"
            onClick={($e) => {
              setLoading(true);
              $e.preventDefault();
              let c = canvas.current.getDotAndSign();
              let blobData = base64toBlob(c.original_image);
              let data = new FormData();
              data.append("file", blobData, "newImage.png");

              api
                .upload("upload", data)
                .then((resp) => {
                  let imageData = {
                    imageUrl: c.original_image,
                    imageName: resp.data.name,
                    driverId: driverData.driverId,
                  };

                  setDriverData(imageData);
                  onChangeSign(imageData);
                  setSignModal(false);
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            Kaydet
          </IonButton>
        </IonButtons>
      </IonModal>

      <SelectDriver
        open={driverSelect}
        drivers={drivers}
        active={driverData.driverId}
        onChange={onChangeActiveDriver}
        onClose={closeDriverSelectDialog}
      />
    </>
  );
}

function SelectDriver({open, drivers, active, onChange,  onClose}) {


    let inputs = drivers?.map((item, idx) => {
        return{
            name: "driver" + item.id,
            type: 'radio',
            label: item.name,
            value: item.id,
            checked: Boolean(active === item.id)
        }
    })

    function changeDriver(alertData){
        let foundSelected = drivers.find(x => x.id === alertData);
        onChange(foundSelected);

    }

    function onCloseAlert(){
        onClose()
    }

    return (
        <IonAlert
            isOpen={open}
            onDidDismiss={() => onCloseAlert()}
            cssClass='my-custom-class'
            header={'Sürücü Değiştir'}
            inputs={inputs}
            buttons={[
                {
                    text: 'İptal',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                },
                {
                    text: 'Değiştir',
                    handler: (alertData) => {
                        changeDriver(alertData);
                    }
                }
            ]}
        />
    )
}

export default FormSignArea;
