import React from "react";
import {IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {useCamera, availableFeatures} from "@ionic/react-hooks/camera";
import {CameraResultType} from "@capacitor/core";

const Camera = () => {

    const { photo, getPhoto} = useCamera();

    const handleTakePhoto = () => {
        if(availableFeatures.getPhoto) {
            getPhoto({
                quality: 50,
                allowEditing: false,
                resultType: CameraResultType.DataUrl
            })
        }
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar>
                    <IonTitle>Kamera Test</IonTitle>
                </IonToolbar>
            </IonHeader>
          <IonContent>
              <div className="d-flex justify-content-center mt-3">
                  {availableFeatures.getPhoto ? (
                      <div>
                          <div><IonButton onClick={handleTakePhoto}>Take Photo</IonButton></div>
                          <div>{photo && <img alt="" src={photo.dataUrl} />}</div>
                      </div>
                  ) : <div>Camera not available on this platform</div>}
              </div>
          </IonContent>
        </IonPage>
    )
}

export default Camera