import { useState, useEffect } from "react";
import { useCamera, availableFeatures} from '@ionic/react-hooks/camera';
import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { useStorage } from '@ionic/react-hooks/storage';
import { isPlatform } from '@ionic/react';
import { CameraResultType, CameraSource, CameraPhoto, Capacitor, FilesystemDirectory } from "@capacitor/core";
import {useApi} from "../providers/LaravelApiProvider";

export function usePhotoGallery() {

    const { getPhoto } = useCamera();
    const [photo, setPhoto] = useState(null);
    const {openToast} = useApi();


    const takePhoto = async () => {
            const cameraPhoto = await getPhoto({
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera,
                quality: 75,
            });

            let splittedArray = cameraPhoto.dataUrl.split(";");
            let dataInfo = splittedArray[0];

            if(dataInfo === "data:image/png" || dataInfo === "data:image/jpeg" || dataInfo === "data:image/webp"){
                const fileName = new Date().getTime() + '.' + cameraPhoto.format;
                const newPhoto = {
                    filepath: fileName,
                    dataUrl: cameraPhoto.dataUrl
                };
                setPhoto(newPhoto)
            }else{
                setPhoto(null);
                openToast("danger", "Dosya Formatı Geçersiz!", true);
            }

    };

    return {
        takePhoto,
        photo
    };
}
