import React, {useEffect, useRef, useState} from "react";

import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonList,
    IonItemDivider,
    IonInput,
    IonHeader,
    IonIcon, IonModal,
    IonPage,  IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar,
    IonRadioGroup,
    IonRadio,
    IonAlert, IonItem, IonLabel, IonFooter,
    IonLoading,   IonFab, IonFabButton
} from "@ionic/react";
import base64toBlob from "../lib/base64toBlob";
import {
  closeCircleOutline,
  chevronForwardOutline,
  alertCircleOutline,
  chevronBackOutline,
  createOutline,
  arrowBackOutline,
  refreshOutline,
  refreshCircleOutline,
  refreshCircle,
  addCircle,
} from "ionicons/icons";
import {useApi} from "../providers/LaravelApiProvider";
import Logo from "../components/Logo";
import moment from "moment";
import SignatureCanvas from "../components/SignatureCanvas";
import domtoimage from 'dom-to-image';
import ExpertiseSvg from "../components/ExpertiseSvg";
import AlertDialog from "../components/AlertDialog";
import DamageConfirmDialog from "../components/DamageConfirmAlert";
import NoDamageLabel from "../components/NoDamageLabel";
import VerifyDialog from "../components/VerifyDialog";
import FullLoader from "../components/FullLoader";
import ImageUploadPage from "../components/ImageUploadPage";
import LastStepDialog from "../components/LastStepDialog";
import FormSignArea from "../components/FormSignArea";
import DeliveryPersonDescription from "../components/DeliveryPersonDescription";
import ExtrasDialog from "../components/ExtrasDialog";
import CustomerEditDialog from "../components/CustomerEditDialog";
import DriverEditDialog from "../components/DriverEditDialog";
import money, {currencyFormat} from "../utils/money";
import truncate from "truncate";
import DriverAddEditDialog from "../components/DriverAddEditDialog";

import VehicleImage from "../components/VehicleImage";
import DriverChangeDialog from "../components/DriverChangeDialog";
import { func } from "prop-types";
const fuelData = [1,2,3,4,5,6,7,8,9,10,11,12];
const checkboxList = [
    { val: 'Türk Vatandaşı Türk Ehliyeti Olan', isChecked: true },
    { val: 'Türk Vatandaşı Yabancı Ehliyetli', isChecked: false },
    { val: 'Yabancı Uyruklu Yabancı Ehliyetli', isChecked: false }
  ];
export default function ReservationDetail({match}) {

    let [data, setData] = useState([]);

    const {api,user, openToast, getSmsText, updateSmsText} = useApi();
    const [reservation, setReservation] = useState();
    const [damages,setDamages] = useState();
    const canvas = useRef(null);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    let [kmDialog, setKmDialog] = useState({open: false, type:null});
    let [signEmployeeModal, setSignEmployeeModal] = useState(false);
    let [damageSelectModal, setDamageSelectModal] = useState(false);
    let [onlyReturn, setOnlyReturn] = useState(null);
    let [onlyDelivery, setOnlyDelivery] = useState(null);
    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState('Türk Vatandaşı Türk Ehliyeti Olan');
    let [driverArea, setDriverArea] = useState({imageUrl: null, imageName: null, driverId: null })

    let [signEmployeeImg, setSignEmployeeImg] = useState(null);
    let [formImg,setFormImg] = useState(null);
    const [printing, setPrinting] = useState(false);

    let [form,setForm] = useState({});
    let [selectedDamages , setSelectedDamages] = useState({});
    let [oldSelectedDamages, setOldSelectedDamages] = useState([]);

    let [alertData, setAlertData] = useState({
        message: "",
        show: false,
    });

    let [damageAlertData, setDamageAlertData] = useState({
        message: "",
        show: false,
    });

    let [passDamage, setPassDamage] = useState(false);
    let [customerEditDialog, setCustomerEditDialog] = useState({open: false, type: null, defaultValue: null});

    let [driverEditDialog, setDriverEditDialog] = useState({
        show: false,
        id: null,
        idx: null,
        driverValue: {name: null, label: null, type: null},
        defaultValue: null
    });
    let [loading, setLoading] = useState(false);
    let [ionLoading, setIonLoading] = useState(false);
    let [verifyDialog, setVerifyDialog] = useState(false);
    let [isVerify, setIsVerify] = useState(false);

    let [step, setStep] = useState("form");
    let [photos, setPhotos] = useState([]);

    let [extraDialog, setExtraDialog] = useState(false);

    let [deliveryDescriptionDialog, setDeliveryDescriptionDialog] = useState(false);
    let [deliveryDescription, setDeliveryDescription] = useState(null);

    let [payStatus, setPayStatus] = useState(false);
    let [exchangeRate, setExchangeRate] = useState(1);
    let [driverAddEdit, setDriverAddEdit] = useState({
      open: false,
      extra_id: null,
    });
    let [driverData, setDriverData] = useState(null);

    let [activeCard, setActiveCard] = useState(null);
    let [damageListQuery, setDamageListQuery] = useState(null);
    let [atfError, setAtfError] = useState(false);
    const [changeDialog, setChangeDialog] = useState(false);
    let KabisType;
    useEffect(() => {
        load();
    }, []);

    function load() {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let deliveryText = url.searchParams.get("only_delivery");
        let returnText = url.searchParams.get("only_return");

        let deliveryData = (deliveryText === 'true');
        let returnData = (returnText === 'true');

        setLoading(true);
        Promise.all([
            api.get("mobileapp/" + match.params.id, {only_delivery: deliveryData, only_return: returnData}),
            api.get("damage_zone/",{paginate: 9999}),
        ]).then(resp => {
            if(resp[0].data.length > 0){
                let respReservation = resp[0].data[0];
                let exchange_rate = respReservation.exchange_rate;
                setExchangeRate(exchange_rate);


                if(respReservation){
                    let isVerify = Boolean(respReservation.drivers[0].verification_time === null);
                    if(isVerify === true) {
                        setIsVerify(false);
                    }else if(isVerify === false){
                        setIsVerify(true);
                    }
                    if(respReservation.reservation_activities.length > 0){

                        let activities = respReservation.reservation_activities[0];
                        let damageList = JSON.parse(activities.damage_list);
                        let rentalNote = activities.rental_note;
                        setDeliveryDescription(rentalNote);

                        let newSelectedDamages = {}
                        damageList.map(item => {
                            newSelectedDamages = {
                                ...newSelectedDamages,
                                [item.code] : item.value
                            }
                        });

                        setSelectedDamages(newSelectedDamages);
                        setOldSelectedDamages(damageList);


                        let newForm = {
                            ...form,
                            outKm: activities.kilometer,
                            outFuel: activities.fuel
                        }

                        setForm(newForm);

                    }
                }

                setReservation(respReservation);
                setDamages(resp[1]);
                //setCheckData(resp[2]);

                setOnlyReturn(returnData);
                setOnlyDelivery(deliveryData);
                setData(resp[0].data);

                setLoading(false);
            }

        }).finally(() => {
            setLoading(false);
        });
    }
   function onDriverAddEditChange(extra_id, selectedDriver) {
    console.log(selectedDriver);
      addDriver(selectedDriver);
      reload();
     setDriverData(selectedDriver);
     
   }
     function addDriver(driver) {
       if (driver) {
         setLoading(true);
         api
           .post("mobileapp/driver/add/" + reservation.id, driver)
           .then((resp) => {
             setLoading(false);
           })
           .catch((err) => {
             setLoading(false);
           })
           .finally(() => {
             setLoading(false);
           });
       } else {
         return false;
       }
     }
    function reload(){
        let url_string = window.location.href;
        let url = new URL(url_string);
        let deliveryText = url.searchParams.get("only_delivery");
        let returnText = url.searchParams.get("only_return");

        let deliveryData = (deliveryText === 'true');
        let returnData = (returnText === 'true');


        setIonLoading(true);
        Promise.all([
            api.get("mobileapp/" + match.params.id, {only_delivery: deliveryData, only_return: returnData}),
            api.get("damage_zone/",{paginate: 9999}),
        ]).then(resp => {
            let respReservation = resp[0].data[0];
            let exchange_rate = respReservation.exchange_rate;
            setExchangeRate(exchange_rate);

            if(respReservation){
                let isVerify = Boolean(respReservation.drivers[0].verification_time === null);
                if(isVerify === true) {
                    setIsVerify(false);
                }else if(isVerify === false){
                    setIsVerify(true);
                }
                if(respReservation.reservation_activities.length > 0){

                    let activities = respReservation.reservation_activities[0];
                    let damageList = JSON.parse(activities.damage_list);
                    let rentalNote = activities.rental_note;
                    setDeliveryDescription(rentalNote);

                    let newSelectedDamages = {}
                    damageList.map(item => {
                        newSelectedDamages = {
                            ...newSelectedDamages,
                            [item.code] : item.value
                        }
                    });

                    setSelectedDamages(newSelectedDamages);
                    setOldSelectedDamages(damageList);


                    let newForm = {
                        ...form,
                        outKm: activities.kilometer,
                        outFuel: activities.fuel
                    }

                    setForm(newForm);

                }
            }

            setReservation(respReservation);
            setDamages(resp[1]);
            //setCheckData(resp[2]);

            setOnlyReturn(returnData);
            setOnlyDelivery(deliveryData);
            setData(resp[0].data);

            setIonLoading(false);

        }).finally(() => {
            setIonLoading(false);
        });
    }

    function openKmDialog(type,open) {
        setKmDialog({
            open: open,
            type: type
        });
    }

    function onChangeDriverSign(imageData) {
        setDriverArea(imageData);
    }

    function openSignEmployeeModal(open) {
        setSignEmployeeModal(open);
    }

    function openDamageSelectModal(open) {
       setDamageSelectModal(open)
    }

    function handleChange(key,value) {
        console.log(key,value);
        let newForm = {
            ...form,
            [key]: value
        };

        setForm(newForm);
    }
    function extraCheckDriver(extra){

    }

    function damageChange(key, value) {
        if(value){
            let newForm = {
                ...selectedDamages,
                [key]: value
            };
            setSelectedDamages(newForm);

        }

    }
    function checkDriver(){
      console.log(reservation.drivers.length === 1);
          if (
            reservation.extras.filter((x) => x.extra_id === 11).length === 1 &&
            reservation.drivers.length === 1
          ) {
            return true;
          }else {
                        return false;
          }
    }
    function submit() {
        if(step === "form"){
            let notHavePhone = Boolean(reservation.drivers.find(x => x.phone === null));
            //let notHaveDriverIdentity = Boolean(reservation.drivers.find(x => x.user.identity_number === "" || x.user.identity_number === null));
            //let notHaveDriverAddress = Boolean(reservation.drivers.find(x => x.address === "" || x.address === null));

            if(!reservation.identity_number && !reservation.tax_number && onlyDelivery === true){
                setAlertData({show: true, message: "Müşteri TC No veya Vergi No alanlarından birini doldurunuz"});
                return false;
            }

            if(!reservation.address && onlyDelivery === true){
                setAlertData({show: true, message: 'Müşteri "Fatura Adresi" bölümünü doldurunuz'})
                return false;
            }

            // if(notHaveDriverIdentity){
            //     setAlertData({show: true, message: "Sürücü TC No alanlarını doldurunuz"})
            //     return false;
            // }

            if(notHavePhone){
                setAlertData({show: true, message: "Sürücü telefon alanlarını doldurunuz"})
                return false;
            }
          
            // if(notHaveDriverAddress){
            //     setAlertData({show: true, message: "Sürücü adres alanlarını doldurunuz"})
            //     return false;
            // }

            if(onlyDelivery === true && onlyReturn === false){
                if(!form.outKm){
                    setAlertData({show: true, message: "Teslimat bölümündeki Km alanını doldurunuz!"})
                    return false;
                }

                if(form.outKm.length > 6){
                    setAlertData({show: true, message: "Teslimat KM değeri 6 haneden büyük olamaz"})

                    return false;
                }

                if(!form.outFuel){
                    setAlertData({show: true, message: "Teslimat bölümündeki Yakıt alanını doldurunuz!"})

                    return false;
                }
            }else if(onlyDelivery === false && onlyReturn === true){
                if(!form.returnKm){
                    setAlertData({show: true, message: "İade bölümündeki Km alanını doldurunuz!"})

                    return false;
                }else{
                    if(form.returnKm < form.outKm){
                        setAlertData({show: true, message: "İade Km değeri Teslimat Km değerinden küçük olamaz"})

                        return false;
                    }
                }

                if(form.returnKm.length > 6){
                    setAlertData({show: true, message: "İade KM değeri 6 haneden büyük olamaz"})

                    return false;
                }
                if(form.inHgs === undefined){
                    setAlertData({show: true, message: "Lütfen HGS Kontrolü Yapınız. K.B - E.T "})
    
                    return false;
                }
                if(!form.returnFuel){
                    setAlertData({show: true, message: "İade bölümündeki Yakıt alanını doldurunuz!"})

                    return false;
                }


            }

            if(form.inCleaning === undefined){
                setAlertData({show: true, message: "Araç Temizlik Durumunu belirtiniz"})

                return false;
            }    
          


            if(Object.keys(selectedDamages).length <= 0 && passDamage === false){

                setDamageAlertData({show: true, message: 'Araç için herhangi bir hasar durumu girilmedi.'})
                return false;
            }


            if(driverArea.imageName === null && driverArea.imageUrl === null){
                setAlertData({show: true, message: "Lütfen sürücü imza alanını doldurunuz"})
                return false;
            }

            if(!signEmployeeImg){
                setAlertData({show: true, message: "Teslimat Görevlisi imza alanını doldurunuz"})
                return false;
            }

            setStep("pay");
        }else if(step === "image"){
             controlUser().then(resp => {
                 setStep(resp.step);
             })

            setStep("kabis");

        }else if(step === "pay"){
            if(payStatus === true){
                setStep("image");
            }else{
                setAlertData({show: true, message: "Tahsilat tamamlanmadan teslimat bitirilemez"})
                return false;
            }
        }
    }
    function tcno_dogrula(tcno)
{
    // geleni her zaman String'e çevirelim!
    tcno = String(tcno);

    // tcno '0' karakteri ile başlayamaz!
    if (tcno.substring(0, 1) === '0') {
        return false;
    }
    // Tcno 11 karakter uzunluğunda olmalı!
    if (tcno.length !== 11) {
        return false;
    }

    /**
        Aşağıdaki iki kontrol için toplamları hazır ediyoruz
        - o anki karakteri sayıya dönüştür
        - tek haneleri ayrıca topla (1,3,5,7,9)
        - çift haneleri ayrıca topla (2,4,6,8)
        - bütün haneleri ayrıca topla
    **/
   var hane_tek,hane_cift,j;
    var ilkon_array = tcno.substr(0, 10).split('');
    var ilkon_total = hane_tek = hane_cift = 0;

    for (var i = j = 0; i < 9; ++i) {
      j = parseInt(ilkon_array[i], 10);
      if (i & 1) { // tek ise, tcnin çift haneleri toplanmalı!
          hane_cift  += j;
      } else {
          hane_tek += j;
      }
      ilkon_total += j;
    }

    /**
        KONTROL 1:
        1. 3. 5. 7. ve 9. hanelerin toplamının 7 katından, 
        2. 4. 6. ve 8. hanelerin toplamı çıkartıldığında, 
        elde edilen sonucun Mod10'u bize 10. haneyi verir
    **/
    if ( (hane_tek * 7 - hane_cift) % 10 !== parseInt(tcno.substr(-2, 1), 10)) {
        return false;
    }

    /**
        KONTROL 2:
        1. 2. 3. 4. 5. 6. 7. 8. 9. ve 10. hanelerin toplamından
        elde edilen sonucun Mod10'u bize 11. haneyi vermelidir.
        NOT: ilk 9 haneyi üstteki FOR döndüsünde zaten topladık!
    **/
    ilkon_total += parseInt(ilkon_array[9], 10); 
    if (ilkon_total % 10 !== parseInt(tcno.substr(-1), 10)) {
        return false;
    }

    return true;
}
    function kabis(variable) {
        
       
        let km =   form.outKm;
        let tckn =   reservation.drivers[0]?.user?.identity_number;
        form["KabisType"]="Türk Vatandaşı Yabancı Ehliyetli";
       if(variable == "Türk Vatandaşı Yabancı Ehliyetli"){
        form["KabisType"]="Türk Vatandaşı Yabancı Ehliyetli";
        if(!tcno_dogrula(tckn)){
       
       
            KabisType = "Türk Vatandaşı Yabancı Ehliyetli";
          
            setAlertData({show: true, message: "Tc Kimlik Bilgisi Yanlıştır Lütfen Kontrol Ediniz."})
        }else {
            takeFormPhoto();
        }
 
    }    if(variable == "Türk Vatandaşı Türk Ehliyeti Olan"){
        form["KabisType"]="Türk Vatandaşı Türk Ehliyeti Olan";
        if(!tcno_dogrula(tckn)){
         
            KabisType = "Türk Vatandaşı Türk Ehliyeti Olan";
           
            setAlertData({show: true, message: "Tc Kimlik Bilgisi Yanlıştır Lütfen Kontrol Ediniz."})
        }else {
            takeFormPhoto();
        }
        console.log(form);
     } 
      if(variable == "Yabancı Uyruklu Yabancı Ehliyetli"){
        form["KabisType"]="Yabancı Uyruklu Yabancı Ehliyetli";
        KabisType = "Yabancı Uyruklu Yabancı Ehliyetli";
      
      
        takeFormPhoto();
     }
     console.log(form,"saasasas");
    }
    function controlUser(){
        return new Promise((resolve, reject) => {
            let foundTcPhotos = photos.filter(x => x.type === "tc");
            let foundPassportPhotos = photos.filter(x => x.type === "passport");

            if(activeCard === null && onlyDelivery === true){
                setAlertData({show: true, message: "Kimlik veya Pasaport fotoğraf alanlarından birini seçin!"})
                resolve({status: false, step: "image"});
            }else if(activeCard === "tcCard" ){
                if((foundTcPhotos.length < 1 || foundTcPhotos.length < 1) && onlyDelivery === true){
                    setAlertData({show: true, message: "Lütfen kimlik fotoğrafı yükleyiniz"})
                    resolve({status: false, step: "image"});
                }else{
                    if(imageControl() === false){
                            resolve({status: false, step: "image"});
                        }else{
                            resolve({status: true, step: "kabis"});
                        }
                 //   resolve({status: true, step: "kabis"});
                    // if(reservation.identity_number === null){

                    //     // api.get('user/identity/' + reservation.tax_number).then(resp => {
                    //     //
                    //     //     if(resp.Type === "person"){
                    //     //         if(imageControl() === false){
                    //     //             resolve({status: false, step: "image"});
                    //     //         }else{
                    //     //             resolve({status: true, step: "pay"});
                    //     //         }
                    //     //
                    //     //     }else if(resp.Type === "Company"){
                    //     //         if(imageControl() === false){
                    //     //             resolve({status: false, step: "image"});
                    //     //         }else{
                    //     //             resolve({status: true, step: "pay"});
                    //     //         }
                    //     //     }else if(resp.Type === "company"){
                    //     //         if(imageControl() === false){
                    //     //             resolve({status: false, step: "image"});
                    //     //         }else{
                    //     //             resolve({status: true, step: "pay"});
                    //     //         }
                    //     //     }else{
                    //     //         setAlertData({show: true, message: "Lütfen geçerli bir Vergi No giriniz!"});
                    //     //         resolve({status: false, step: "form"});
                    //     //     }
                    //     // })
                    //     if(imageControl() === true){
                    //         resolve({status: true, step: "pay"});
                    //     }

                    // }else if(reservation.tax_number === null){
                    //     api.get('user/identity/' + reservation.identity_number).then(resp => {

                    //         if(resp.Type === "person"){
                    //             if(imageControl() === false){
                    //                 resolve({status: false, step: "image"});
                    //             }else{
                    //                 resolve({status: true, step: "pay"});
                    //             }

                    //         }else if(resp.Type === "Company"){
                    //             if(imageControl() === false){
                    //                 resolve({status: false, step: "image"});
                    //             }else{
                    //                 resolve({status: true, step: "pay"});
                    //             }
                    //         }else if(resp.Type === "company"){
                    //             if(imageControl() === false){
                    //                 resolve({status: false, step: "image"});
                    //             }else{
                    //                 resolve({status: true, step: "pay"});
                    //             }
                    //         }else{
                    //             setAlertData({show: true, message: "Lütfen geçerli bir Müşteri TC No giriniz!"});
                    //             resolve({status: false, step: "form"});
                    //         }
                    //     })
                    // }else {
                    //     setAlertData({show: true, message: 'Müşterinin sadece "TC" veya "Vergi No" alanı dolu olmalıdır!'});
                    //     resolve({status: false, step: "form"});
                    // }

                }
            }else if(activeCard === "passportCard"){

                if((foundPassportPhotos.length < 1 || foundPassportPhotos.length < 1) && onlyDelivery === true) {
                    setAlertData({show: true, message: "Lütfen pasaport fotoğrafı yükleyiniz"})
                    resolve({status: false, step: "image"});
                }else{
                    if(reservation.identity_number){
                        api.get('user/identity/' + reservation.identity_number).then(resp => {

                            if(resp.Type === "foreign"){
                                if(imageControl() === false){
                                    resolve({status: false, step: "image"});
                                }else{
                                    resolve({status: true, step: "kabis"});
                                }
                            }else{
                                setAlertData({show: true, message: "Lütfen geçerli bir Kimlik No giriniz!"});
                                setStep("form");
                                resolve({status: false, step: "form"});
                            }
                        })
                    }else{
                        api.get('user/identity/' + reservation.tax_number).then(resp => {

                            if(resp.Type === "company" || resp.Type === "Company"){
                                if(imageControl() === false){
                                    resolve({status: false, step: "image"});
                                }else{
                                    resolve({status: true, step: "kabis"});
                                }
                            }else{
                                setAlertData({show: true, message: "Lütfen geçerli bir Kimlik No/Vergi No giriniz!"});
                                setStep("form");
                                resolve({status: false, step: "form"});
                            }
                        })
                    }

                }
            }else{
                setAlertData({show: true, message: "Lütfen istenen bilgileri eksiksiz giriniz"});
                setStep("form");
                resolve({status: false, step: "form"});
            }
        })

    }

    function imageControl(){
        let foundVehiclePhotos = photos.filter(x => x.type === "vehicle");

        if(foundVehiclePhotos.length < 8){
            console.log("dsadsadsadasds");
            setAlertData({show: true, message: "Lütfen Aracın Ön-Arka, Sağ - Sol Taraf Ve Araç Gösterge ve En Az 8 Fotoğraf Yükleyiniz."})
            return false;
        }else if(isVerify === false && onlyDelivery === true){

            setVerifyDialog(true);
            return false;
        }else if(onlyDelivery === true && onlyReturn === false ){
            let message = "* Bu formun sms doğrulama onayı sürücüden alınmıştır."
            api.get("mobileapp/" + match.params.id, {only_delivery: onlyDelivery, only_return: onlyReturn}).then((resp) => {
                let data = resp.data[0];
                //let newVerificationTime = moment(data.drivers[0].verification_time).format("DD/MM/YY HH:mm:ss");
                let newVerificationTime = moment().format("DD/MM/YY HH:mm:ss");

                updateSmsText(message + "(" + newVerificationTime + ")");
            })
            return true;
        }else {
            return true;
        }
    }

    function takeFormPhoto(){
        setStep("form");
        setLoading(true);

        setTimeout(() => {
            setPrinting(true);
            const node = document.getElementById('formWrapper');

            const options = {background: 'white', height: 1020, width: 800};
            domtoimage.toPng(node, options).then(function (dataUrl) {
                //alert('resim başarıyla çekildi consolea base64 versiyonu düşmüştür');
                let blobData = base64toBlob(dataUrl);
                let url = URL.createObjectURL(blobData);

                let img = new Image();
                img.src = url;

                let data = new FormData();
                data.append('file',blobData,'newImage.png');

                api.upload('upload',data).then(resp => {
                    setFormImg(resp.data.name);
                    save(resp.data.name);
                });
                setLoading(false);

            }).catch(function (error) {
                openToast("danger", "ATF HATASI", true)
                setLoading(false);
                setAtfError(true);

            }).finally(() => {
                setPrinting(false);
                setLoading(false);

            });
        },3000)

        //save("dfredf");

    }

    function save(image) {
        let damageList = [];
        let imageList = [];

        Object.keys(selectedDamages).forEach(function(k){
            damageList.push({
                code: k,
                value: selectedDamages[k]
            })
        });

        let filterPhotos = photos.filter(x => x.status === true);

        let imageResult = filterPhotos.map((item) => {
            return item.name;
        })
        console.log(KabisType,12);
        let params = {
            status_code: onlyDelivery === true && onlyReturn === false ? "delivered" : onlyDelivery === false && onlyReturn === true ? "completed" : null,
            form_image: image,
            auth_sign: signEmployeeImg?.name ? signEmployeeImg?.name : null,
            damage_list: damageList,
            kilometer: onlyDelivery === true && onlyReturn === false ? form.outKm : onlyDelivery === false && onlyReturn === true ? form.returnKm : null,
            clean_inside: form.inCleaning,
            clean_outside: form.inCleaning,
            fuel: onlyDelivery === true && onlyReturn === false ? form.outFuel : onlyDelivery === false && onlyReturn === true ? form.returnFuel : null,
            driver1_sign: driverArea.imageName,
            imageList: imageResult,
            kabisVariable:form,
            rental_note: deliveryDescription,
            delivery_time: moment()
        };
   
        
        setLoading(true);
        api.post('mobileapp/' + match.params.id ,params).then(resp => {
            setLoading(false);
            openToast("success", "İşlem Başarılı", true)
            window.location.assign('/reservations');

        }).catch((error) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    function clearDamageArray(){
        if(oldSelectedDamages.length > 0) {
            let newSelectedDamages = {}
            oldSelectedDamages.map(item => {
                newSelectedDamages = {
                    ...newSelectedDamages,
                    [item.code] : item.value
                }
            });
            setSelectedDamages(newSelectedDamages);
        }else{
            setSelectedDamages({})

        }
    }

    function closeAlertDialog(){
        setAlertData({show: false, message: ""});
    }

    function closeDamageAlertData(value){
        if(value === "no-damage"){
            setPassDamage(true);
            setDamageAlertData({show: false, message: ""});
        }else{
            setPassDamage(false);
            setDamageAlertData({show: false, message: ""});
        }
    }

    function openCustomerEditDialog(type, defaultValue) {
        if(onlyDelivery === true){
            //setIdentityNumberDialog(true);
            setCustomerEditDialog({open: true, type: type, defaultValue: defaultValue})
        }else{
            return false;
        }
    }

    function changeIdentityNumberDialog(value){
        //reservation.identity_number = value;
        reload()
    }

    function closeCustomerEditDialog(){
        //setIdentityNumberDialog(false);
        setCustomerEditDialog({open: false, type: null})

    }

    function openDriverEditDialog(id, idx, name, label, type, defaultValue) {
        if(onlyDelivery === true){
            setDriverEditDialog({
                show: true,
                id: id,
                idx: idx,
                driverValue: {name: name, label: label, type: type},
                defaultValue: defaultValue
            });
        }else{
            return false;
        }

    }

    function closeDriverEditDialog() {
        setDriverEditDialog({
            show: false,
            id: null,
            idx: null,
            driverValue: {name: null, label: null, type: null},

        });
    }

   function changeDriverEditDialog(){
       closeDriverEditDialog();
       reload();
   }

    function closeVerifyDialog() {
        setVerifyDialog(false);
    }

    function changeVerifyDialog(value){
        setIsVerify(value);
    }

    function openExtraDialog() {
        setExtraDialog(true)
    }

    function closeExtraDialog() {
        setExtraDialog(false);
        onChangeExtras();
    }

    function onChangeExtras() {
        console.log('change extra');
        reload()
    }

    function onChangePayStatus(value) {
        setPayStatus(value);
    }

    function onImagePageChange(imageArray) {
        setPhotos(imageArray);
    }

    function onChangeCard(value){
        if(value === "tcPhotos"){

        }else if(value === "passportCard"){

        }

        setActiveCard(value);
    }

    function verginoSorgula(kno) {
        let newKno = String(kno);
        let length = newKno.length;
        let d = [];
        let p = [];
        let v = [];
        let lastDigit = Number(newKno.charAt(9))

        for(let i=0; i< 9; i++){
            let tmp = (Number(newKno.charAt(i)) + (9 - i)) % 10
            v[i] = (tmp * 2 ** (9 - i)) % 9
            if (tmp !== 0 && v[i] === 0) v[i] = 9
        }

        let sum = v.reduce((a, b) => a + b, 0) % 10;
        let result = (10 - sum) % 10;

    }

    function searchDamage(value){
        setDamageListQuery(value);
        let query = value.toLowerCase();
        let result = damages.data.filter(item => item.name.toLowerCase().indexOf(query) >= 0);
        setDamages({data: result});
    }


    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              {step === "form" && <IonBackButton text="Geri" />}

              {step === "image" && (
                <IonButton onClick={() => setStep("pay")}>
                  <IonIcon icon={arrowBackOutline} /> Geri
                </IonButton>
              )}

              {step === "pay" && (
                <IonButton onClick={() => setStep("form")}>
                  <IonIcon icon={arrowBackOutline} /> Geri
                </IonButton>
              )}
              {step === "kabis" && (
                <IonButton onClick={() => setStep("image")}>
                  <IonIcon icon={arrowBackOutline} /> Geri
                </IonButton>
              )}
            </IonButtons>
            <IonTitle>
              {step === "form"
                ? "#" + reservation?.code + " Rezervasyon Detayı"
                : step === "image"
                ? "Fotoğraf Yükleme"
                : step === "pay"
                ? "Ödeme"
                : step === "kabis"
                ? "Kabis"
                : ""}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <FullLoader show={loading} />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={ionLoading}
          onDidDismiss={() => {}}
          message={"İşlem Gerçekleştiriliyor..."}
        />
        {Boolean(user?.roles?.find((x) => x.name === "Super Admin")) && (
          <div className="d-flex justify-content-center mt-2">
            <IonButton
              color={"danger"}
              fill={step === "form" ? "solid" : "outline"}
              onClick={() => setStep("form")}
            >
              Form
            </IonButton>
            <IonButton
              color={"danger"}
              fill={step === "image" ? "solid" : "outline"}
              onClick={() => setStep("image")}
            >
              Fotoğraf
            </IonButton>
            <IonButton
              color={"danger"}
              fill={step === "pay" ? "solid" : "outline"}
              onClick={() => setStep("pay")}
            >
              Ödeme
            </IonButton>
            <IonButton
              color={"danger"}
              fill={step === "kabis" ? "solid" : "outline"}
              onClick={() => setStep("kabis")}
            >
              Kabis
            </IonButton>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <IonButton
            color={"secondary"}
            className="mr-1"
            fill={"solid"}
            onClick={() => {
              setLoading(true);
              api
                .get("reservation/hgs/search/" + reservation.id)
                .then((resp) => {
                  load();
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            Anlık Hgs (
            {reservation?.now_hgs_amount ? reservation.now_hgs_amount : "0.00"}{" "}
            TL)
          </IonButton>
          {onlyDelivery === true && (
            <IonButton
              className={"ml-2"}
              color={"success"}
              fill={"solid"}
              onClick={() => {
                setChangeDialog(true);
              }}
            >
              <IonIcon icon={refreshCircle} /> Sürücü Değiştir
            </IonButton>
          )}
          {onlyDelivery === true && checkDriver() && (
            <IonButton
              className={"ml-2"}
              color={"danger"}
              fill={"solid"}
              onClick={() => {
                setDriverAddEdit({ open: true, extra_id: 11 });
              }}
            >
              <IonIcon icon={addCircle} /> Sürücü Ekle
            </IonButton>
          )}
          {/* {onlyDelivery === true && (
            <IonButton
              className={"ml-2"}
              color={"warning"}
              fill={"solid"}
              onClick={() => {
                setChangeDialog(true);
              }}
            >
              <IonIcon icon={addCircle} /> Ek Sürücü Ekle
            </IonButton>
          )} */}
        </div>
        <DriverChangeDialog
          show={changeDialog}
          onClose={() => setChangeDialog(false)}
          onDriverAddEditChange={() => reload()}
          reservation={reservation}
        />
        {data.length > 0 ? (
          <>
            {reservation && (
              <>
                <AlertDialog
                  show={alertData.show}
                  message={alertData.message}
                  onClose={closeAlertDialog}
                />
                {verifyDialog && (
                  <VerifyDialog
                    show={verifyDialog}
                    id={reservation.id}
                    isVerify={isVerify}
                    onlyDelivery={onlyDelivery}
                    onlyReturn={onlyReturn}
                    onChange={changeVerifyDialog}
                    onClose={closeVerifyDialog}
                  />
                )}
                <DriverAddEditDialog
                  show={driverAddEdit}
                  reservation={reservation}
                  onDriverAddEditChange={onDriverAddEditChange}
                  onClose={() =>
                    setDriverAddEdit({ open: false, extra_id: null })
                  }
                  defaultDriverData={true}
                />
                <IonFab vertical="top" horizontal="end" slot="fixed">
                  <IonFabButton
                    color="medium"
                    onClick={() => window.location.reload()}
                  >
                    <IonIcon icon={refreshOutline} />
                  </IonFabButton>
                </IonFab>

                {step === "form" && (
                  <IonContent
                    scrollX
                    className="ion-padding align-content-center scroll-content"
                  >
                    {extraDialog && (
                      <ExtrasDialog
                        show={extraDialog}
                        onlyDelivery={onlyDelivery}
                        onlyReturn={onlyReturn}
                        reservation={reservation}
                        onClose={closeExtraDialog}
                      />
                    )}
                    <DamageConfirmDialog
                      show={damageAlertData.show}
                      message={damageAlertData.message}
                      onClose={closeDamageAlertData}
                    />
                    <div
                      id="formWrapper"
                      className="form-wrapper border ion-padding"
                      style={{ margin: printing ? 0 : "0 auto" }}
                    >
                      <div className="d-flex flex-row">
                        <div className="d-flex flex-auto">
                          <Logo />
                        </div>
                        <div className="align-items-center d-flex flex-fill flex-row">
                          <div className="flex-grow-1 text-center">
                            <strong>Rezervasyon Kodu:</strong>{" "}
                            {reservation?.code}
                          </div>

                          <div className="flex-grow-1 text-center">
                            <strong>Kaynak:</strong> {reservation.company.name}
                          </div>
                        </div>
                      </div>

                      <div className="title my-1 text-center">
                        {onlyDelivery === true && onlyReturn === false
                          ? "Araç Teslim Formu"
                          : onlyDelivery === false && onlyReturn === true
                          ? "Araç İade Formu"
                          : "Form"}
                      </div>
                      <div>
                        <div className="sub-title my-1 text-center">
                          Müşteri
                        </div>
                        <div className="flexTable">
                          <div className="flexCell col-6">
                            <strong>Müşteri Adı:</strong> {reservation.name}
                          </div>
                          <div
                            className={
                              "flexCell col-3 position-relative custom-info"
                            }
                            onClick={() =>
                              openCustomerEditDialog(
                                "identity_number",
                                reservation.identity_number
                              )
                            }
                          >
                            <strong>TC No: </strong>
                            {onlyDelivery === true && (
                              <IonIcon
                                icon={createOutline}
                                style={{
                                  right: 2,
                                  top: 2,
                                  position: "absolute",
                                  fontSize: "12px",
                                }}
                              />
                            )}
                            {reservation.identity_number}
                          </div>
                          <div
                            className={
                              "flexCell col-3 position-relative custom-info"
                            }
                            onClick={() =>
                              openCustomerEditDialog(
                                "tax_number",
                                reservation.tax_number
                              )
                            }
                          >
                            <strong>Vergi No: </strong>
                            {onlyDelivery === true && (
                              <IonIcon
                                icon={createOutline}
                                style={{
                                  right: 2,
                                  top: 2,
                                  position: "absolute",
                                  fontSize: "12px",
                                }}
                              />
                            )}
                            {reservation.tax_number}
                          </div>

                          {/*<div className={"flexCell col-3 position-relative custom-info"} onClick={() => {}}><strong>Pasaport No: </strong>*/}
                          {/*    {onlyDelivery === true && (<IonIcon icon={createOutline} style={{right:2, top:2, position:"absolute", fontSize:"12px"}}/>)}*/}
                          {/*</div>*/}
                        </div>
                        <div className="flexTable">
                          <div
                            className="flexCell col-3 position-relative custom-info"
                            onClick={() =>
                              openCustomerEditDialog("email", reservation.email)
                            }
                          >
                            {onlyDelivery === true && (
                              <IonIcon
                                icon={createOutline}
                                style={{
                                  right: 2,
                                  top: 2,
                                  position: "absolute",
                                  fontSize: "12px",
                                }}
                              />
                            )}
                            <strong>Email:</strong> {reservation.email}
                          </div>

                          <div
                            className="flexCell col-9 position-relative custom-info"
                            onClick={() =>
                              openCustomerEditDialog(
                                "address",
                                reservation.address
                              )
                            }
                          >
                            {onlyDelivery === true && (
                              <IonIcon
                                icon={createOutline}
                                style={{
                                  right: 2,
                                  top: 2,
                                  position: "absolute",
                                  fontSize: "12px",
                                }}
                              />
                            )}
                            <strong>Fatura Adresi:</strong>{" "}
                            {reservation.address}
                          </div>
                        </div>
                      </div>

                      <CustomerEditDialog
                        show={customerEditDialog.open}
                        defaultValue={customerEditDialog.defaultValue}
                        id={reservation.id}
                        reservationData={reservation}
                        type={customerEditDialog.type}
                        onChange={changeIdentityNumberDialog}
                        onClose={closeCustomerEditDialog}
                      />

                      {reservation.drivers.map((item, idx) => (
                        <>
                          {idx < 2 && (
                            <>
                              <div
                                key={"driver-number-" + idx}
                                className="sub-title text-center"
                              >
                                {idx + 1} .Sürücü
                              </div>
                              <div key={"driver" + idx}>
                                <div className="flexTable">
                                  <div
                                    className="flexCell col-3 custom-info position-relative"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "name",
                                        "Ad Soyad",
                                        "text",
                                        item.user?.name
                                      );
                                    }}
                                  >
                                    <strong>Ad Soyad:</strong>
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    {truncate(item.name, 53)}
                                  </div>
                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "identity_number",
                                        "Yeni Kimlik No",
                                        "number",
                                        item.user?.identity_number
                                      );
                                    }}
                                  >
                                    <strong>TC No:</strong>
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    {item.user?.identity_number}
                                  </div>
                                  <div
                                    className={
                                      "flexCell col-3 position-relative custom-info"
                                    }
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "passport_number",
                                        "Yeni Pas. No",
                                        "text",
                                        item.user?.passport_number
                                      );
                                    }}
                                  >
                                    <strong>Pasaport No: </strong>
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    {item.user?.passport_number}
                                  </div>

                                  <div
                                    className="flexCell col-3  position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "email",
                                        "Yeni Email",
                                        "text",
                                        item.email
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>E-Posta:</strong>
                                    {item.email}
                                  </div>
                                </div>

                                <div className="flexTable">
                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "birthday",
                                        "Yeni Tarih",
                                        "date",
                                        item.birthday
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Doğum Tarihi:</strong>{" "}
                                    {item.birthday
                                      ? moment(item.birthday).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""}
                                  </div>
                                  <div
                                    className={
                                      "flexCell col-3 position-relative custom-info"
                                    }
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "phone",
                                        "Yeni Tel No",
                                        "number",
                                        item.phone
                                      );
                                    }}
                                  >
                                    <strong>Telefon:</strong>
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    {item.phone}
                                  </div>
                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "license_date",
                                        "Yeni Tarih",
                                        "date",
                                        item.license_date
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Ehliyet Veriliş Tarihi:</strong>{" "}
                                    {item.license_date
                                      ? moment(item.license_date).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""}
                                  </div>

                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "license_type",
                                        "Yeni Ehliyet Sınıfı",
                                        "text",
                                        item.license_type
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Ehliyet Sınıfı:</strong>
                                    {item.license_type}
                                  </div>
                                </div>

                                <div className="flexTable">
                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "license_number",
                                        "Yeni Ehliyet No",
                                        "text",
                                        item.license_number
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Ehliyet No:</strong>
                                    {item.license_number}
                                  </div>
                                  <div
                                    className="flexCell col-3 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "license_city",
                                        "Yeni E.Ver Yeri",
                                        "text",
                                        item.license_city
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Ehliyet Veriliş Yeri:</strong>
                                    {item.license_city}
                                  </div>

                                  <div
                                    className="flexCell col-6 position-relative custom-info"
                                    onClick={() => {
                                      openDriverEditDialog(
                                        item.id,
                                        idx + 1,
                                        "address",
                                        "Yeni Adres",
                                        "text",
                                        item.address
                                      );
                                    }}
                                  >
                                    {onlyDelivery === true && (
                                      <IonIcon
                                        icon={createOutline}
                                        style={{
                                          right: 2,
                                          top: 2,
                                          position: "absolute",
                                          fontSize: "12px",
                                        }}
                                      />
                                    )}
                                    <strong>Adres:</strong>
                                    {item.address}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))}

                      <DriverEditDialog
                        show={driverEditDialog.show}
                        reservation={reservation}
                        driverEditData={driverEditDialog}
                        onChange={changeDriverEditDialog}
                        onClose={closeDriverEditDialog}
                      />

                      <div className="sub-title my-1 text-center">
                        Rezervasyon Bilgileri
                      </div>

                      <div className="flexTable" onClick={openExtraDialog}>
                        <div
                          className={
                            "flexCell col position-relative custom-info"
                          }
                        >
                          <IonIcon
                            icon={createOutline}
                            style={{
                              right: 2,
                              top: 2,
                              position: "absolute",
                              fontSize: "14px",
                            }}
                          />
                          <strong>Extralar:</strong>
                          <span>Sınırsız Km ( Ücretsiz )</span>

                          {reservation.extras.length > 0 &&
                            reservation.extras.map((item, idx) => (
                              <span key={"extra" + idx}>
                                {" "}
                                {idx !== 0 && ", "}
                                {item.extra.name.tr}&nbsp;({" "}
                                {currencyFormat(item.price * exchangeRate)}{" "}
                                {reservation.currency.icon} )
                              </span>
                            ))}
                        </div>
                      </div>

                      <div className="flexTable">
                        <div className="flexCell col-3">
                          <strong>Günlük Araç Tutarı:</strong>{" "}
                          {currencyFormat(
                            reservation.daily_vehicle_amount * exchangeRate
                          )}{" "}
                          {reservation.currency.icon}
                        </div>
                        <div className="flexCell col-3">
                          <strong>Gün Sayısı:</strong> {reservation.day_range}
                        </div>
                        <div className="flexCell col-3">
                          <strong>Toplam Kiralama Bedeli:</strong>{" "}
                          {currencyFormat(
                            reservation.final_amount * exchangeRate
                          )}{" "}
                          {reservation.currency.icon}
                        </div>
                        <div className="flexCell col-3">
                          <strong>Ekstra Toplam:</strong>{" "}
                          {currencyFormat(
                            reservation.extras_amount * exchangeRate
                          )}{" "}
                          {reservation.currency.icon}
                        </div>
                      </div>

                      <div className="flexTable">
                        <div className="flexCell col-3">
                          <strong>Haftalık Km Hakkı:</strong> 1000
                        </div>
                        <div className="flexCell col-3">
                          <strong>Günlük Km Hakkı:</strong> 250
                        </div>
                        <div className="flexCell col-3">
                          <strong>İlave Km Hakkı:</strong> Sınırsız{" "}
                        </div>
                        <div className="flexCell col-3">
                          <strong>HGS Bedeli:</strong>{" "}
                          {currencyFormat(reservation.hgs_amount)} TL{" "}
                        </div>
                      </div>

                      {/*<div className="flexTable">*/}
                      {/*    <div className="flexCell col-3"><strong>Kaza Muafiyet Tutarı:</strong> </div>*/}
                      {/*    <div className="flexCell col-3"><strong>Ek Teminat:</strong> </div>*/}
                      {/*    <div className="flexCell col-3"><strong>TLDW Kabul / Red:</strong></div>*/}
                      {/*    <div className="flexCell col-3"><strong>TLDW Müşteri Seçimi:</strong> </div>*/}
                      {/*</div>*/}

                      <div className="my-2 ml-1">
                        *Mini Hasar sigortası satın alan kiracı 10.000 TL ödeme
                        yükümlülüğünü ortadan kaldırır<br></br> *Süper Mini
                        Hasar sigortası satın alan kiracı 15.000 TL ödeme
                        yükümlülüğünü ortadan kaldırır
                        <br></br>*Premium Hasar sigortası satın alan kiracı
                        20.000 TL ödeme yükümlülüğünü ortadan kaldırır
                      </div>

                      <div className="sub-title mb-1 text-center">Teslimat</div>

                      <div className="flexTable">
                        <div className="flexCell col-3">
                          <strong>Şube:</strong>{" "}
                          {reservation.begin_location?.name?.tr}
                        </div>
                        <div className="flexCell col-3">
                          <strong>Tarih:</strong>{" "}
                          {moment(reservation.begin_date).format("DD/MM/YY")}{" "}
                          <strong>
                            {moment(reservation.begin_date).format("(HH:mm)")}
                          </strong>
                        </div>
                        <div
                          className="flexCell col-3 position-relative"
                          style={{ zIndex: 0 }}
                        >
                          <strong>Km: </strong>
                          <span>{form.outKm}</span>
                          {onlyDelivery === false && onlyReturn === true ? (
                            <div className="touchAreaDisabled"></div>
                          ) : (
                            <div
                              className="touchArea"
                              onClick={() => {
                                openKmDialog("out", true);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            onlyDelivery === true
                              ? "custom-info flexCell col-3"
                              : "flexCell col-3"
                          }
                        >
                          <strong>Yakıt: </strong>
                          <span>{form.outFuel}</span>
                          {onlyDelivery === false && onlyReturn === true ? (
                            <div className="touchAreaDisabled"></div>
                          ) : (
                            <IonItem className="touchAreaSelect">
                              <IonLabel>Yakıt (Çıkış)</IonLabel>
                              <IonSelect
                                okText="Tamam"
                                cancelText="İptal"
                                onIonChange={(e) => {
                                  handleChange("outFuel", e.detail.value);
                                }}
                              >
                                {fuelData.map((item, idx) => (
                                  <IonSelectOption
                                    key={"option" + idx}
                                    value={item}
                                  >
                                    {item}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          )}
                        </div>
                      </div>

                      <div className="sub-title my-1 text-center">İade</div>

                      <div className="flexTable">
                        <div className="flexCell col-3">
                          <strong>Şube:</strong>{" "}
                          {reservation.end_location?.name?.tr}
                        </div>
                        <div className="flexCell col-3">
                          <strong>Tarih:</strong>{" "}
                          {moment(reservation.end_date).format("DD/MM/YY")}{" "}
                          <strong>
                            {moment(reservation.end_date).format("(HH:mm)")}
                          </strong>
                        </div>
                        <div className="flexCell col-3 position-relative ">
                          <strong>Km: </strong>
                          <span>{form.returnKm}</span>
                          {onlyDelivery === true && onlyReturn === false ? (
                            <div className="touchAreaDisabled"></div>
                          ) : (
                            <div
                              className={"touchArea"}
                              onClick={() => {
                                openKmDialog("return", true);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            onlyDelivery === false
                              ? "flexCell col-3 custom-info"
                              : "flexCell col-3"
                          }
                        >
                          <strong>Yakıt: </strong>
                          <span>{form.returnFuel}</span>
                          {onlyDelivery === true && onlyReturn === false ? (
                            <div className="touchAreaDisabled"></div>
                          ) : (
                            <IonItem className="touchAreaSelect">
                              <IonLabel>Yakıt (Dönüş)</IonLabel>
                              <IonSelect
                                okText="Tamam"
                                cancelText="İptal"
                                onIonChange={(e) => {
                                  handleChange("returnFuel", e.detail.value);
                                }}
                              >
                                {fuelData.map((item, idx) => (
                                  <IonSelectOption
                                    key={"option-2-" + idx}
                                    value={item}
                                  >
                                    {item}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          )}
                        </div>
                      </div>

                      <div className="my-2 ml-1">
                        <p>
                          <strong>* 1)</strong> Küçük Çizik <strong>2)</strong>{" "}
                          Büyük Çizik ve Ezik <strong>3)</strong> Hasar{" "}
                          <strong>4)</strong> Cam Çatlaması ve Kırılması{" "}
                          <strong>5)</strong> Jant Kapağı Eksik
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-8">
                          <div
                            className="image"
                            onClick={() => openDamageSelectModal(true)}
                          >
                            <VehicleImage />

                            <div className="imageSvg">
                              <ExpertiseSvg
                                width={480}
                                height={310}
                                data={selectedDamages}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4 ">
                          <div className="cell mb-2">
                            <strong>Plaka:</strong>{" "}
                            {reservation.vehicle?.license_plate}
                          </div>
                          <div className="cell mb-2">
                            <strong>Marka:</strong>{" "}
                            {reservation.model?.brand?.name}
                          </div>
                          <div className="cell mb-2">
                            <strong>Model:</strong> {reservation.model?.name}
                          </div>

                          <div className="cell mb-2 position-relative custom-info">
                            <strong>Temizlik Durumu: </strong>
                            <span>
                              {form.inCleaning === true
                                ? "Temiz"
                                : form.inCleaning === false
                                ? "Kirli"
                                : ""}
                            </span>
                            <IonItem className="touchAreaSelect">
                              <IonLabel>Temizlik Durumu</IonLabel>
                              <IonSelect
                                okText="Tamam"
                                cancelText="İptal"
                                onIonChange={(e) => {
                                  handleChange("inCleaning", e.detail.value);
                                }}
                              >
                                <IonSelectOption value={true}>
                                  {"Temiz"}
                                </IonSelectOption>
                                <IonSelectOption value={false}>
                                  {"Kirli"}
                                </IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </div>

                          {onlyDelivery === false && onlyReturn === true ? (
                            <div className="cell mb-2 position-relative ">
                              <strong>Hgs Durumu: </strong>
                              <span>
                                {form.inHgs === true ? "Kontrol Edildi" : ""}
                              </span>
                              <IonItem className="touchAreaSelect">
                                <IonLabel>Hgs Durumu</IonLabel>
                                <IonSelect
                                  okText="Tamam"
                                  cancelText="İptal"
                                  onIonChange={(e) => {
                                    handleChange("inHgs", e.detail.value);
                                  }}
                                >
                                  <IonSelectOption value={true}>
                                    {"Kontrol Edildi"}
                                  </IonSelectOption>
                                </IonSelect>
                              </IonItem>
                            </div>
                          ) : (
                            <div></div>
                          )}

                          {reservation.description && (
                            <div className="p-2 description mt-2">
                              <span>Açıklama:</span>
                              <p>{reservation.description}</p>
                            </div>
                          )}

                          <div
                            onClick={() =>
                              setDeliveryDescriptionDialog(
                                !deliveryDescriptionDialog
                              )
                            }
                            className="p-2 description mt-2 position-relative"
                          >
                            <IonIcon
                              icon={createOutline}
                              style={{
                                right: 2,
                                top: 2,
                                position: "absolute",
                                fontSize: "12px",
                              }}
                            />
                            <span>Teslimatçı Açıklama:</span>

                            <p>{deliveryDescription}</p>
                          </div>

                          <DeliveryPersonDescription
                            value={deliveryDescription}
                            open={deliveryDescriptionDialog}
                            onClose={() => setDeliveryDescriptionDialog(false)}
                            onChange={(value) => setDeliveryDescription(value)}
                          />
                        </div>
                      </div>

                      <div>
                        <p className="custom-text">
                          * Kiracılar bu Araç Teslim Formunu imza etmekle
                          KİRALAYAN ile aralarında imzalamış oldukları ARAÇ
                          KİRALAMA SÖZLEŞMESİ’nde belirtilen sözleşme şartlarını
                          Yukarıda yazan araç ve kiralama bilgileri için kabul
                          etmişlerdir. 5S OTOMOTİV İMALAT A.Ş. Bu araç teslim
                          formundaki bilgileri, kiralamanın öncesinde hazırlık,
                          sonrasında ise tahsilat, anket, arşiv vb. Kendi
                          operasyonel amaçları için belirleyeceği 3. Taraf
                          firmalarla paylaşabilir. Tanıtım amaçlı faliyetler
                          için 5S OTOMOTİV bu bilgileri kullanarak kiracılar ile
                          temasa geçebilir.
                        </p>
                        <p className="custom-text">
                          * Kıracılar bu araç teslim formunu imza etmekle ticari
                          elektronik izin, kişisel verilerin korunması ve
                          işlenmesini kabul etmişlerdir.
                        </p>
                        <p className="custom-text">
                          {reservation.drivers[0].verification_time
                            ? "*Bu formun sms doğrulama onayı sürücüden alınmıştır.(" +
                              moment(
                                reservation.drivers[0].verification_time
                              ).format("DD/MM/YYYY HH:mm:ss") +
                              ")"
                            : getSmsText()}
                        </p>
                      </div>
                      <FormSignArea
                        value={driverArea}
                        drivers={reservation.drivers}
                        onlyDelivery={onlyDelivery}
                        onlyPrice={
                          currencyFormat(
                            reservation.final_amount * exchangeRate
                          ) + reservation.currency.icon
                        }
                        onlyReturn={onlyReturn}
                        onChange={onChangeDriverSign}
                      />

                      <div className="flexTable border-0 my-1">
                        <div className="flexCell col-4 border-0">
                          <strong>
                            {onlyDelivery === true
                              ? "Teslim Eden (Görevli):"
                              : "Teslim Alan (Görevli):"}
                          </strong>{" "}
                          {user.name}
                        </div>
                        <div className="flexCell col-4 border-0">
                          <strong>Tarih Saat:</strong>{" "}
                          {moment().format("DD/MM/YY")}{" "}
                          <strong>{moment().format("HH:mm")}</strong>
                        </div>
                        <div className="flexCell col-4 border-0">
                          <strong>İmza:</strong>
                          {signEmployeeImg ? (
                            <img
                              onClick={() => {
                                openSignEmployeeModal(true);
                              }}
                              src={signEmployeeImg?.link}
                              width={102}
                              height={25}
                              alt="Orijinal İmza"
                            />
                          ) : (
                            <span
                              className="signArea"
                              onClick={() => {
                                openSignEmployeeModal(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <IonAlert
                      isOpen={kmDialog.open}
                      onDidDismiss={() => {
                        openKmDialog(null, null);
                      }}
                      header={"Km"}
                      inputs={[
                        {
                          name: "kmData",
                          type: "number",
                        },
                      ]}
                      buttons={[
                        {
                          text: "İptal",
                          role: "cancel",
                          handler: () => {},
                        },
                        {
                          text: "Kaydet",
                          handler: (alertData) => {
                            handleChange(
                              kmDialog.type + "Km",
                              alertData.kmData
                            );
                          },
                        },
                      ]}
                    />

                    <IonModal
                      isOpen={signEmployeeModal}
                      onDidDismiss={() => {
                        openSignEmployeeModal(false);
                      }}
                    >
                      <div className="p-3">
                        <h3>İmza (Görevli)</h3>
                        <small>İmzayı aşağıdaki kutucuğun içine atınız</small>
                      </div>
                      <div className="p-3">
                        <div className="border border-secondary ">
                          <SignatureCanvas
                            ref={canvas}
                            penColor="black"
                            canvasProps={{
                              width: 500,
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />
                        </div>
                      </div>
                      <IonButtons className="p-2 d-flex justify-content-end">
                        <IonButton
                          color="medium"
                          onClick={() => openSignEmployeeModal(false)}
                        >
                          İptal
                        </IonButton>
                        <IonButton
                          color="primary"
                          onClick={($e) => {
                            $e.preventDefault();
                            let c = canvas.current.getDotAndSign();
                            let blobData = base64toBlob(c.original_image);
                            let data = new FormData();
                            data.append("file", blobData, "newImage.png");
                            setIonLoading(true);
                            api
                              .upload("upload", data)
                              .then((resp) => {
                                let driverData = {
                                  link: c.original_image,
                                  name: resp.data.name,
                                };
                                setSignEmployeeImg(driverData);
                                openSignEmployeeModal(false);
                                setIonLoading(false);
                              })
                              .finally(() => {
                                setIonLoading(false);
                              });
                          }}
                        >
                          Kaydet
                        </IonButton>
                      </IonButtons>
                    </IonModal>

                    <IonModal
                      isOpen={damageSelectModal}
                      onDidDismiss={() => {
                        openDamageSelectModal(false);
                      }}
                    >
                      <IonContent>
                        <div
                          className="position-fixed bg-white w-100 py-2 px-2"
                          style={{ zIndex: 99 }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <strong className="h4">Hasar Listesi</strong>
                            <IonIcon
                              className={"text-primary"}
                              size={"large"}
                              icon={closeCircleOutline}
                              onClick={() => {
                                openDamageSelectModal(false);
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-3 mt-4">
                          {damages &&
                            damages.data.length > 0 &&
                            damages.data.map((item, idx) => (
                              <IonItem
                                key={item.code + item.id + idx}
                                disabled={Boolean(
                                  oldSelectedDamages.find(
                                    (x) => x.code === item.code
                                  )
                                )}
                              >
                                <IonLabel>{item.name}</IonLabel>
                                <IonSelect
                                  okText="Tamam"
                                  cancelText="İptal"
                                  value={selectedDamages[item.code]}
                                  placeholder="Seç"
                                  onIonChange={(e) => {
                                    damageChange(item.code, e.detail.value);
                                  }}
                                >
                                  <IonSelectOption value="1">1</IonSelectOption>
                                  <IonSelectOption value="2">2</IonSelectOption>
                                  <IonSelectOption value="3">3</IonSelectOption>
                                  <IonSelectOption value="4">4</IonSelectOption>
                                  <IonSelectOption value="5">5</IonSelectOption>
                                </IonSelect>
                              </IonItem>
                            ))}
                        </div>
                      </IonContent>
                      <IonButtons className="p-2 d-flex justify-content-end">
                        <IonButton
                          color="medium"
                          onClick={() => {
                            clearDamageArray();
                          }}
                        >
                          Temizle
                        </IonButton>
                        <IonButton
                          color="primary"
                          onClick={() => {
                            openDamageSelectModal(false);
                          }}
                        >
                          Tamam
                        </IonButton>
                      </IonButtons>
                    </IonModal>
                  </IonContent>
                )}

                {step === "image" && (
                  <ImageUploadPage
                    active={activeCard}
                    onlyDelivery={onlyDelivery}
                    onlyReturn={onlyReturn}
                    onChangeCard={onChangeCard}
                    onChangePassportPhoto={() => reload()}
                    reservation={reservation}
                    defaultValues={photos}
                    onChange={onImagePageChange}
                  />
                )}

                {step === "pay" && (
                  <LastStepDialog
                    onlyReturn={onlyReturn}
                    onlyDelivery={onlyDelivery}
                    reservation={reservation}
                    onChangePay={onChangePayStatus}
                  />
                )}
                {step === "kabis" && (
                  <IonContent
                    scrollX
                    className="ion-padding align-content-center scroll-content"
                  >
                    <IonContent>
                      <IonList>
                        <IonRadioGroup
                          value={selected}
                          onIonChange={(e) => setSelected(e.detail.value)}
                        >
                          {checkboxList.map(({ val, isChecked }, i) => (
                            <IonItem key={i}>
                              <IonLabel>{val}</IonLabel>
                              <IonRadio value={val} checked={isChecked} />
                            </IonItem>
                          ))}
                        </IonRadioGroup>

                        {/* <IonItem>{selected ?? '(none selected'}</IonItem> */}
                        {selected === "Türk Vatandaşı Türk Ehliyeti Olan" && (
                          <div>
                            <IonItemDivider>TC Kimlik Numarası</IonItemDivider>
                            <IonItem
                              onIonLoad={(e) => {
                                handleChange("kabistt", e.detail.value);
                              }}
                            >
                              <IonInput
                                value={
                                  reservation.drivers[0]?.user?.identity_number
                                }
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                                disabled
                              ></IonInput>
                            </IonItem>

                            {/* <IonItemDivider>İrtibat Numarası</IonItemDivider>
          <IonItem>
         Ülke :&nbsp;<IonSelect placeholder="Ülke Seçiniz">
               	<IonSelectOption value="+93">AFGANİSTAN (+93)</IonSelectOption>
		<IonSelectOption value="+355">ARNAVUTLUK (+355)</IonSelectOption>
		<IonSelectOption value="+213">CEZAYİR (+213)</IonSelectOption>
		<IonSelectOption value="+1684">AMERİKAN SAMOASI (+1684)</IonSelectOption>
		<IonSelectOption value="+376">ANDORRA (+376)</IonSelectOption>
		<IonSelectOption value="+244">ANGOLA (+244)</IonSelectOption>
		<IonSelectOption value="+1264">ANGUİLLA (+1264)</IonSelectOption>
		<IonSelectOption value="+0">ANTARTİKA (+0)</IonSelectOption>
		<IonSelectOption value="+1268">ANTİGUA VE BARBUDA (+1268)</IonSelectOption>
		<IonSelectOption value="+54">ARJANTİN (+54)</IonSelectOption>
		<IonSelectOption value="+374">ERMENİSTAN (+374)</IonSelectOption>
		<IonSelectOption value="+297">ARUBA (+297)</IonSelectOption>
		<IonSelectOption value="+61">AVUSTRALYA (+61)</IonSelectOption>
		<IonSelectOption value="+43">AVUSTURYA (+43)</IonSelectOption>
		<IonSelectOption value="+994">AZERBAYCAN (+994)</IonSelectOption>
		<IonSelectOption value="+1242">BAHAMALAR (+1242)</IonSelectOption>
		<IonSelectOption value="+973">BAHREYN (+973)</IonSelectOption>
		<IonSelectOption value="+880">BANGLADEŞ (+880)</IonSelectOption>
		<IonSelectOption value="+1246">BARBADOS (+1246)</IonSelectOption>
		<IonSelectOption value="+375">BELARUS (+375)</IonSelectOption>
		<IonSelectOption value="+32">BEL&#199;İKA (+32)</IonSelectOption>
		<IonSelectOption value="+501">BELİZE (+501)</IonSelectOption>
		<IonSelectOption value="+229">BENİN (+229)</IonSelectOption>
		<IonSelectOption value="+1441">BERMUDA (+1441)</IonSelectOption>
		<IonSelectOption value="+975">BUTAN (+975)</IonSelectOption>
		<IonSelectOption value="+591">BOLİVYA (+591)</IonSelectOption>
		<IonSelectOption value="+387">BOSNA HERSEK (+387)</IonSelectOption>
		<IonSelectOption value="+267">BOTSVANA (+267)</IonSelectOption>
		<IonSelectOption value="+0">BOUVET ADASI (+0)</IonSelectOption>
		<IonSelectOption value="+55">BREZİLYA (+55)</IonSelectOption>
		<IonSelectOption value="+246">BRİTANYA HİNT OKYANUSU TOPRAKLARI (+246)</IonSelectOption>
		<IonSelectOption value="+673">BRUNEİ (+673)</IonSelectOption>
		<IonSelectOption value="+359">BULGARİSTAN (+359)</IonSelectOption>
		<IonSelectOption value="+226">BURKİNA FASO (+226)</IonSelectOption>
		<IonSelectOption value="+257">BURUNDİ (+257)</IonSelectOption>
		<IonSelectOption value="+855">KAMBO&#199;YA (+855)</IonSelectOption>
		<IonSelectOption value="+237">KAMERUN (+237)</IonSelectOption>
		<IonSelectOption value="+1">KANADA (+1)</IonSelectOption>
		<IonSelectOption value="+238">YEŞİL BURUN ADALARI (+238)</IonSelectOption>
		<IonSelectOption value="+1345">CAYMAN ADALARI (+1345)</IonSelectOption>
		<IonSelectOption value="+236">ORTA AFRİKA CUMHURİYETİ (+236)</IonSelectOption>
		<IonSelectOption value="+235">&#199;AD (+235)</IonSelectOption>
		<IonSelectOption value="+56">ŞİLİ (+56)</IonSelectOption>
		<IonSelectOption value="+86">&#199;İN (+86)</IonSelectOption>
		<IonSelectOption value="+61">CHRİSTMAS ADASI (+61)</IonSelectOption>
		<IonSelectOption value="+672">COCOS ADALARI (+672)</IonSelectOption>
		<IonSelectOption value="+57">KOLOMBİYA (+57)</IonSelectOption>
		<IonSelectOption value="+269">KOMORLAR (+269)</IonSelectOption>
		<IonSelectOption value="+242">KONGO (+242)</IonSelectOption>
		<IonSelectOption value="+242">DEMOKRATİK KONGO CUMHURİYETİ (+242)</IonSelectOption>
		<IonSelectOption value="+682">COOK ADALARI (+682)</IonSelectOption>
		<IonSelectOption value="+506">KOSTA RİKA (+506)</IonSelectOption>
		<IonSelectOption value="+225">FİLDİŞİ SAHİLİ (+225)</IonSelectOption>
		<IonSelectOption value="+385">HIRVATİSTAN (+385)</IonSelectOption>
		<IonSelectOption value="+53">K&#220;BA (+53)</IonSelectOption>
		<IonSelectOption value="+357">KIBRIS (+357)</IonSelectOption>
		<IonSelectOption value="+420">&#199;EK CUMHURİYETİ (+420)</IonSelectOption>
		<IonSelectOption value="+45">DANİMARKA (+45)</IonSelectOption>
		<IonSelectOption value="+253">CİBUTİ (+253)</IonSelectOption>
		<IonSelectOption value="+1767">DOMİNİKA (+1767)</IonSelectOption>
		<IonSelectOption value="+1809">DOMİNİK CUMHURİYETİ (+1809)</IonSelectOption>
		<IonSelectOption value="+593">EKVATOR (+593)</IonSelectOption>
		<IonSelectOption value="+20">MISIR (+20)</IonSelectOption>
		<IonSelectOption value="+503">EL SALVADOR (+503)</IonSelectOption>
		<IonSelectOption value="+240">EKVATOR GİNESİ (+240)</IonSelectOption>
		<IonSelectOption value="+291">ERİTRE (+291)</IonSelectOption>
		<IonSelectOption value="+372">ESTONYA (+372)</IonSelectOption>
		<IonSelectOption value="+251">ETİYOPYA (+251)</IonSelectOption>
		<IonSelectOption value="+500">FALKLAND ADALARI (+500)</IonSelectOption>
		<IonSelectOption value="+298">FAROE ADALARI (+298)</IonSelectOption>
		<IonSelectOption value="+679">FİJİ (+679)</IonSelectOption>
		<IonSelectOption value="+358">FİNLANDİYA (+358)</IonSelectOption>
		<IonSelectOption value="+33">FRANSA (+33)</IonSelectOption>
		<IonSelectOption value="+594">FRANSIZ GUYANASI (+594)</IonSelectOption>
		<IonSelectOption value="+689">FRANSIZ POLİNEZYASI (+689)</IonSelectOption>
		<IonSelectOption value="+0">FRANSIZ G&#220;NEY TOPRAKLARI (+0)</IonSelectOption>
		<IonSelectOption value="+241">GABON (+241)</IonSelectOption>
		<IonSelectOption value="+220">GAMBİYA (+220)</IonSelectOption>
		<IonSelectOption value="+995">G&#220;RCİSTAN (+995)</IonSelectOption>
		<IonSelectOption value="+49">ALMANYA (+49)</IonSelectOption>
		<IonSelectOption value="+233">GANA (+233)</IonSelectOption>
		<IonSelectOption value="+350">CEBELİTARIK (+350)</IonSelectOption>
		<IonSelectOption value="+30">YUNANİSTAN (+30)</IonSelectOption>
		<IonSelectOption value="+299">GR&#214;NLAND (+299)</IonSelectOption>
		<IonSelectOption value="+1473">GRENADA (+1473)</IonSelectOption>
		<IonSelectOption value="+590">GUADELOUPE (+590)</IonSelectOption>
		<IonSelectOption value="+1671">GUAM (+1671)</IonSelectOption>
		<IonSelectOption value="+502">GUATEMELA (+502)</IonSelectOption>
		<IonSelectOption value="+224">GİNE (+224)</IonSelectOption>
		<IonSelectOption value="+245">GİNE-BİSSAU (+245)</IonSelectOption>
		<IonSelectOption value="+592">GUYANA (+592)</IonSelectOption>
		<IonSelectOption value="+509">HAİTİ (+509)</IonSelectOption>
		<IonSelectOption value="+0">HEARD ADASI VE MCDONALD ADALARI (+0)</IonSelectOption>
		<IonSelectOption value="+39">VATİKAN (+39)</IonSelectOption>
		<IonSelectOption value="+504">HONDURAS (+504)</IonSelectOption>
		<IonSelectOption value="+852">HONG KONG (+852)</IonSelectOption>
		<IonSelectOption value="+36">MACARİSTAN (+36)</IonSelectOption>
		<IonSelectOption value="+354">İZLANDA (+354)</IonSelectOption>
		<IonSelectOption value="+91">HİNDİSTAN (+91)</IonSelectOption>
		<IonSelectOption value="+62">ENDONEZYA (+62)</IonSelectOption>
		<IonSelectOption value="+98">İRAN (+98)</IonSelectOption>
		<IonSelectOption value="+964">IRAK (+964)</IonSelectOption>
		<IonSelectOption value="+353">İRLANDA (+353)</IonSelectOption>
		<IonSelectOption value="+972">İSRAİL (+972)</IonSelectOption>
		<IonSelectOption value="+39">İTALYA (+39)</IonSelectOption>
		<IonSelectOption value="+1876">JAMAİKA (+1876)</IonSelectOption>
		<IonSelectOption value="+81">JAPONYA (+81)</IonSelectOption>
		<IonSelectOption value="+962">&#220;RD&#220;N (+962)</IonSelectOption>
		<IonSelectOption value="+7">KAZAKİSTAN (+7)</IonSelectOption>
		<IonSelectOption value="+254">KENYA (+254)</IonSelectOption>
		<IonSelectOption value="+686">KİRİBATİ (+686)</IonSelectOption>
		<IonSelectOption value="+850">KUZEY KORE (+850)</IonSelectOption>
		<IonSelectOption value="+82">G&#220;NEY KORE (+82)</IonSelectOption>
		<IonSelectOption value="+965">KUVEYT (+965)</IonSelectOption>
		<IonSelectOption value="+996">KIRGIZİSTAN (+996)</IonSelectOption>
		<IonSelectOption value="+856">LAOS (+856)</IonSelectOption>
		<IonSelectOption value="+371">LETONYA (+371)</IonSelectOption>
		<IonSelectOption value="+961">L&#220;BNAN (+961)</IonSelectOption>
		<IonSelectOption value="+266">LESOTHO (+266)</IonSelectOption>
		<IonSelectOption value="+231">LİBERYA (+231)</IonSelectOption>
		<IonSelectOption value="+218">LİBYAN ARAP JAMAHİRİYA (+218)</IonSelectOption>
		<IonSelectOption value="+423">LİHTENŞAYN (+423)</IonSelectOption>
		<IonSelectOption value="+370">LİTVANYA (+370)</IonSelectOption>
		<IonSelectOption value="+352">L&#220;KSEMBURG (+352)</IonSelectOption>
		<IonSelectOption value="+853">MAKAO (+853)</IonSelectOption>
		<IonSelectOption value="+389">MAKEDONYA (+389)</IonSelectOption>
		<IonSelectOption value="+261">MADAGASKAR (+261)</IonSelectOption>
		<IonSelectOption value="+265">MALAVİ (+265)</IonSelectOption>
		<IonSelectOption value="+60">MALEZYA (+60)</IonSelectOption>
		<IonSelectOption value="+960">MALDİV ADALARI (+960)</IonSelectOption>
		<IonSelectOption value="+223">MALİ (+223)</IonSelectOption>
		<IonSelectOption value="+356">MALTA (+356)</IonSelectOption>
		<IonSelectOption value="+692">MARSHALL ADALARI (+692)</IonSelectOption>
		<IonSelectOption value="+596">MARTİNİK (+596)</IonSelectOption>
		<IonSelectOption value="+222">MORİTANYA (+222)</IonSelectOption>
		<IonSelectOption value="+230">MAURITIUS (+230)</IonSelectOption>
		<IonSelectOption value="+269">MAYOTTE (+269)</IonSelectOption>
		<IonSelectOption value="+52">MEKSİKA (+52)</IonSelectOption>
		<IonSelectOption value="+691">MİKRONEZYA (+691)</IonSelectOption>
		<IonSelectOption value="+373">MOLDOVA (+373)</IonSelectOption>
		<IonSelectOption value="+377">MONAKO (+377)</IonSelectOption>
		<IonSelectOption value="+976">MOĞOLİSTAN (+976)</IonSelectOption>
		<IonSelectOption value="+1664">MONTSERRAT (+1664)</IonSelectOption>
		<IonSelectOption value="+212">FAS (+212)</IonSelectOption>
		<IonSelectOption value="+258">MOZAMBİK (+258)</IonSelectOption>
		<IonSelectOption value="+95">MYANMAR (+95)</IonSelectOption>
		<IonSelectOption value="+264">NAMİBYA (+264)</IonSelectOption>
		<IonSelectOption value="+674">NAURU (+674)</IonSelectOption>
		<IonSelectOption value="+977">NEPAL (+977)</IonSelectOption>
		<IonSelectOption value="+31">HOLLANDA (+31)</IonSelectOption>
		<IonSelectOption value="+599">HOLLANDA ANTİLLERİ (+599)</IonSelectOption>
		<IonSelectOption value="+687">YENİ KALEDONYA (+687)</IonSelectOption>
		<IonSelectOption value="+64">YENİ ZELANDA (+64)</IonSelectOption>
		<IonSelectOption value="+505">NİKARAGUA (+505)</IonSelectOption>
		<IonSelectOption value="+227">NİJER (+227)</IonSelectOption>
		<IonSelectOption value="+234">NİJERYA (+234)</IonSelectOption>
		<IonSelectOption value="+683">NİUE (+683)</IonSelectOption>
		<IonSelectOption value="+672">NORFOLK ADALARI (+672)</IonSelectOption>
		<IonSelectOption value="+1670">KUZEY MARİAN ADALARI (+1670)</IonSelectOption>
		<IonSelectOption value="+47">NORVE&#199; (+47)</IonSelectOption>
		<IonSelectOption value="+968">UMMAN (+968)</IonSelectOption>
		<IonSelectOption value="+92">PAKİSTAN (+92)</IonSelectOption>
		<IonSelectOption value="+680">PALAU (+680)</IonSelectOption>
		<IonSelectOption value="+970">FİLİSTİN (+970)</IonSelectOption>
		<IonSelectOption value="+507">PANAMA (+507)</IonSelectOption>
		<IonSelectOption value="+675">PAPUA YENİ GİNE (+675)</IonSelectOption>
		<IonSelectOption value="+595">PARAGUAY (+595)</IonSelectOption>
		<IonSelectOption value="+51">PERU (+51)</IonSelectOption>
		<IonSelectOption value="+63">FİLİPİNLER (+63)</IonSelectOption>
		<IonSelectOption value="+0">PİTCAİRN (+0)</IonSelectOption>
		<IonSelectOption value="+48">POLONYA (+48)</IonSelectOption>
		<IonSelectOption value="+351">PORTEKİZ (+351)</IonSelectOption>
		<IonSelectOption value="+1787">PORTORİKO (+1787)</IonSelectOption>
		<IonSelectOption value="+974">KATAR (+974)</IonSelectOption>
		<IonSelectOption value="+262">RUENİON (+262)</IonSelectOption>
		<IonSelectOption value="+40">ROMANYA (+40)</IonSelectOption>
		<IonSelectOption value="+70">RUSYA FEDERASYONU (+70)</IonSelectOption>
		<IonSelectOption value="+250">RWANDA (+250)</IonSelectOption>
		<IonSelectOption value="+290">SAİNT HELENA (+290)</IonSelectOption>
		<IonSelectOption value="+1869">SAİNT KİTS VE NEVİS (+1869)</IonSelectOption>
		<IonSelectOption value="+1758">SAİNT LUCİA (+1758)</IonSelectOption>
		<IonSelectOption value="+508">SAİNT PİERRE VE MİQUELON (+508)</IonSelectOption>
		<IonSelectOption value="+1784">SAİNT VİNCENT VE GRENADİNLER (+1784)</IonSelectOption>
		<IonSelectOption value="+684">SAMOA (+684)</IonSelectOption>
		<IonSelectOption value="+378">SAN MARİNO (+378)</IonSelectOption>
		<IonSelectOption value="+239">SAO TOME VE PRİNCİPE (+239)</IonSelectOption>
		<IonSelectOption value="+966">SUUDİ ARABİSTAN (+966)</IonSelectOption>
		<IonSelectOption value="+221">SENEGAL (+221)</IonSelectOption>
		<IonSelectOption value="+381">SIRBİSTAN (+381)</IonSelectOption>
		<IonSelectOption value="+248">SEYŞELLER (+248)</IonSelectOption>
		<IonSelectOption value="+232">SİERRA-LEONE (+232)</IonSelectOption>
		<IonSelectOption value="+65">SİNGAPUR (+65)</IonSelectOption>
		<IonSelectOption value="+421">SLOVAKYA (+421)</IonSelectOption>
		<IonSelectOption value="+386">SLOVENYA (+386)</IonSelectOption>
		<IonSelectOption value="+677">SOLOMON ADALARI (+677)</IonSelectOption>
		<IonSelectOption value="+252">SOMALİ (+252)</IonSelectOption>
		<IonSelectOption value="+27">G&#220;NEY AFRİKA (+27)</IonSelectOption>
		<IonSelectOption value="+0">G&#220;NEY GE.VE SANDVİC ADALARI (+0)</IonSelectOption>
		<IonSelectOption value="+34">İSPANYA (+34)</IonSelectOption>
		<IonSelectOption value="+94">SRİ LANKA (+94)</IonSelectOption>
		<IonSelectOption value="+249">SUDAN (+249)</IonSelectOption>
		<IonSelectOption value="+597">SURİNAM (+597)</IonSelectOption>
		<IonSelectOption value="+47">SVALBARD VE JAN MAYEN ADALARI (+47)</IonSelectOption>
		<IonSelectOption value="+268">ESVATİNİ (+268)</IonSelectOption>
		<IonSelectOption value="+46">İSVE&#199; (+46)</IonSelectOption>
		<IonSelectOption value="+41">İSVİ&#199;RE (+41)</IonSelectOption>
		<IonSelectOption value="+963">SURİYE (+963)</IonSelectOption>
		<IonSelectOption value="+886">TAYVAN (+886)</IonSelectOption>
		<IonSelectOption value="+992">TACİKİSTAN (+992)</IonSelectOption>
		<IonSelectOption value="+255">TANZANYA (+255)</IonSelectOption>
		<IonSelectOption value="+66">TAYLAND (+66)</IonSelectOption>
		<IonSelectOption value="+670">DOĞU TİMOR (+670)</IonSelectOption>
		<IonSelectOption value="+228">TOGO (+228)</IonSelectOption>
		<IonSelectOption value="+690">TOKELAU (+690)</IonSelectOption>
		<IonSelectOption value="+676">TONGA (+676)</IonSelectOption>
		<IonSelectOption value="+1868">TRİNİDAD VE TOBAGO (+1868)</IonSelectOption>
		<IonSelectOption value="+216">TUNUS (+216)</IonSelectOption>
		<IonSelectOption selected="selected" value="+90">T&#220;RKİYE (+90)</IonSelectOption>
		<IonSelectOption value="+7370">T&#220;RKMENİSTAN (+7370)</IonSelectOption>
		<IonSelectOption value="+1649">T&#220;RKS VE CAİCOS ADALARI (+1649)</IonSelectOption>
		<IonSelectOption value="+688">TUVALU (+688)</IonSelectOption>
		<IonSelectOption value="+256">UGANDA (+256)</IonSelectOption>
		<IonSelectOption value="+380">UKRAYNA (+380)</IonSelectOption>
		<IonSelectOption value="+971">BİRLEŞİK ARAP EMİRLİKLERİ (+971)</IonSelectOption>
		<IonSelectOption value="+44">BİRLEŞİK KRALLIK (+44)</IonSelectOption>
		<IonSelectOption value="+1">AMERİKA BİRLEŞİK DEVLETLERİ (+1)</IonSelectOption>
		<IonSelectOption value="+1">AMERİKA BİRLEŞİK DEVLETLERİ K&#220;&#199;&#220;K HARİCİ ADALARI (+1)</IonSelectOption>
		<IonSelectOption value="+598">URUGUAY (+598)</IonSelectOption>
		<IonSelectOption value="+998">&#214;ZBEKİSTAN (+998)</IonSelectOption>
		<IonSelectOption value="+678">VANUATU (+678)</IonSelectOption>
		<IonSelectOption value="+58">VENEZUELA (+58)</IonSelectOption>
		<IonSelectOption value="+84">VİETNAM (+84)</IonSelectOption>
		<IonSelectOption value="+1284">BRİTANYA VİRJİN ADALARI, (+1284)</IonSelectOption>
		<IonSelectOption value="+1340">ABD VİRJİN ADALARI (+1340)</IonSelectOption>
		<IonSelectOption value="+681">WALLİS VE FUTUNA ADALARI (+681)</IonSelectOption>
		<IonSelectOption value="+212">BATI SAHRA (+212)</IonSelectOption>
		<IonSelectOption value="+967">YEMEN (+967)</IonSelectOption>
		<IonSelectOption value="+260">ZAMBİYA (+260)</IonSelectOption>
		<IonSelectOption value="+263">ZİMBABVE (+263)</IonSelectOption>
		<IonSelectOption value="+382">KARADAĞ (+382)</IonSelectOption>
            </IonSelect>
           Alan Kodu :&nbsp;<IonInput  placeholder="553"></IonInput>
           Numara :&nbsp; <IonInput placeholder="6827255"></IonInput>
          </IonItem>
          <IonItemDivider>Km</IonItemDivider>
          <IonItem>
            <IonInput disabled  placeholder="" value={form.outKm}  ></IonInput>
          </IonItem> */}

                            <IonItemDivider>Teslim Km</IonItemDivider>
                            <IonItem>
                              <IonInput
                                disabled
                                placeholder=""
                                value={
                                  form.returnKm == null
                                    ? form.outKm
                                    : form.returnKm
                                }
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>
                          </div>
                        )}
                        {selected === "Türk Vatandaşı Yabancı Ehliyetli" && (
                          <div>
                            <IonItemDivider>TC Kimlik Numarası</IonItemDivider>
                            <IonItem>
                              <IonInput
                                value={
                                  reservation.drivers[0]?.user?.identity_number
                                }
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                                disabled
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Yabancı Ehliyet No:</IonItemDivider>
                            <IonItem>
                              <IonInput
                                disabled
                                placeholder=""
                                value={reservation.drivers[0]?.license_number}
                              ></IonInput>
                            </IonItem>
                            <IonItemDivider>Teslim Km</IonItemDivider>
                            <IonItem>
                              <IonInput
                                disabled
                                placeholder=""
                                value={form.outKm}
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>
                          </div>
                        )}
                        {selected === "Yabancı Uyruklu Yabancı Ehliyetli" && (
                          <div>
                            <IonItemDivider>Pasaport Numarası</IonItemDivider>
                            <IonItem>
                              <IonInput
                                value={
                                  reservation.drivers[0]?.user?.passport_number
                                }
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                                disabled
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Ad</IonItemDivider>
                            <IonItem>
                              <IonInput
                                placeholder=""
                                onIonChange={(e) => {
                                  handleChange("ad", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Soyad</IonItemDivider>
                            <IonItem>
                              <IonInput
                                placeholder=""
                                onIonChange={(e) => {
                                  handleChange("soyad", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Doğum Yeri</IonItemDivider>
                            <IonItem>
                              <IonInput
                                placeholder=""
                                onIonChange={(e) => {
                                  handleChange("dogum_yeri", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Doğum Yılı</IonItemDivider>
                            <IonItem>
                              <IonInput
                                placeholder=""
                                onIonChange={(e) => {
                                  handleChange("dogum_yili", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>

                            <IonItemDivider>Teslim Km</IonItemDivider>
                            <IonItem>
                              <IonInput
                                disabled
                                placeholder=""
                                value={form.outKm}
                                onIonChange={(e) => {
                                  handleChange("kabisKm", e.detail.value);
                                }}
                              ></IonInput>
                            </IonItem>
                          </div>
                        )}
                      </IonList>
                    </IonContent>
                  </IonContent>
                )}
              </>
            )}
            <IonFooter>
              {step === "form" && (
                <div className={"d-flex justify-content-end py-2 pr-4"}>
                  {atfError && (
                    <IonButton
                      color="danger"
                      className="mr-3"
                      onClick={() => {
                        save(signEmployeeImg?.name);
                      }}
                    >
                      Yine de bitir
                    </IonButton>
                  )}
                  <IonButton
                    onClick={() => {
                      submit();
                    }}
                  >
                    {onlyDelivery === true
                      ? "ÖDEME SAYFASINA GİT"
                      : "TESLİMATI TAMAMLA"}{" "}
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              )}

              {step === "image" && (
                <div className={"d-flex justify-content-end py-2 px-4"}>
                  <IonButton
                    className="mr-3"
                    color="medium"
                    onClick={() => {
                      setStep("form");
                    }}
                  >
                    <IonIcon icon={chevronBackOutline} /> Geri
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      submit();
                    }}
                  >
                    Kabis Bilgilerini Doldur{" "}
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              )}

              {step === "pay" && (
                <div className={"d-flex justify-content-end py-2 px-4"}>
                  <IonButton
                    className="mr-3"
                    color="medium"
                    onClick={() => {
                      setStep("form");
                    }}
                  >
                    <IonIcon icon={chevronBackOutline} /> Geri
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      submit();
                    }}
                  >
                    {onlyDelivery === true
                      ? "Fotoğraf Yükle"
                      : "Fotoğraf Yükle"}{" "}
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              )}
              {step === "kabis" && (
                <div className={"d-flex justify-content-end py-2 px-4"}>
                  <IonButton
                    onClick={() => {
                      kabis(selected);
                    }}
                  >
                    Teslimatı Bitir <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              )}
            </IonFooter>
          </>
        ) : (
          <IonContent>
            <div className="p-3">
              <div className="bg-danger p-3 rounded d-flex justify-content-center">
                <div className="d-flex align-items-center text-white">
                  <IonIcon size={"large"} icon={alertCircleOutline} />
                  <strong className="ml-2">
                    {" "}
                    Rezervasyon yok veya hata oluştu
                  </strong>
                </div>
              </div>
            </div>
          </IonContent>
        )}
      </IonPage>
    );
}
