import React from 'react';
import babyChair from '../assets/extraIcons/baby_chair.svg';
import unlimitedKm from '../assets/extraIcons/unlimited_km.svg';
import additionalDriver from '../assets/extraIcons/additional_driver.svg';
import miniInsurance from '../assets/extraIcons/mini_insurance.svg';
import defaultIcon from '../assets/extraIcons/default_icon.svg';
export default function ExtraIconFounder({code}){

    return (
        <>
            {code === "baby_chair" ? (
                <img src={babyChair} style={{width: '20px', height: '20px'}} className="mr-1"/>
            ) : code === "unlimited_km" ? (
                <img src={unlimitedKm} style={{width: '20px', height: '20px'}} className="mr-1"/>
            ) : code === "young_driver" ?  (
                <img src={additionalDriver} style={{width: '20px', height: '20px'}} className="mr-1"/>

            ) : code === "mini_insurance" ? (
                <img src={miniInsurance} style={{width: '20px', height: '20px'}} className="mr-1"/>

            ) : code === "additional_driver" ? (
                <img src={additionalDriver} style={{width: '20px', height: '20px'}} className="mr-1"/>

            ) : (
                <img src={defaultIcon} style={{width: '20px', height: '20px'}} className="mr-1"/>

            )}
        </>
    )
}
