export const storage = {
    get,
    set,
    remove
}

function get(key) {
    return JSON.parse(localStorage.getItem(key));
}

function set(key, value) {
    localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : value);
}

function remove(key) {
    localStorage.removeItem(key);
}