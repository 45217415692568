
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import {cameraOutline, checkmarkDoneOutline, closeCircleOutline} from "ionicons/icons";


import {
    IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,

    IonContent, IonFab, IonFabButton, IonIcon, IonLoading, IonModal, IonThumbnail,

} from "@ionic/react";

import React, {useEffect, useState} from "react";
import {
    alertCircleOutline,
    checkmarkOutline,  ellipseOutline,
} from "ionicons/icons";
import base64toBlob from "../lib/base64toBlob";
import {useApi} from "../providers/LaravelApiProvider";
import ImageGallery from "./ImageGallery";


function ImageUploadPage({onChange, defaultValues, active, onChangeCard, reservation, onlyDelivery, onlyReturn, onChangePassportPhoto}) {

    const {api} = useApi();
    let [activeCard, setActiveCard] = useState(null);
    let [oldImages, setOldImages] = useState([]);
    let [loading, setLoading] = useState(false);
    let [photos, setPhotos] = useState([
        {id: 1, name: null, type: null, status: false },
        {id: 2, name: null, type: null, status: false },
        {id: 3, name: null, type: null, status: false },
        {id: 4, name: null, type: null, status: false },
        {id: 5, name: null, type: null, status: false },
        {id: 6, name: null, type: null, status: false },
        {id: 7, name: null, type: null, status: false },
        {id: 8, name: null, type: null, status: false },
        {id: 9, name: null, type: null, status: false },
        {id: 10, name: null, type: null, status: false },
        {id: 11, name: null, type: null, status: false },
        {id: 12, name: null, type: null, status: false },
        {id: 13, name: null, type: null, status: false },
        {id: 14, name: null, type: null, status: false },
        {id: 15, name: null, type: null, status: false },
        {id: 16, name: null, type: null, status: false },
        {id: 17, name: null, type: null, status: false },
        {id: 18, name: null, type: null, status: false },
        {id: 19, name: null, type: null, status: false },
        {id: 20, name: null, type: null, status: false }
    ]);

    let [galleryDialog, setGalleryDialog] = useState({open: false, selected: null});
    let [cardButtonDisable, setCardButtonDisable] = useState({tc: false, passport: false});

    useEffect(() => {
        setActiveCard(active);
        setLoading(true);
        if(reservation.identity_number){
            api.get('user/identity/' + reservation.identity_number).then(resp => {
                if(onlyDelivery === false){
                    if(resp.Type === "foreign"){
                        setActiveCard("passportCard");
                        onChangeCard("passportCard");
                        setCardButtonDisable({tc: true, passport: false})
                    }else if(resp.Type === "person" || resp.Type === "company"){
                        setActiveCard("tcCard");
                        onChangeCard("tcCard");
                        setCardButtonDisable({tc: false, passport: true})
                    }
                }
                setLoading(false);
            }).finally(() => setLoading(false))
        }else if(reservation.tax_number){
            // api.get('user/identity/' + reservation.tax_number).then(resp => {
            //     if(onlyDelivery === false){
            //         if(resp.Type === "foreign"){
            //             setActiveCard("passportCard");
            //             onChangeCard("passportCard");
            //             setCardButtonDisable({tc: true, passport: false})
            //         }else if(resp.Type === "person" || resp.Type === "company"){
            //             setActiveCard("tcCard");
            //             onChangeCard("tcCard");
            //             setCardButtonDisable({tc: false, passport: true})
            //         }
            //     }
            // })
            if(onlyDelivery === false){
                setActiveCard("tcCard");
                onChangeCard("tcCard");
                setCardButtonDisable({tc: false, passport: true})
            }
            setLoading(false)
        }
        setLoading(false)

        // if(reservation.reservation_activities.length > 0){
        //
        // }

        api.get('reservation/delivery_image/' + reservation.id).then(resp => {
            setOldImages(resp.data);
        }).finally(() => {

        })

        if(defaultValues.length > 0){
            setPhotos(defaultValues);
        }
    },[active, defaultValues])

    function onClickCard(value) {
        setActiveCard(value);
        onChangeCard(value);

        if(value === "tcCard"){
            let newPhotoArray = photos.map(item => {
                if(item.type === "passport"){
                    let newItem = item;
                    newItem.status = false;
                    newItem.name = null;
                    newItem.type = null;

                    return newItem;
                }else{
                    return item;
                }
            })
            setPhotos(newPhotoArray);
            onChange(newPhotoArray);
        }else if(value === "passportCard"){
            console.log("passport kartı seçildi");
            let newPhotoArray = photos.map(item => {
                if(item.type === "tc"){
                    let newItem = item;
                    newItem.status = false;
                    newItem.name = null;
                    newItem.type = null;

                    return newItem;
                }else{
                    return item;
                }
            })
            setPhotos(newPhotoArray);
            onChange(newPhotoArray);
            console.log(newPhotoArray, 'new array');
        }
    }


    function onChangeIC(uploadedData) {

        if(uploadedData.type === "passport"){

            let driver = reservation.drivers[0];
            if(driver.name === reservation.name){
                setLoading(true);
                Promise.all([
                    api.post('mobileapp/driver/' + driver.id ,{identity_number: 22222222222}),
                    api.post('mobileapp/user/' + reservation.id ,
                        {
                            identity_number: 22222222222,
                            tax_number: "",
                            tax_office: ""
                        })
                ]).then(resp => {
                    setLoading(false);
                    onChangePassportPhoto();
                }).catch(() => {
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })
            }else{
            }
        }

         let oldPhotos = photos;
         let uploaded = oldPhotos.map(item => {
             if(uploadedData.id === item.id){
                 let newItem = item;
                 newItem.id = uploadedData.id;
                 newItem.status = uploadedData.status;
                 newItem.type = uploadedData.type;
                 newItem.name = uploadedData.name;
                 return newItem;
             }else{
                 return item;
             }
         })

        setPhotos(uploaded);
        onChange(uploaded);
    }

    function openGalleryDialog(open, selected) {
        setGalleryDialog({open: open, selected: selected});
    }

    return (
        <IonContent>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            <div className="px-4 my-2">
                <div className="d-flex justify-content-center border border-warning rounded p-1 text-center">
                <span className="text-warning"><IonIcon icon={alertCircleOutline} size="medium"/>
                    &nbsp; Kişinin kimlik, pasaport ve araç fotoğraflarını aşağıda belirtilen alanlara yükleyiniz.
                    Kimlik veya pasaport fotoğrafı yüklemek için ilgili kartlara tıklayınız </span>
                </div>
            </div>

            {oldImages.length > 0 && (
                <>
                    <div className="d-flex justify-content-center">
                        <IonCardTitle>Yüklenen Resimler</IonCardTitle>
                    </div>

                    <div className="d-flex justify-content-center">
                        {oldImages.map((item, idx) => (
                            <IonThumbnail onClick={() => openGalleryDialog(true, item)} className="mr-2 border border-secondary" key={"old-images" + idx}>
                                <img src={item} />
                            </IonThumbnail>
                        ))}
                    </div>
                    <IonModal onDidDismiss={() => {openGalleryDialog(false, null,)}} isOpen={galleryDialog.open} cssClass='my-custom-class'>
                       <IonContent>
                           <div className="p-3">
                               <div className="d-flex justify-content-between align-items-center">
                                   <strong className="h4">Yüklenen Resimler</strong>
                                   <IonIcon className={"text-primary"} size={"large"} icon={closeCircleOutline} onClick={() => {openGalleryDialog(false, null)}} />
                               </div>
                               <hr/>
                           </div>
                           <div>
                               <ImageGallery className="pt-1 px-3 d-flex justify-content-center"  selected={galleryDialog.selected}/>
                           </div>
                       </IonContent>
                        <IonButtons className="p-2 d-flex justify-content-end">
                            <IonButton color="medium" onClick={() => {openGalleryDialog(false, 0,[])}}>Kapat</IonButton>
                        </IonButtons>
                    </IonModal>
                </>
            )}

            <div className="row px-3 mt-2">
                <div className="col-md-6 col-12">
                    <IonFab vertical="top" horizontal="end" slot="fixed" >
                        <IonFabButton disabled={cardButtonDisable.tc} color={activeCard === "tcCard" ? "success" : "light"} onClick={() => onClickCard("tcCard")} >
                            <IonIcon icon={activeCard === "tcCard" ? checkmarkOutline : ellipseOutline} />
                        </IonFabButton>
                    </IonFab>
                    <IonCardTitle className="ml-2">Kimlik Fotoğrafları</IonCardTitle>
                    <IonCardSubtitle className="ml-2">(Yerli)</IonCardSubtitle>

                    <IonCard disabled={activeCard === "tcCard" ? false : true}>
                        <IonCardHeader>
                            <small>Formdaki TC No'ya sahip kimlik fotoğrafını yükleyiniz.</small>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="row">
                                <ImageCard label="Ön Yüz" className="col-6" id={1} type={"tc"} defaultValue={photos[0]?.status} onChange={onChangeIC} />
                                <ImageCard label="Arka Yüz" className="col-6" id={2} type={"tc"} defaultValue={photos[1]?.status} onChange={onChangeIC} />
                            </div>
                        </IonCardContent>
                    </IonCard>

                </div>

                <div className="col-md-6 col-12">
                    <IonFab vertical="top" horizontal="end" slot="fixed">
                        <IonFabButton disaabled={cardButtonDisable.passport} color={activeCard === "passportCard" ? "success" : "light"} onClick={() => onClickCard("passportCard")} >
                            <IonIcon icon={activeCard === "passportCard" ? checkmarkOutline : ellipseOutline} />
                        </IonFabButton>
                    </IonFab>
                    <IonCardTitle className="ml-2">Pasaport Fotoğrafları</IonCardTitle>
                    <IonCardSubtitle className="ml-2">(Yabancı)</IonCardSubtitle>

                    <IonCard onClick={() => setActiveCard("passportCard")} disabled={activeCard === "passportCard" ? false : true}>
                        <IonCardHeader>
                            <small>Yabancı müşteriler için bu alanı kullanınız</small>

                        </IonCardHeader>
                        <IonCardContent>
                            <div className="row">
                                <ImageCard label="Fotoğraf 1" className="col-6" id={3} type={"passport"} defaultValue={photos[2]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Fotoğraf 1" className="col-6" id={4} type={"passport"} defaultValue={photos[3]?.status} onChange={onChangeIC}/>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            </div>
            <div className="row px-3">
                <div className="col-12">
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>Araç Fotoğrafları</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="row">
                                <ImageCard label="Önden" className="col-6 col-md-3" id={5} type={"vehicle"} defaultValue={photos[4]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Yandan 1" className="col-6 col-md-3" id={6} type={"vehicle"} defaultValue={photos[5]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Yandan 2" className="col-6 col-md-3" id={7} type={"vehicle"} defaultValue={photos[6]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Arkadan" className="col-6 col-md-3" id={8} type={"vehicle"} defaultValue={photos[7]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Araç Gösterge" className="col-6 col-md-3" id={9} type={"vehicle"} defaultValue={photos[8]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 1" className="col-6 col-md-3" id={10} type={"vehicle"} defaultValue={photos[9]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 2" className="col-6 col-md-3" id={11} type={"vehicle"} defaultValue={photos[10]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 3" className="col-6 col-md-3" id={12} type={"vehicle"} defaultValue={photos[11]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 3" className="col-6 col-md-3" id={13} type={"vehicle"} defaultValue={photos[12]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 4" className="col-6 col-md-3" id={14} type={"vehicle"} defaultValue={photos[13]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 5" className="col-6 col-md-3" id={15} type={"vehicle"} defaultValue={photos[14]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 6" className="col-6 col-md-3" id={16} type={"vehicle"} defaultValue={photos[15]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 7" className="col-6 col-md-3" id={17} type={"vehicle"} defaultValue={photos[16]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 8" className="col-6 col-md-3" id={18} type={"vehicle"} defaultValue={photos[17]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 9" className="col-6 col-md-3" id={19} type={"vehicle"} defaultValue={photos[18]?.status} onChange={onChangeIC}/>
                                <ImageCard label="Diğer 10" className="col-6 col-md-3" id={20} type={"vehicle"} defaultValue={photos[11]?.status} onChange={onChangeIC}/>
                            </div>
                            {/*<div className="d-flex flex-wrap">*/}

                            {/*    <ImageCard label="Araç İçi 1" className="flex-fill mr-2 w-25" id={9} type={"vehicle"} defaultValue={photos[8]?.status} onChange={onChangeIC}/>*/}
                            {/*    <ImageCard label="Araç İçi 2" className="flex-fill mr-2 w-25" id={10} type={"vehicle"} defaultValue={photos[9]?.status} onChange={onChangeIC}/>*/}
                            {/*    <ImageCard label="Araç İçi 3" className="flex-fill mr-2 w-25" id={11} type={"vehicle"} defaultValue={photos[10]?.status} onChange={onChangeIC}/>*/}
                            {/*    <ImageCard label="Araç İçi 4" className="flex-fill mr-2 w-25" id={12} type={"vehicle"} defaultValue={photos[11]?.status} onChange={onChangeIC}/>*/}

                            {/*</div>*/}
                        </IonCardContent>
                    </IonCard>
                </div>
            </div>
        </IonContent>
    )
}

function ImageCard({label, type, id, className, onChange, defaultValue}) {

    const {photo, takePhoto} = usePhotoGallery();
    const {api} = useApi();

    let [loading, setLoading] = useState(false);


    useEffect(() => {

        if(photo){
            uploadPhoto(photo);
        }else if(photo === null){
        }
    },[photo])

    function uploadPhoto(photoData){
        let blobData = base64toBlob(photoData.dataUrl);
        let url = URL.createObjectURL(blobData);

        let img = new Image();
        img.src = url;

        let data = new FormData();
        data.append('file',blobData,'newImage.png');

        setLoading(true);
        api.upload('upload',data).then(resp => {
            setLoading(false);
            let uploadedData = {
                id: id,
                status: true,
                name: resp.data.name,
                type: type
            }
            onChange(uploadedData);
        }).catch(err => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }


    return(
        <>
        <IonLoading
            cssClass='my-custom-class'
            isOpen={loading}
            message={'Yükleniyor...'}
        />
        <div className={className} onClick={() => takePhoto()}>
            <div className="image-card d-flex justify-content-center align-items-center">

                {photo || defaultValue === true ? (
                    <strong className="text-success">Fotoğraf Yüklendi <IonIcon icon={checkmarkDoneOutline}/></strong>
                ) : (
                    <IonIcon size={"large"} icon={cameraOutline}/>
                )}

            </div>
            <div className="d-flex justify-content-center my-2">
                <span>{label}</span>
            </div>

        </div>
        </>
    )
}

export default ImageUploadPage;


