import {
    IonButton,
    IonButtons, IonCheckbox,
    IonContent,
    IonIcon, IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonBadge
} from "@ionic/react";
import {
    addOutline, arrowBackOutline,
    closeCircleOutline, createOutline,
    trashOutline
} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {useApi} from "../providers/LaravelApiProvider";
import DriverAddEditDialog from "./DriverAddEditDialog";
import CurrencySelector from "./CurrencySelector";
import money from "../utils/money";
import ConfirmDialog, {OtherIncomeAddEditConfirm} from "./ConfirmDialog";
import AlertDialog from "./AlertDialog";

function ExtrasDialog({onlyDelivery, onlyReturn, reservation, show, onClose}){
    const {api} = useApi();
    let [extras, setExtras] = useState([]);
    let [loading, setLoading] = useState(false);
    let [driverAddEdit, setDriverAddEdit] = useState({open: false, extra_id: null});
    let [pageType, setPageType] = useState(null);
    let [currencyList, setCurrencyList] = useState([]);
    let [otherIncomeList, setOtherIncomeList] = useState([]);
    let [selectedIncome, setSelectedIncome] = useState(null);
    let [driverData, setDriverData] = useState(null);

    let [confirm, setConfirm] = useState(false);


    useEffect(() => {
        load();
    },[show])

    async function load(){
        let defaultExtras = reservation.extras;
        if(onlyDelivery === true && onlyReturn === false){
            setPageType("extra");
            setLoading(true);
            await Promise.all([
                api.post("extra/search", reservation),
                api.get("payment_activity/fake_current/" + reservation.id)
            ]).then(resp => {
                let filteredData = resp[0].filter(x => x.extra_type === 0);
                let extrasData = filteredData.map((item) => {
                    let isHave = Boolean(defaultExtras.find(x => x.extra_id === item.id));
                    return {
                        id: item.id,
                        name: item.name.tr,
                        price: item.price * reservation.exchange_rate,
                        quantity: 1,
                        isChecked: isHave,
                        code: item.code,
                        isDefault: isHave,
                        description: item.code === "additional_driver" ? reservation.drivers[1]?.name : null
                    }
                })

                setExtras(extrasData);
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })

        }else if(onlyDelivery === false && onlyReturn === true){
            setPageType("other");
            setLoading(true);

            await Promise.all([
                api.get("other_income/show/" + reservation.id),
                api.get("payment_activity/fake_current/" + reservation.id),
                api.get("extra",{type: 1}),
                api.post("extra/search", reservation),

            ]).then(resp => {

                if(resp[0].length > 0) {
                    let data = resp[0];

                    let extrasData = data.map((item, idx) => {
                        return {
                            id: item.extra_id,
                            name: item.extra.name.tr ,
                            price: money(Number(item.price) * Number(reservation.exchange_rate)),
                            quantity: 1,
                            isChecked: false,
                            code: item.extra.code,
                            isDefault: true,
                            sortNumber: idx + 1,
                            income_id: item.id
                        }
                    })
                    setExtras(extrasData);
                }else{
                    setExtras([]);
                }
                setCurrencyList(resp[1]);
                //let filteredData = data.filter(x => x.extra_type === 1);
                setOtherIncomeList(resp[2].data);

                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })

        }else{
        }
    }

    function onChangeCheck(e, id, code){
        if(code === "additional_driver"){
            if(e.detail.checked === true){
                setDriverAddEdit({open: true, extra_id: id});
                return false;
            }else{
                let oldExtras = extras;
                let newExtras = oldExtras.map((item) => {
                    if(item.id === id){
                        let newItem = item;
                        newItem.isChecked = e.detail.checked;
                        newItem.description = null
                        return newItem;
                    }else{
                        return item;
                    }
                })
                setExtras(newExtras);
                setDriverData(null);
            }
        }else{
            let oldExtras = extras;
            let newExtras = oldExtras.map((item) => {
                if(item.id === id){
                    let newItem = item;
                    newItem.isChecked = e.detail.checked;
                    newItem.description = null
                    return newItem;
                }else{
                    return item;
                }
            })
            setExtras(newExtras);
        }

    }

    function onQuantityChange(quantity, id) {
        let oldExtras = extras;
        let newExtras = oldExtras.map((item) => {
            if(item.id === id){
                let newItem = item;
                newItem.quantity = quantity;
                return newItem;
            }else{
                return item;
            }
        })
        setExtras(newExtras);
    }

    function onDriverAddEditChange(extra_id, selectedDriver) {

        let oldExtras = extras;
        let newExtras = oldExtras.map((item) => {
            if(item.id === extra_id){
                let newItem = item;
                newItem.isChecked = true;
                newItem.description = selectedDriver.name

                return newItem;
            }else{
                return item;
            }
        })

        setExtras(newExtras);
        setDriverData(selectedDriver);
    }

    function addExtra(){
        let filtered = extras.filter(x => x.isChecked === true);
        let haveAdditionalDriver = Boolean(filtered?.filter(x => x.code === "additional_driver")?.length > 0);

        let newExtras = filtered.map((item) => {
             return item.id;
        })

        let newPrices =  filtered.map((item) => {
            return item.price * item.quantity
        })

        let params = {
            extras: newExtras,
            prices: newPrices
        }

        setLoading(true);
        api.post("mobileapp/updateExtra/" + reservation.id, params).then((resp) => {
            setLoading(false);
            if(haveAdditionalDriver && reservation.drivers?.length < 2){
              addDriver(driverData);
            }
            closeMainModal();
        }).catch((err) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })

    }

    function addDriver(driver){
        if(driver){
            setLoading(true);
            api.post("mobileapp/driver/add/" + reservation.id, driver).then((resp) => {
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        }else{
            return false;
        }

    }

    function plusInputArea(item){
        let newData = {
            id: item.id,
            name: item.name,
            price: "",
            quantity: 1,
            isChecked: false,
            code: item.code,
            isDefault: false,
            sortNumber: item.sortNumber + 1,
            income_id: null
        }

        let newExtras = [...extras, newData];
        setExtras(newExtras);
    }

    function minusInputArea(item){
        let founded = extras.findIndex(x => x.id === item.id && x.sortNumber === item.sortNumber);
        let oldExtras = extras;
        if(founded > -1){
            oldExtras.splice(founded,1);
            let newExtras = [...oldExtras];
            setExtras(newExtras);
        }
    }

    function onIncomeSelect(item){
        setSelectedIncome(item);
        let filtered = extras.filter(x => x.id === item.id);



        let newData = {
            id: item.id,
            name: item.name.tr,
            price: "",
            quantity: 1,
            isChecked: false,
            code: item.code,
            isDefault: false,
            sortNumber: filtered.length === 0 ? 1 : filtered.length,
            income_id: null
        }


        let newExtras = [...extras, newData];
        setExtras(newExtras);
    }

    function onListItemChange(){
        load();
    }

    function closeMainModal(){
        onClose();
        setExtras([]);
    }


    return(
        <>
        <IonModal isOpen={show} onDidDismiss={() => closeMainModal()} cssClass='my-custom-class'>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            <IonContent>
                {pageType === "extra" && (
                    <div className="">
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <strong className="h4">Ekstralar</strong>
                                <IonIcon className={"text-primary"} size={"large"} icon={closeCircleOutline} onClick={() => {closeMainModal()}} />
                            </div>
                            <hr className="bg-secondary"/>
                        </div>

                        <div className="d-flex justify-content-between align-items-center px-3 ">
                            <IonCheckbox checked={true} disabled={true} />
                            <IonLabel className="text-muted">Sınırsız Km (Ücretsiz)</IonLabel>
                            <div></div>
                        </div>
                        <hr/>

                        {extras.length > 0 && extras.map((item, idx) => (
                            <>
                                <div key={"extra-dialog-item" + idx} className="d-flex justify-content-between align-items-center px-3 ">
                                    <IonCheckbox checked={item.isChecked} disabled={item.isDefault} onIonChange={(e) =>onChangeCheck(e, item.id, item.code)} />
                                    <div className="d-flex flex-column">
                                        <IonLabel className={item.isDefault ? "text-muted" : ""}>
                                            {item.name} ({money(item.price * item.quantity)} {reservation.currency.icon})
                                        </IonLabel>
                                        {item.description && (
                                            <IonBadge
                                                className="mt-1 p-2"
                                                color="medium"
                                                onClick={() =>{
                                                    if(item.isDefault){
                                                        return false;
                                                    }else{
                                                        setDriverAddEdit({open: true, extra_id: item.id})
                                                    }
                                                }}>
                                                {item.description}
                                                <IonIcon className="ml-2" icon={createOutline}/>
                                            </IonBadge>)}
                                    </div>

                                    {item.isChecked === true && item.code === "baby_chair" && item.isDefault === false ? (
                                        <QuantitySelector id={item.id} value={item.quantity} onChange={onQuantityChange}/>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <hr/>
                            </>
                        ))}
                        <DriverAddEditDialog
                            show={driverAddEdit}
                            reservation={reservation}
                            onDriverAddEditChange={onDriverAddEditChange}
                            onClose={() => setDriverAddEdit({open: false, extra_id: null})}
                            defaultDriverData={driverData}
                        />

                    </div>
                )}

                {pageType === "other" && (
                    <div className="">
                        <div className="px-3 pt-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <strong className="h4">Diğer Gelirler</strong>
                                <IonIcon className={"text-primary"} size={"large"} icon={closeCircleOutline} onClick={() => {closeMainModal()}} />
                            </div>
                            <hr className="bg-secondary"/>
                        </div>
                        <IonItem className="border border-light m-3" >
                            <IonLabel>Diğer Gelir Seç</IonLabel>
                            <IonSelect okText="Ekle" cancelText="İptal" value={selectedIncome} placeholder="Seç" onIonChange={e => onIncomeSelect(e.detail.value)}>
                                {otherIncomeList.length > 0 && otherIncomeList.map((item, idx) => (
                                    <>
                                        {extras.findIndex(x => x.id === item.id) < 0 && (
                                            <IonSelectOption key={"select-option-income" + idx} value={item}>{item.name.tr}</IonSelectOption>
                                        )}
                                    </>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <span style={{fontSize: "14px"}} className="text-warning">*Tahsilat tutarı girerken lütfen <strong>girilen miktarı</strong> kontrol ediniz ve emin olunuz.</span>
                        </IonItem>
                        <IonItem>
                            <span style={{fontSize: "14px"}} className="text-warning">* Diğer gelirleri <strong>TL</strong> giriniz.</span>
                        </IonItem>
                        <div className="mt-3">
                            {extras.length > 0 ? extras.map((item, idx) => (
                                <>
                                    <ListItem
                                        key={"extra-list-item" + idx}
                                        currencyList={currencyList}
                                        keyData={"extra-list-item" + idx}
                                        reservation={reservation}
                                        item={item}
                                        onChange={onListItemChange}
                                        plusInputArea={plusInputArea}
                                        minusInputArea={minusInputArea}
                                        allExtras={extras}
                                    />
                                </>
                            )) : (
                                <div className="d-flex justify-content-center align-items-center p-3">
                                    <p>Diğer gelir bulunamadı</p>
                                </div>

                            )}
                        </div>


                    </div>
                )}
            </IonContent>
            <IonButtons className="p-2 d-flex justify-content-end">
                <IonButton color="medium" onClick={() => {closeMainModal()}}>Kapat</IonButton>
                {onlyDelivery === true && (
                    <IonButton disabled={!Boolean(extras.filter(x => x.isChecked === true && x.isDefault === false)?.length > 0)} color="primary" onClick={() => {setConfirm(true)}}>Ekle</IonButton>
                )}
            </IonButtons>
        </IonModal>
        <ConfirmDialog
            show={confirm}
            title={"Seçilen ekstralar eklensin mi?"}
            message={"Ekledikten sonra form üzerindeki ekstralarda değişiklik yapamazsınız."}
            onClose={(value) => {
                if(value === true) {
                    addExtra();
                }
                setConfirm(false);
            }}/>
        </>
    )
}

function QuantitySelector({value, id, onChange}) {

    let [quantity, setQuantity] = useState(1);

    useEffect(() => {
        setQuantity(value);
    },[value])

    function changeQuantity(type) {
      if(type === "plus"){
          let newQuantity = quantity + 1;
          setQuantity(newQuantity);
          onChange(newQuantity, id)
      }else if(type === "minus"){
          let newQuantity = quantity - 1;
          if(newQuantity < 1){
              return false
          }else{
              setQuantity(newQuantity);
              onChange(newQuantity, id)
          }
      }
    }

    return (
        <div className="d-flex justify-content-start align-items-center">
            <IonButton onClick={() => changeQuantity("minus")} color="medium" size={"small"}>-</IonButton>
            <div className="px-2 border ">{quantity}</div>
            <IonButton onClick={() => changeQuantity("plus")} size={"small"}>+</IonButton>
        </div>
    )
}

function ListItem({keyData, item, plusInputArea, reservation, onChange, currencyList, minusInputArea, allExtras}){

    const {api} = useApi();
    let [price, setPrice] = useState(null);
    let [currency, setCurrency] = useState(null);
    let [loading, setLoading] = useState(false);
    let [confirm, setConfirm] = useState(false);
    let [addUpdateConfirm, setAddUpdateConfirm] = useState({show: false, data: null});
    let [willDeleteValue, setWillDeleteValue] = useState(null);
    let [isEdit, setIsEdit] = useState(false);
    let [isDelete, setIsDelete] = useState(false);
    let [alertDialog, setAlertDialog] = useState({show: false, message: null});
    let [defaultCurrency, setDefaultCurrency] = useState(null);

    useEffect(() => {
        setPrice(item.price);
        setCurrency(reservation.currency);
        setIsEdit(!item.isDefault);

    },[item,currencyList])

    function onChangeInput(value){
        setPrice(value)
    }

    function onChangeCurrency(value){
        setCurrency(value);
    }

    function addOtherIncomeConfirm(item, price){

        if(Number(price) === 0){
            setAlertDialog({
                show: true,
                message: 'Diğer gelir "0" girilemez!'
            })
            return false;
        }


        setAddUpdateConfirm({
            show: true,
            data: {
                item: item,
                price: price,
                currency: defaultCurrency
            }
        })
    }

    function addOtherIncome(data, value){

        let item = data.item;

        if(value === true) {
            if(item.income_id){
                let params = {
                    price: Number(price),
                    currency_id: 1,
                }

                setLoading(true);
                api.post('other_income/update/' + item.income_id, params).then(resp => {
                    onChange()
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })

            }else{
                let params = {
                    extra_id: Number(item.id),
                    price: Number(price),
                    currency_id: 1,
                }


                setLoading(true);
                api.post('other_income/store/' + reservation.id, params).then(resp => {
                    onChange()
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })
            }
        }

        setAddUpdateConfirm({
            show: false,
            data: null
        })

    }

    function deleteOtherIncome(item){
        setConfirm(true);
        setWillDeleteValue(item);
    }

    function onConfirmClose(value){
       if(value === true) {
           if(item.income_id === null) {
               minusInputArea(item);
           }else{
               setLoading(true);
               api.get('other_income/delete/' + item.income_id).then(resp => {
                   if(resp === true){
                       onChange();
                   }
                   setLoading(false);
               }).finally(() => {
                   setLoading(false);
               })
           }
       }else{
           setWillDeleteValue(null);
       }

       setConfirm(false);
    }


    function onBlur(item){

    }

    function onFocus(item) {

    }


    return (
        <div key={keyData}>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            <AlertDialog show={alertDialog.show} message={alertDialog.message} onClose={() => setAlertDialog({show: false, message: null})}/>
            <ConfirmDialog onClose={onConfirmClose} show={confirm} message={"Diğer gelir silinsin mi?"}/>
            <OtherIncomeAddEditConfirm
                show={addUpdateConfirm.show}
                data={addUpdateConfirm.data}
                title={addUpdateConfirm?.data?.item?.income_id ? "Diğer gelir güncellensin mi?" :"Diğer gelir eklensin mi?"}
                onClose={(data, value) => addOtherIncome(data, value)}
            />
            <div className="bg-light border border-bottom my-2 py-2">
               <div className="d-flex justify-content-between align-items-center px-3 ">
                   <IonLabel className="ml-1">{item.name} : </IonLabel>
                   <IonInput  type="number" className="border border-secondary rounded ml-2 bg-white" onIonFocus={() => onFocus(item) } onIonBlur={() => onBlur(item)} value={price} placeholder="Tutar Giriniz(TL)" onIonChange={e => {onChangeInput(e.detail.value)}}/>

               </div>
               <div className="d-flex justify-content-end align-items-center px-3 mt-2">
                   {isEdit === true && (
                       <IonButton size="" color="danger" onClick={() => deleteOtherIncome(item)}><IonIcon icon={trashOutline}/>Sil</IonButton>
                   )}

                   <IonButton size="" color="light" onClick={() => plusInputArea(item)}><IonIcon icon={addOutline}/> Yeni</IonButton>
                   <IonButton color="primary"  onClick={() => {addOtherIncomeConfirm(item, price)}}>Kaydet</IonButton>
               </div>
           </div>
        </div>
    )
}

export default ExtrasDialog;
