import {IonInput, IonItem, IonLabel} from "@ionic/react";
import React, {useEffect, useState} from "react";


function Input({name, value, label, onChange, type, validation, className, errors}){

    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if(errors !==null){
           let foundError = Boolean(errors.find(x => x.name === name));
           if(foundError){
               let errorData = errors.find(x => x.name === name);
               setErrorMessage(errorData.message);
           }
        }

    },[errors])

    function onChangeInput(e) {
        onChange(e.target.name, e.target.value);
    }

    function onFocus() {
        setErrorMessage(null);
    }

    return (
        <IonItem className={className}>
            <IonLabel color="medium">{label}: </IonLabel><br />
            <IonInput type={type} value={value} name={name} onIonFocus={() => onFocus()}  onIonChange={(e) =>onChangeInput(e)}/>
            {errorMessage !== null && (
                <small className="text-danger"> {errorMessage} </small>
            )}
        </IonItem>
    );
}

export default Input;
