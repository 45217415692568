import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonButtons,
    IonContent, IonFab, IonFabButton,
    IonHeader,
    IonIcon,
    IonItem, IonLabel,
    IonSearchbar, IonSelect, IonSelectOption,
    IonToolbar
} from "@ionic/react";
import {logOutOutline, personCircle, createOutline, callSharp, chevronForwardOutline} from "ionicons/icons";
import {useApi} from "../providers/LaravelApiProvider";
import moment from "moment";

function ReservationManagerPage() {

    const {api, user} = useApi();
    let [reservations, setReservations] = useState([]);

    useEffect(() => {
        load()
    },[])

    function load() {
        api.get("mobileapp", {paginate: 999}).then(resp => {
            setReservations(resp.data);
        }).finally(() => {

        });
    }

    return (
        <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton>
                            <IonIcon  icon={personCircle} />
                            <span className="d-sm-block d-none"> {user.name}</span>
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end" className="clickable">
                        <IonButton color="primary" onClick={() => {}}>
                            <IonIcon slot="icon-only" icon={logOutOutline}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonSearchbar  placeholder="Rezervasyon Ara" onIonChange={($e => {

                })}/>
            </IonHeader>
            <IonHeader>
                <div className="px-3 py-2 bg-light d-flex justify-content-between">
                    <IonItem className="w-50">
                        <IonLabel>Teslimatçı Seç</IonLabel>
                        <IonSelect okText="Seç" cancelText="İptal" value={"all"} placeholder="Select One" onIonChange={(e) => {}}>
                            <IonSelectOption value="all">Hepsi</IonSelectOption>
                            <IonSelectOption value="hv">Harun Varıcı</IonSelectOption>
                            <IonSelectOption value="hk">Hakan Küçüksert</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </div>
            </IonHeader>
            {
                reservations.length > 0 && reservations.map((item, idx) => (
                    <div key={"reservation-manage-page" + idx} className="reservation-card my-3 ">
                        <div className="row px-3  py-2">
                            <div className="col-3 text-center py-2">
                                <img src={item.model.image} width={100} /><br/>
                                <span className="plaka">{item?.vehicle?.license_plate}</span><br/>
                                <span className="badge badge-warning mt-1">{item.code}</span>
                            </div>
                            <div className="col-8">
                                <div className="d-flex flex-column">
                                    <span>{item.name}</span>
                                    <span><IonIcon icon={callSharp} /> {item.phone}</span>
                                </div>

                                <div className="row d-flex align-items-center">
                                    <div className="col-5 d-flex flex-column mt-2">
                                        <strong>{moment(item.begin_date).format("DD/MM/YYYY (HH:mm)")}</strong>
                                        <small>{item.begin_location.name.tr}</small>
                                        <small className="text-muted">{item.begin_location.address}</small>
                                    </div>
                                    <div className="col-2 text-center">
                                        <IonIcon icon={chevronForwardOutline}/>
                                    </div>
                                    <div className="col-5 d-flex flex-column mt-2">
                                        <strong>{moment(item.end_date).format("DD/MM/YYYY (HH:mm)")}</strong>
                                        <small>{item.end_location.name.tr}</small>
                                        <small className="text-muted">{item.begin_location.address}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <IonFab horizontal="end" >
                                    <IonFabButton color="light" size="small">
                                        <IonIcon icon={createOutline} />
                                    </IonFabButton>
                                </IonFab>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="bg-light p-2">
                                <small>Teslimatçı: <strong>Test Teslimatçı</strong></small>
                            </div>
                        </div>
                    </div>

                ))
            }
        </IonContent>
    )
}

export default ReservationManagerPage;
