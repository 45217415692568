import React, {useEffect, useRef, useState} from "react";
import {IonContent, IonPage} from "@ionic/react";
import SignatureCanvas from '../components/SignatureCanvas';

export default function Sign() {

    const canvas = useRef(null);
    const [signImg, setSignImg] = useState(null);

    useEffect(() => {
        console.log(canvas, 'pfg');
    }, [])

    console.log(signImg,'signimg');

    return (
        <IonPage>
            <IonContent>

                <SignatureCanvas ref={canvas} penColor='black' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />

                <hr/>

                <img src={signImg?.original_image} alt="Orijinal İmza"/>

                <img src={signImg?.points_image} alt="Point İmza"/>

                <div>
                    <a href="#" onClick={($e) => {
                        $e.preventDefault();
                        let c = canvas.current.getDotAndSign();
                        setSignImg(c);
                    }} className="btn btn-primary btn-lg">Kaydet</a>
                </div>
            </IonContent>
        </IonPage>
    )
}
