import React, {useEffect, useState} from "react";


function ImageGallery({selected, className}){

    let [image, setImage] = useState(null);

    useEffect(() => {
       setImage(selected);
    },[selected])


    return (
            <div className={className}>
                <img className="border border-secondary" width={350} src={image} />
            </div>
    )
}

export default ImageGallery;
