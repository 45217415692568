import {
    IonButton,
    IonButtons,
    IonContent, IonIcon,
    IonItem,
    IonList, IonLoading,
    IonModal, IonSearchbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import Input from "./Input";
import validate from "../utils/driver_add_validate";
import DateInput from "./DateInput";
import {useApi} from "../providers/LaravelApiProvider";
import AlertDialog from "./AlertDialog";
import {checkmarkCircleOutline, checkmarkDoneOutline, closeOutline} from "ionicons/icons";

function DriverAddEditDialog({show, onClose, reservation, onDriverAddEditChange, defaultDriverData}) {

    const {api} = useApi();
    let [form, setForm] = useState(null);
    let [errors, setErrors] = useState(null);
    let [status, setStatus] = useState("add");
    let [searchResult, setSearchResult] = useState([]);
    let [searchText, setSearchText] = useState(null);
    let [selected, setSelected] = useState(null);
    let [loading, setLoading] = useState(false);
    let [alertDialog, setAlertDialog] = useState({show: false, message: null})

    useEffect(() => {
        let params = {
            full_name: defaultDriverData?.name,
            email: defaultDriverData?.email,
            phone: defaultDriverData?.phone,
            address: defaultDriverData?.address,
            identity_number: defaultDriverData?.identity_number,
            license_class: defaultDriverData?.license_type,
            license_date: defaultDriverData?.license_date,
            license_locate: defaultDriverData?.license_city,
            born_date: defaultDriverData?.birthday,
            license_number: defaultDriverData?.license_number
        }
        setForm(params);
    },[defaultDriverData, show])

    function onCloseModal() {
        //setForm({});
        setSearchText(null);
        setSearchResult([]);
        setErrors(null);
        onClose();
    }

    function onChange(key, value) {
        let oldForm = form;
        setForm({
            ...oldForm,
            [key]: value
        })
    }

    function searchUser(value) {
        if(value){
            setSearchText(value);
            api.get('user', {search: value}).then(resp => {
                setSearchResult(resp.data);
            })
        }else{
            setSearchResult([]);
            setSearchText(value);

        }
    }

    function onSelectUser(data) {
        let newForm = {
            full_name: data.name,
            phone: data.phone,
            identity_number: data.identity_number,
            email: data.email,
            born_date: data.birthday,
            license_date: data.license_date,
            license_number: data.license_number,
            license_locate: data.license_city,
            license_class: data.license_type,
            address: data.address
        }
        setStatus("add");
        setForm(newForm);

    }

    function submit() {
        let validateErrors = validate(form);

        if(validateErrors.length > 0){
            setErrors(validateErrors);
            return false;
        }else if(form.identity_number){
            setLoading(true);
            api.get('user/identity/' + form.identity_number).then(resp => {
                if(resp.Type === "person" || resp.Type === "foreign"){
                    save();
                }else{
                    setAlertDialog({
                        show: true,
                        message: "Geçerli bir TC giriniz"
                    })
                }
                setLoading(false);

            }).finally(() => {
                setLoading(false);
            })
        }else{
            save();
        }
    }

    function save(){
        let params = {
            name: form.full_name,
            email: form.email,
            phone: form.phone,
            address: form.address,
            identity_number: form.identity_number,
            license_type: form.license_class,
            license_date: form.license_date,
            license_city: form.license_locate,
            birthday: form.born_date,
            license_number: form.license_number
        }

        onDriverAddEditChange(show.extra_id, params);
        onCloseModal();
    }


    return(
        <IonModal isOpen={show.open} onDidDismiss={() => {onCloseModal()}}>
            <IonContent>
                <AlertDialog show={alertDialog.show} message={alertDialog.message} onClose={() => setAlertDialog({show: false, message: null})}/>

                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={loading}
                    onDidDismiss={() => {}}
                    message={'İşlem yapılıyor...'}
                />
                <div className="p-2">
                    <div className="d-flex p-2">
                        <IonButton block onClick={() => setStatus("search")} color={status === "search" ? "success" : "dark"} className="flex-fill">
                            Kişi Ara {status === "search" && (<IonIcon className="ml-2" icon={checkmarkCircleOutline}/>)}
                        </IonButton>
                        <IonButton block onClick={() => setStatus("add")} color={status === "add" ? "success" : "dark"} className="ml-2 flex-fill">
                            Yeni Ekle {status === "add" && (<IonIcon className={"ml-2"} icon={checkmarkCircleOutline}/>)}
                        </IonButton>
                    </div>

                </div>
                <hr/>

                {status === "search" && (
                    <div className="px-3 bg-light">
                        <div>
                            <h4>Yeni Sürücü Ara</h4>
                        </div>
                        <IonSearchbar placeholder="Kişi Ara..." value={searchText} onIonChange={e =>{searchUser(e.detail.value)}}/>
                        <IonList>
                        {searchResult.length > 0 && searchResult.map((item, idx) => (
                            <IonItem key={"search-result" + idx} button onClick={() => {onSelectUser(item)}}>{item.name}</IonItem>
                        ))}

                        </IonList>
                    </div>
                )}

                {status === "add" && (
                    <div className="px-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>Yeni Sürücü Ekle</h4>
                            <IonButton color="danger" fill="outline" size={"small"} onClick={() => setForm({})} >Formu Temizle <IonIcon icon={closeOutline}/></IonButton>
                        </div>
                        <div className="p-2">
                            <Input className={"mb-2 text-left"} name={"full_name"} value={form?.full_name} label={"Ad Soyad"} type={"text"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"phone"} value={form?.phone} label={"Telefon"} type={"tel"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"identity_number"} value={form?.identity_number} type={"number"} label={"TC No"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"email"} value={form?.email} label={"E-Posta"} errors={errors} onChange={onChange}/>
                            <DateInput className={"mb-2"} name={"born_date"} value={form?.born_date} label={"Doğum Tarihi"} errors={errors} onChange={onChange}/>
                            <DateInput className={"mb-2"} name={"license_date"} value={form?.license_date} label={"Ehliyet V. Tarihi"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"license_number"} value={form?.license_number} label={"Ehliyet Numarası"} type={"text"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"license_locate"} value={form?.license_locate} label={"Ehliyet V. Yeri"} type={"text"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"license_class"} value={form?.license_class} label={"Ehliyet Sınıfı"} type={"text"} errors={errors} onChange={onChange}/>
                            <Input className={"mb-2 text-left"} name={"address"} value={form?.address} label={"Adres"} type={"text"} errors={errors} onChange={onChange}/>
                        </div>

                    </div>
                )}

            </IonContent>
            <IonButtons className="p-2 d-flex justify-content-end">
                <IonButton color="medium" onClick={() => {onCloseModal()}}>Kapat</IonButton>
                <IonButton color="primary" onClick={() => {submit()}}>Sürücüyü Ekle</IonButton>
            </IonButtons>

        </IonModal>
    )
}

export default DriverAddEditDialog;
