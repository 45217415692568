import {
    IonContent,
    IonHeader, IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React from 'react';

export default function NotFound() {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Sayfa Bulunamadı</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <h1>404</h1>
            </IonContent>
        </IonPage>
    );
}