import {
    IonButton, IonButtons,
    IonCard,
    IonContent,
    IonHeader, IonIcon,
    IonInput, IonLabel, IonLoading,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useContext, useEffect, useState} from 'react';
import {ApiContext} from "../providers/LaravelApiProvider";
import Logo from "../assets/5scarrental.svg";
import AlertDialog from "../components/AlertDialog";
import DownloadApkButton from "../components/DownloadApkButton";

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    let [alertData, setAlertData] = useState({show: false, message: ""})

    /// test kullanıcı: app@teksenbilisim.com
    /// şifre: 123
    const {login, getVersion} = useContext(ApiContext);

    function closeAlertDialog() {
        setAlertData({
            show: false,
            message: ""
        })
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Giriş Yap</IonTitle>
                    <IonButtons slot="end">
                        <DownloadApkButton/>
                    </IonButtons>
                </IonToolbar>

            </IonHeader>
            <IonContent style={{background: 'blue', height: '100%'}}>

                <IonLoading
                    isOpen={loading}
                    message={'Giriş Yapılıyor...'}
                />

                <AlertDialog show={alertData.show} message={alertData.message} onClose={closeAlertDialog}/>

                <div className="d-flex ion-align-items-center ion-justify-content-center h-100">
                    <IonCard className="p-3" style={{width: 350}}>
                        <div className="p-3 text-center">
                            <img style={{height: 50}} src={Logo} alt=""/>
                        </div>

                        <ion-item>
                            <IonLabel position="floating">E-Posta</IonLabel>
                            <IonInput onIonChange={e => setEmail(e.detail.value)} value={email} clearInput/>
                        </ion-item>

                        <ion-item>
                            <IonLabel position="floating">Şifre</IonLabel>
                            <IonInput type="password" onIonChange={e => setPassword(e.detail.value)} value={password} clearInput/>
                        </ion-item>

                        <IonButton onClick={() => {
                            setLoading(true);
                            login(email, password).then(resp => {

                            }).catch(err => {

                            }).finally(() => {
                                setLoading(false);
                            });
                        }} className="mt-3" color="primary" expand={"full"}>Giriş Yap</IonButton>
                    </IonCard>
                </div>
            </IonContent>
            <p className="text-right pr-3">
                <small className="ion-color-light">{getVersion()}</small>
                <small></small>
            </p>
        </IonPage>
    );
}
