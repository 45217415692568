import React, {useEffect, useState} from "react";
import {IonAlert, IonButton, IonButtons, IonIcon, IonLoading, IonModal} from "@ionic/react";
import {useApi} from "../providers/LaravelApiProvider";
import {
    alertCircleOutline,
    callSharp,
    checkmarkDoneCircleOutline,
} from 'ionicons/icons';
import Input from "./Input";
import TCNoVerification from "tcno-verification/tcno-verification-dev";

function VerifyDialog({show,id, onClose, onChange, onlyDelivery, onlyReturn}) {

    const {api} = useApi();

    let [loading, setLoading] = useState(false);
    let [reservation, setReservation] = useState(null);
    let [findex, setFindex] = useState(false);

    let [activeIdentityEdit, setActiveIdentityEdit] = useState(false);
    let [activeDriverEdit, setActiveDriverEdit] = useState(false);

    let [identity, setIdentity] = useState(null);
    let [phone, setPhone] = useState(null);
    let [errors, setErrors] = useState([]);

    useEffect(() => {
        load();
    },[id])

    function load() {
        setLoading(true);
        api.get("mobileapp/" + id, {only_delivery: onlyDelivery, only_return: onlyReturn}).then(resp => {

            let data = resp.data[0];
            let isVerify = Boolean(data.drivers[0].verification_code === null);
            onChange(!isVerify);
            setReservation(resp.data[0]);
            setLoading(false);
            setFindex(Boolean(resp.data[0].findexStatus));
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    function close(){
        onClose();
        onChange(true);
    }

    function confirmInfo() {
        // setLoading(true);
        // let driver_id = reservation.drivers[0].id;
        // api.get("mobileapp/driver/verify/" + driver_id).then(resp => {
        //
        //     confirmCode(resp.verification_code);
        //
        // }).catch(() => {
        //     setLoading(false);
        // }).finally(() => {
        //     setLoading(false);
        // })

        confirmCode(458965);


    }

    function confirmCode(code) {
        // setLoading(true);
        // let driver_id = reservation.drivers[0].id;
        //
        // api.get("mobileapp/driver/verify/" + driver_id, {verification_code: code}).then(resp => {
        //     setLoading(false);
        //     onClose();
        //     onChange(resp.status);
        // }).catch(() => {
        //     setLoading(false);
        // }).finally(() => {
        //     setLoading(false);
        // })

        onClose();
        onChange(true);

    }

    function change(value){
        load();
    }

    function identityChange(key, value) {

        setIdentity(value);
    }

    function identityUpdate(id) {
        if(identity){
            if(!TCNoVerification(identity)){
                setErrors(
                    [{name: "identity_number",message: "Lütfen geçerli tc no giriniz"}]
                );
                return false
            }else{
                setLoading(true);
                api.post('mobileapp/user/' + id ,{identity_number: identity}).then(resp => {
                    setLoading(false);
                    load();
                    setActiveIdentityEdit(false);
                }).catch(() => {
                    setLoading(false);
                }).finally(() => {
                    setLoading(false);
                })
            }
        }else{
            setErrors(
                [{name: "identity_number",message: "Lütfen bu alanı doldurunuz"}]
            );
        }
    }

    function phoneChange(key, value) {
        setPhone(value);
    }

    function phoneUpdate(id) {
        if(!phone){
            return false;
        }else{
            setLoading(true);
            api.post('mobileapp/driver/' + id ,{phone: phone}).then(resp => {
                setLoading(false);
                load();
                setActiveDriverEdit(false);
            }).catch(() => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })
        }
    }


    return(
        <>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            <IonModal isOpen={show} cssClass=''>

                <div className="p-3 position-relative overflow-auto">
                    <h3>Onay</h3>
                    {findex === false ? (
                        <div className="bg-danger d-flex align-items-center justify-content-center py-2 my-3 rounded mx-2">
                        <span className="text-white font-weight-bold px-2">
                        <IonIcon icon={alertCircleOutline} size="medium"/>&nbsp;
                            Müşterinin findexi yapılmamıştır. Müşteri bilgilerinin doğruluğundan emin misiniz?
                        </span>
                        </div>
                    ) : (findex === true && reservation.drivers[0].verivication_time === null) ? (
                        <div className="border border-warning d-flex align-items-center justify-content-center py-2 my-3 rounded mx-2">
                        <span className="px-2 text-warning text-center">
                        <IonIcon icon={alertCircleOutline} size="medium"/>&nbsp;
                            Lütfen onay kodu göndererek sürücünün telefon numarasını doğrulayınız.
                        </span>
                        </div>
                    ) : (<></>)}


                    {reservation && (
                        <>
                            <div className="light-border rounded ml-2 p-2 position-relative">
                                <strong className="m-0">Müşteri Bilgileri</strong>
                                {/*<IonIcon onClick={() => setActiveIdentityEdit(!activeIdentityEdit)} style={{position: "absolute", right: 5, top: 5, fontSize:"25px"}} icon={createOutline} />*/}

                                {activeIdentityEdit === false && (
                                   <>
                                       <span className="d-block mb-1">{reservation?.name}</span>
                                       <span className="d-block"><strong>TC: </strong>{reservation?.identity_number} </span>
                                   </>
                                )}

                                {activeIdentityEdit === true && (
                                    <>
                                        <div className="">
                                            <h5 className="text-muted">{reservation?.name}</h5>
                                            <Input className={"mb-2 w-70 ml-2"} type="number" value={identity} name={"identity_number"} label={"TC No"} errors={errors} onChange={identityChange}/>
                                            <div className="d-flex justify-content-end">
                                                <IonButton color="light" className="flex-fill ml-2" onClick={() => setActiveIdentityEdit(false)}>İptal</IonButton>
                                                <IonButton color="success" className="flex-fill ml-2" onClick={() => identityUpdate(reservation.id)}>Güncelle</IonButton>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="light-border rounded ml-2 pt-2 my-3 position-relative">
                                {/*<IonIcon onClick={() => setActiveDriverEdit(!activeDriverEdit)} style={{position: "absolute", right: 5, top: 5, fontSize:"25px"}} icon={createOutline} />*/}
                                <strong className="ml-2">Sürücü Bilgileri</strong>

                                {activeDriverEdit === true && (
                                    <div className="rounded position-relative px-3 pb-3">
                                        <h5 className="text-muted ">{reservation.drivers[0].name}</h5>
                                        <Input className={"mb-2 w-70 "} name={"phone"} type="number" value={phone} label={"Telefon"} errors={[]} onChange={phoneChange}/>
                                        <div className="d-flex justify-content-end">
                                        <IonButton color="light" className="flex-fill" onClick={() => setActiveDriverEdit(false)}>İptal</IonButton>
                                        <IonButton color="success" className="flex-fill ml-2" onClick={() => phoneUpdate(reservation.drivers[0].id)}>Güncelle</IonButton>
                                        </div>
                                    </div>
                                )}

                                {activeDriverEdit === false && (
                                    <>
                                        {reservation.drivers.length > 0 && reservation.drivers.map((item, idx) => (
                                            <div className="border border-light ml-2 my-2" key={"driver-verify-dialog" + idx}>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <span className="d-block">{item.name}</span>
                                                        <span className="d-block"><IonIcon icon={callSharp}/> {item.phone}</span>
                                                    </div>
                                                    {findex === true && idx === 0 && (
                                                        <div>
                                                            <CodeDialog id={item.id} verify={item.verification_time} onChange={change} />
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        ))}
                                    </>
                                )}


                            </div>

                        </>
                    )}


                </div>
                <IonButtons className="p-2 d-flex justify-content-end">
                    <IonButton color="medium" onClick={() => {close()}}>İptal</IonButton>
                    {findex === true && (
                        <IonButton color="primary" onClick={() => {close()}}>Tamam</IonButton>
                    )}

                    {findex === false && (
                        <IonButton color="primary" onClick={() => {confirmInfo()}}>Evet Onaylıyorum</IonButton>
                    )}
                </IonButtons>
            </IonModal>
        </>
    )
}

function CodeDialog(props) {

    const {api} = useApi();

    let [codeDialog, setCodeDialog] = useState({show: false, code: null})
    let [verify, setVerify] = useState(props.verify);
    let [loading, setLoading] = useState(false);
    let [verifyCode, setVerifyCode] = useState(null);


    function getVerifyCode(id) {
        setLoading(true);
        api.get("mobileapp/driver/verify/" + id).then(resp => {
            setCodeDialog({show: true, code: resp.verification_code });
            console.log(resp,'verify code respp');
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    function verifyPhone(alertData) {
        setLoading(true);
        api.get("mobileapp/driver/verify/" + props.id, alertData).then(resp => {
            setVerify(resp.status);
            setLoading(false);
            props.onChange(true);
        }).catch(() => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        })
    }

    return(
        <div>
            <IonLoading
                cssClass='my-custom-class'
                isOpen={loading}
                onDidDismiss={() => {}}
                message={'Lütfen bekleyiniz...'}
            />
            {verify ? (
                <h5 className="text-success">
                    Onaylandı
                    <IonIcon color="success"  icon={checkmarkDoneCircleOutline} />
                </h5>
            ) : (
                <IonButton onClick={() => {getVerifyCode(props.id)}}>
                    Onay Kodu Gönder
                </IonButton>
            )}

            <IonAlert
                isOpen={codeDialog.show}
                onDidDismiss={() => {
                     setCodeDialog({show: false, code: null})
                }}
                cssClass='my-custom-class'
                header={'Onay Kodu - ' + codeDialog.code}
                inputs={[
                    {
                        name: 'verification_code',
                        type: 'number',
                        placeholder: 'Onay Kodu'
                    },
                ]}
                buttons={[
                    {
                        text: 'İptal',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Gönder',
                        handler: (alertData) => {
                            console.log('Confirm Ok');
                            verifyPhone(alertData)
                        }
                    }
                ]}
            />
        </div>
    )
}

export default VerifyDialog
